﻿// ------------- MEDIA QUERIES ------------- //

$x-large-media-query-size: 1600px;
$large-media-query-size: 1280px;
$medium-media-query-size: 1024px;
$tablet-media-query-size: 767px;
$small-media-query-size: 640px;
$mobile-media-query-size: 320px;

$calendar-breakpoint-size: $tablet-media-query-size;
$nav-breakpoint: $tablet-media-query-size;