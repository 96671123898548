﻿/// <reference path="../entries/main">

// STRUCTURE & LAYOUT
.#{$prefix}modal {
    &-wrapper {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
        }

        transition: 0.5s opacity;
        z-index: 150;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 100vh;
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        height: calc(var(--vh, 1vh) * 100);
        opacity: 1;
        overflow: auto;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
            // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: $medium-media-query-size;
        margin-left: auto;
        margin-right: auto;
        top: $gap;

        @include media(tablet) {
            width: 100%;
            bottom: auto;
        }
    }

    &-inner {
        position: relative;
        overflow: auto;
    }
}

// STYLING
.#{$prefix}modal {
    &-wrapper {
        @include responsive-property('padding', $standard-padding-map);
        background-color: rgba($primary-dark, 0.8);
    }

    &-inner {
        @include responsive-property('padding', $standard-padding-map);
        background-color: $white;

        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.#{$prefix}modal {
    &-close {
        // Uses hypotenuse of icon square size to match rotated icon size
        @extend %un-button;
        width: hypotenuse($ui-icon-size, $ui-icon-size);
        height: hypotenuse($ui-icon-size, $ui-icon-size);
        position: absolute;
        right: -$gap;
        top: -$gap;

        @include media(medium) {
            right: 0;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            width: hypotenuse($ui-icon-size, $ui-icon-size);
            height: $ui-icon-thickness;
            background: $primary-light;
            position: absolute;
            left: 0;
            top: calc(50% - #{$ui-icon-thickness / 2});
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }
}