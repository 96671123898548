﻿/// <reference path="../entries/main">

[data-sticky-el] {
    will-change: min-height;
}

[data-sticky-inner] {
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}