﻿/// <reference path="../../entries/main">

.#{$prefix}cart-group {
    & + & {
        @include responsive-context($gutter-map-v) {
            margin-top: $responsive-variable;
        }
    }

    &-header + * {
        @extend %text-top-margin;
    }
}

.#{$prefix}cart-item {
    @extend %card-padding;

    &-header {
        @extend %flex-between;

        & > *:nth-child(n+2):last-child {
            flex-shrink: 0;
        }
    }

    &-details {
        @extend %flex-fiftyfifty;
    }

}

.#{$prefix}cart-item-footer {
    @extend %text-top-margin;
    display: flex;
    justify-content: flex-end;

    &-extended {
        justify-content: space-between
    }

    @extend %border-top;


    @include responsive-context($x-small-gap-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}