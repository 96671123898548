﻿/// <reference path="../entries/main">

$two-col-breakpoint: "medium" !global; // this breakpoint must exist as a value in the gutter-map variable to work
$one-col-breakpoint: "small" !global; // this breakpoint must exist as a value in the gutter-map variable to work

// STRUCTURE
.columns-auto {
    &-container {
        margin-top: $gap-sm;
        margin-bottom: $gap;

        @include media-min($one-col-breakpoint) {
            display: flex;
        }

        @include media(medium) {
            flex-wrap: wrap;
        }

        & > div, & > article, & > figure, & > section {
            @include responsive-property('margin-right', $gutter-map-h);
            flex: 1 1 0;
            width: 0;

            @include media-min($two-col-breakpoint) {
                &:last-child {
                    margin-right: 0;
                }
            }

            @include media-range($one-col-breakpoint, $two-col-breakpoint) {
                flex: 0 0 auto;
                width: calc(50% - #{map-get($gutter-map-h, $two-col-breakpoint) });
                margin-bottom: map-get($gutter-map-h, $two-col-breakpoint);

                &:nth-last-child(odd) {
                    margin-right: 0;

                    &:first-child {
                        width: 100%;
                    }
                }

                &:nth-last-child(3) ~ * {
                    margin-bottom: 0;
                }
            }

            @include media($one-col-breakpoint) {
                width: 100%;
                margin-bottom: map-get($gutter-map-h, $one-col-breakpoint);
            }
        }

        .rich-text-max {
            width: 100%;
        }

        .block {
            @include responsive-context($block-margin-map) {
                margin-top: $responsive-variable / 3;
                margin-bottom: $responsive-variable / 3;
            }
        }

        .block-inner {
            padding: 0;
        }

        &.single-item {
            .rich-text-max {
                text-align: center;
            }
        }

        &.reduced-top > .block {
            padding-top: $gap-sm;
        }

        &.reduced-bottom > .block {
            padding-bottom: $gap-sm;
        }
    }

    &-heading {
        margin-top: $gap-sm;
    }
}
