﻿/// <reference path="../entries/main">

.availability {
    &-good,
    &-low,
    &-limited,
    &-unavailable,
    &-custom {
        @extend %body;

        &::before {
            content: '';
            width: $svg-icon-xs;
            height: $svg-icon-xs;
            display: inline-block;
            border-radius: 9999em;

            @include responsive-context($xx-small-gap-map) {
                margin-right: $responsive-variable;
            }
        }
    }

    &-good {
        &::before {
            background: $ui-valid;
        }
    }

    &-limited {
        &::before {
            background: $ui-invalid;
        }
    }

    &-low {
        &::before {
            background: $ui-warning;
        }
    }
}

@include theme-parent() {
    .availability-unavailable,
    .availability-custom {
        &::before {
            background-color: nth($themed-button-disabled-config, 1);
        }
    }

    .availability-custom {
        &::before {
            background-color: var(--availability-color);
        }
    }
}
