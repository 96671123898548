﻿/// <reference path="../../entries/calendar">

// Leftover calendar aliases
$primary-calendar-dark: $primary-dark;
$secondary-calendar-medium: $primary-medium;


%calendar-edge-padding {
    @include responsive-property('padding-left', $calendar-padding-map);
    @include responsive-property('padding-right', $calendar-padding-map);
}

.ace-cal {
    @include responsive-property('padding-bottom', $block-margin-map);

    &-content {
        // need to override the relative positioning on the page so that the sticky mini-cal can properly measure its position
        position: static;
    }

    &-header {
        @extend %standard-padding-h;
    }


    &-results {
        @extend %responsive-grid-gap;

        @include media(medium) {
            grid-row-gap: 0;
        }

        @include media-min(medium) {
            display: grid;
            grid-template-columns: .2fr 1fr;
        }
    }

    &-nav {
        grid-row-gap: $gap-sm;
        display: flex;
        align-items: center;

    }
}

html[data-calendar-loading='true'] {
    overflow: hidden;
}

ul[class*='ace-cal'] {
    list-style-type: none;
}

@include ie {
    .ace-cal-nav {
        display: block;

        & + * {
            margin-top: $gap-md;
        }
    }

    .ace-cal-filters {
        margin-top: $space;
    }
}

@import '_filters', '_filters-modal-vendor', '_list-container', '_subnav', '_back-to-top', '_load-more', '_loader', '_mini-cal','_grid-container', '_grid-item', '_mini-cal-navigation.scss', '_promo.scss', '_skip-links.scss', '_production.scss';
