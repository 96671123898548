﻿/// <reference path="../entries/main">
$pagination-bar-height: 4px;

// Fun slidey pagination
.carousel {
    &-pagination {
        @extend %standard-padding-h;
        width: auto;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        & > li {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }
    }

    &-page {
        @extend %un-button;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: $pagination-bar-height;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border-top: $pagination-bar-height solid $black-16;
        }

        &:focus {

            @include theme-child() {
                box-shadow: 0 0 0 2px $themed-inverted-color;
            }

            &.is-active {
                box-shadow: none;
            }

            &::before {

                @include theme-child() {
                    border-color: $themed-inverted-color;
                }
            }
        }

        &:active {
            background: transparent;
        }

        &::after {
            transform: translateX(-101%);//101 to prevent small borders from showing up on carousels with multiple items visible (like the content highlight block)
            animation: carouselTrackOffToRight $transition;

            @include theme-child() {
                border-color: $themed-inverted-color;
            }
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromLeft $transition;
        }
    }

    [data-slide-direction="left"] &-page {
        &::after {
            transform: translateX(100%);
            animation: carouselTrackOffToLeft $transition;
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromRight $transition;
        }
    }

    &-page-inactive {
        &::after {
            animation: none !important;
        }
    }
}

// First and last index numbers in track bar as seen on some carousels
.carousel-pagination-nums {
    &[data-start-num][data-end-num] {
        &::before,
        &::after {
            width: 1.25rem;

            @include theme-child() {
                color: $themed-inverted-color;
            }
        }

        &::before {
            content: attr(data-start-num);
            margin-right: $gap-sm;
        }

        &::after {
            content: attr(data-end-num);
            margin-left: $gap-sm;
            text-align: right;
        }

        @include media(small) {
            &::before,
            &::after {
                content: none;
            }
        }
    }
}
// Theme Specific

.theme-dark .carousel-page {
    &::before {
        border-top: $pagination-bar-height solid $white-16;
    }

    &::after {
        border-top: $pagination-bar-height solid $white;
    }
}


@keyframes carouselTrackOnFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes carouselTrackOnFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
