﻿/// <reference path="../entries/main">

.link-toggle {
    &-container {
        padding: 0;
        width: auto;
        width: min-content;
        max-width: none;
        background-position: right 0.75em top 1.25em;
    }

    &-inner {
        position: relative;
    }

    &-trigger {
        padding: $space $button-form-padding-h;
        padding-right: $gap + ($button-form-padding-v * 2);
        width: 100%;
        white-space: nowrap;

        @include theme-child() {
            border: 1px solid $themed-foreground;
        }

        &.inactive {
            display: none;
        }
    }

    &-options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: white;

        @include theme-child() {
            &[aria-hidden=false] {
                border: 1px solid $themed-foreground; 
                border-top: none;
            }
        }

        &-absolute {
            position: absolute !important; //Necessary to override reveal panel class
        }
    }

    &-options {
        a,
        button {
            padding: $space $gap-sm;
            width: 100%;
            display: block;
            border-bottom: none;

            &.link-toggle-options-selected {
                display: none;
            }
        }
    }
}
