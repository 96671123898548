﻿/// <reference path="../entries/main">

%standard-padding-h {
    @include responsive-context($standard-padding-map) {
        padding-left: $responsive-variable;
        padding-right: $responsive-variable;
    }
}

%standard-padding-v {
    @include responsive-context($grid-spacing-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

%standard-margin-v {
    @include responsive-context($block-margin-map) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%medium-margin-v {
    @include responsive-context($gutter-map-v) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%block-padding-v {
    @include responsive-context($block-margin-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

%block-spacing-v {
    @include responsive-context($block-margin-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

%standard-max-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
}

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

// Pattern for a simple 2 column layout that has responsive margins and collapses to 1 column at the medium breakpoint
%simple-2-col-container {
    @include media-min(tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}

%simple-2-col-item {
    @include responsive-context($grid-spacing-map, $block-margin-map) {
        margin-right: $responsive-variable;
        margin-bottom: $responsive-variable-2;
        width: calc(50% - #{$responsive-variable / 2});
    }

    @include media-min(tablet) {
        flex: 0 0 auto;

        &:nth-child(even) {
            margin-right: 0;
        }

        &:last-child, &:nth-last-child(2):nth-child(odd) {
            margin-bottom: 0;
        }
    }

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }
}

%card-padding {
    @include responsive-context($card-padding-h, $card-padding-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%small-card-padding {
    @include responsive-context($gutter-map-h, $gutter-map-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

// Flexbox helpers
%flex-between {
    display: flex;
    justify-content: space-between;

    & > * {
        margin-top: 0;
        flex: 0 1 auto;

        &:first-child {
            margin-right: $space;
        }
    }
}

%flex-row-to-column {
    @include media-min(small) {
        display: flex;
        & > * + * {
            margin-left: $gap-sm;
        }
    }

    @include media(small) {
        & > * {
            width: 100%;

            & + * {
                margin-top: $gap-sm;
            }
        }
    }
}

%flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    & > * {
        flex: 0 1 auto;

        &:first-child {
            margin-bottom: $space;
        }
    }
}

%flex-fiftyfifty {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 1 auto;
        max-width: 100%;
        margin-top: 0;

        @include responsive-context($gutter-map-h) {
            width: calc(50% - #{$responsive-variable / 2});
            margin-right: $responsive-variable;

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(2) ~ * {
                margin-top: $responsive-variable;
            }
        }

        @include media(mobile) {
            width: 100%;
        }
    }
}

%flex-fiftyfifty-bordered {
    @extend %flex-fiftyfifty;

    @include media-min(tablet) {
        & > * {
            width: calc(50% - #{$gap-md / 2});
            flex: 1 1 auto;

            &:nth-child(odd) {
                margin-right: $gap-md / 2;
                padding-right: $gap-md;
                border-right: 1px solid;
            }

            &:nth-child(even) {
                padding-left: $gap-md / 2;
            }
        }
    }

    @include media(tablet) {
        display: block;

        &.reversed {
            display: flex;
            flex-direction: column-reverse;
        }

        & > * {
            width: 100%;

            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    }
}


%responsive-column-gap {
    @include responsive-context($gutter-map-h) {
        column-gap: $responsive-variable;
    }
}

// Responsive grid gaps based on gutter values
%responsive-grid-gap {
    @include responsive-context($gutter-map-h, $gutter-map-v) {
        grid-column-gap: $responsive-variable;
        grid-row-gap: $responsive-variable-2;
    }
}

// Responsive 12 column grid that goes edge to edge
%responsive-grid-columns {
    @include responsive-context($standard-padding-map, $gutter-map-h) {
        $_column-width: 0;
        $_margin-width: 0;

        @if $responsive-breakpoint == default or (map-get($breakpoints, $responsive-breakpoint) > $x-large-media-query-size) {
            $_column-width: minmax(auto, $max-column-width);
            $_margin-width: minmax(#{$responsive-variable - $responsive-variable-2}, 1fr);
        }

        @else {
            $_column-width: minmax(0, 1fr);
            $_margin-width: #{$responsive-variable - $responsive-variable-2};
        }

        grid-template-columns: $_margin-width repeat(12, $_column-width) $_margin-width;
    }
}
