// Spacing variables
$gap-lg:                    5rem;
$gap:                       2rem;
$gap-md:                    1.5rem;
$gap-sm:                    1rem;
$space:                     0.5rem;
$sliver:                    0.25rem;
$max-gutter-width:          3rem;
$max-gutter-height:         3rem;
$max-standard-padding:      4.5rem;
$svg-inline-spacing:        0.5em;

// Layout sizing and sizing for ui elements included in layouts
$max-line-length:           40rem;
$max-input-width:           40rem;
$max-width:                 112.5rem;
$max-content-width:         $max-width - ($max-standard-padding * 2);
$max-width-narrow:          40rem;
$max-column-width:          ($max-content-width / 12) - ($max-gutter-width * 11 / 12);
$fixed-width-centered:      42rem;
$max-modal-height:          38rem;
$max-modal-width:           40rem;
$link-caret-size:           0.625rem;
$standard-border:           1px solid; 

// Miscellaneous
$one-third:                 99.993% / 3;
$prefix:                    'ace-'; //prefix for project-specific classes
$adage-prefix:                    'adage-'; //prefix for project-specific classes
$transition:                0.3s;
$blur-radius:               12px;
$selected-underline-width:  4px;