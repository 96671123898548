﻿/// <reference path="../../../entries/main">

.subnav {
    background: $white;
    border-bottom: 1px solid $black-16;
    width: 100%;
    z-index: $z-subnavigation;
    transition: $transition all ease-in-out;
    top: 0;

    @include supports-vars {

        &.nav-has-loaded {
            top: var(--nav-margin-offset);
        }

        &[data-nav-is-showing="false"] {
            transform: translateY(-100%);
        }
    }

    @include media(tablet) {
        display: none;
    }

    &-menu {
        display: grid;
        grid-template-columns: repeat(3, 2fr) 1.5fr;
        align-items: center;
    }

    &-bar {
        @extend %standard-padding-h;
        @extend %standard-max-width;
        padding-top: $gap-sm;
        padding-bottom: $gap-sm;
    }

    &-menu-item {
        &:not(&-cta) {
            padding-right: $gap;
            margin-right: $gap;
            border-right: 1px solid $black-16;
        }

        &-cta {
            text-align: right;
        }
    }
    .nav-featured-link {
        text-decoration: none;
    }
}
