﻿/// <reference path="../../entries/main">

.button-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$space;
    margin-bottom: -$space;

    &-item {
        margin-right: $space;
        margin-bottom: $space;
    }

    input[type='radio'],
    input[type='checkbox'] {
        & + label {
            @extend %btn;

            &::after {
                content: none;
            }
        }

        &:checked + label {
            @include theme-child() {
                background-color: $themed-inverted-color;
                color: $themed-base;
                border-color: $themed-base;
            }
        }
    }

    &[data-full-width-mobile='true'] {
        @include media(small) {
            display: block;

            label {
                width: 100%;
            }

            input[type='radio'].radio-btn {
                &:checked {
                    & + label,
                    & + input[type='hidden'] + label {
                        padding-left: $button-form-padding-h;
                        padding-right: $button-form-padding-h;
                    }
                }
            }
        }
    }
}
