﻿/// <reference path="../../entries/main">


*:not(label) > input[type='checkbox'],
*:not(label) > input[type='radio'] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0; 
    overflow: hidden;
    opacity: 0;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

input[type='checkbox'],
input[type='radio'] {
    & + label:not(.bold), & + input[type='hidden'] + label:not(.bold) {
        font-weight: $font-weight-normal;
    }
}

input[type='checkbox'],
input[type='radio'] {
    &:focus {
        & + label,
        & + input[type='hidden'] + label {
            @include focused;
        }
    }

    & + label,
    & + input[type='hidden'] + label {
        @extend %label;
        @include focus;
        position: relative;
        display: inline-block;
        margin-left: 0;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: $_radiocheck-offset;
        }
    }

    &:checked + label,
    &:checked + input[type='hidden'] + label {
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
        }
    }
}
// SIZING
input[type='radio'],
input[type='checkbox'] {
    & + label,
    & + input[type='hidden'] + label {
        padding-left: $_radiocheck-size + $_radiocheck-padding;

        &::before {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
            background-size: cover;
        }

        &::after {
            // Manually adjust based on other sizing and check shape
            top: .075em;
            left: 0.4em;
            width: .875rem;
            height: .6875rem;
        }
    }
}
// CHECKBOX
input[type='checkbox'] {
    & + label,
    & + input[type='hidden'] + label {
        &::after {
            display: block;
            background-size: cover;
        }
    }
}

// RADIO PILLS
input[type='radio'].radio-btn {

    & + label,
    & + input[type='hidden'] + label {
        padding: $button-form-padding;
        padding: var(--button-form-padding);
        border-radius: 100px;
        cursor: pointer;

        &:hover {
            border-radius: 100px;
        }

        @include theme-child() {
            background: rgba($themed-foreground, 0.04)
        }

        &::before {
            content: '';
            border: $_radiocheck-weight solid;
            border-width: 0 0 $_radiocheck-weight $_radiocheck-weight;
            transform: rotate(-45deg);
            // Manually adjust based on other sizing and check shape
            top: $_radio-pill-checkmark-top;
            left: $_radio-pill-checkmark-left;
            border-radius: 0;
            width: $_checkmark-width;
            height: $_checkmark-height;
            opacity: 0;
            display: block;
            transition: $transition;
        }

        &::after {
            display: none;
        }
    }

    &:checked {
        & + label,
        & + input[type='hidden'] + label {
            padding-left: $button-form-padding-h + $_checkmark-width;

            &::before {
                opacity: 1;
            }
        }
    }
}

.label-text-offset {
    padding-left: $_radiocheck-size + $_radiocheck-padding;
}

@include theme-parent() {
    input[type="checkbox"] + label::before, input[type="checkbox"] + input[type='hidden'] + label::before {
        @include icon-unchecked-checkbox-bg($themed-inverted-color);
    }

    input[type="radio"] + label::before, input[type="radio"] + input[type='hidden'] + label::before {
        @include icon-unchecked-radio-bg($themed-inverted-color);
    }

    input[type="checkbox"]:checked + label::before,
    input[type="checkbox"]:checked + input[type="hidden"] + label::before {
        @include icon-checkbox-bg($themed-inverted-color);
    }

    input[type="radio"]:checked + label::before,
    input[type="radio"]:checked + input[type="hidden"] + label::before {
        @include icon-radio-bg($themed-inverted-color);
    }

    input[type="checkbox"]:checked + label::after,
    input[type="checkbox"]:checked + input[type="hidden"] + label::after {
        @include icon-checkmark-bg($themed-inverted-color);
    }

    input[type="radio"]:checked + label::after,
    input[type="radio"]:checked + input[type="hidden"] + label::after { 
        @include icon-checkmark-bg($themed-inverted-color);
    }

    input[type="radio"]:checked + label::after,
    input[type="radio"]:checked + input[type="hidden"] + label::after {
        border-color: $themed-inverted-color;
    }
}
