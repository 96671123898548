﻿/// <reference path="../entries/main">
@mixin active-slide-styles() {
    .gallery-slide {
        transition: $transition;

        &.is-active {
            @include theme-child() {
                background-color: $themed-active-slide-background;
                color: $themed-active-slide-foreground;

                .caption, a {
                    color: $themed-active-slide-foreground;
                }
            }
        }
    }
}

@mixin static-splide-overrides {
    .splide__track {
        padding: 0 !important; //had to do this to override inline styles
    }

    .splide.is-active .splide__list {
        width: calc(100% + #{$gap-md*2});
        margin-left: -$gap-md !important; //had to do this to override vendor gallery defaults unfortunately
        transform: translateX(0px) !important; //had to do this to override inline styles
    }

    .gallery-slide {
        padding: 0 $gap-md;
    }
}

@mixin hide-splide-functionality($breakpoint: $medium-media-query-size) {
    @include media-min($breakpoint) {
        .splide__arrows, .splide__pagination {
            display: none;
        }
    }

    @include media($breakpoint - 1px) { //Did this because a bug was a occuring with at the precise moment this breakpoint is hit that was causeing slides to look activated when they shouldn't.
        @include active-slide-styles();
    }
}


.content-highlight-gallery {
    .gallery {
        &-slide {
            width: calc(25% - #{$gap-md*2});
            padding: $gap-md;

            @include media(large) {
                width: calc(40% - #{$gap-md*2});
            }

            @include media(medium) {
                width: calc(50% - #{$gap-md*2});
            }

            @include media(tablet) {
                width: calc(97.5% - #{$gap-md*2});
            }
        }

        &-2 {
            @include hide-splide-functionality(tablet);

            @include media-min(tablet) {
                @include static-splide-overrides;

                .gallery-slide {
                    width: 50%;
                }
            }
        }

        &-3 {
            @include hide-splide-functionality;

            @include media-min(medium) {
                .gallery-slide {
                    width: 33.33%;
                }
            }
        }

        &-4 {
            @include hide-splide-functionality;

            @include media-min(medium) {
                .gallery-slide {
                    width: 25%;
                }
            }
        }

        &-full {
            @include active-slide-styles();
        }

        &-item-caption-title {
            @extend %h3;
        }
    }

    &.gallery-wrapper-static {
        @include media-min(medium) {
            @include static-splide-overrides;
        }
    }
}