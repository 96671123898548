﻿/// <reference path="../../entries/main">

// ------------- CONFIRMATION SENT - SKIN ------------- //
.#{$prefix}confirmation-sent {
    &-inner {
        background: $primary-light;
    }
}


