﻿/// <reference path="./__index">

// ------------- FONTS ------------- //
$body-font: 'GeneralSans', Arial, sans-serif;
$heading-font: 'DMSerifText', Times New Roman, serif;
$accent-font: $body-font;
$secondary-font: 'PingFang', Arial, sans-serif;
$tertiary-font: 'STXingkai', Arial, sans-serif;

// ------------- FONT WEIGHTS ------------- //
$font-weight-normal: 400;
$font-weight-medium: 400;
$font-weight-bold: 600;

// ------------- LINE HEIGHTS ------------- //
$heading-line-height: 1.1; // just for h1
$body-line-height: 1.5; // just for body on desktop
