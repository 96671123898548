﻿/// <reference path="../../entries/main">

// ------------- ACE EXCHANGES PRODUCTIONS - SKIN ------------- //
.#{$prefix}exchanges-productions {
    border-color: $primary-light;
}

// ------------- ACE EXCHANGES PRODUCTIONS LIST ITEM - SKIN ------------- //
.#{$prefix}exchanges-productions-list-item {
    background: $primary-light;
}

// ------------- ACE EXCHANGES PRODUCTIONS LIST ITEM HEADER - SKIN ------------- //
.#{$prefix}exchanges-productions-list-item-header {
    &-details {
        &-cta {
            &-btn {
                font-weight: $font-weight-bold;
                color: $primary-dark;
            }
        }
    }

    background: $primary-light;
}

// ------------- ACE EXCHANGES PRODUCTIONS LIST ITEM BODY PERFORMANCES - SKIN ------------- //
.#{$prefix}exchanges-productions-list-item-body-performances {
    &-item {

        label {
            font-weight: $font-weight-normal;
        }
    }
}