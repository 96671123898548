﻿/// <reference path="../../entries/main">
// ------------- ACE MY PROFILE HEADER - STRUCTURE ------------- //

// Page structure
.#{$prefix}my-profile {
    padding-top: $gap;

    &-heading {
        margin-bottom: $gap-sm;

        @include responsive-context($gutter-map-v) {
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;

        &-actions {
            display: flex;
            align-items: flex-end;
        }
    }

    &-section {
        padding-top: $gap-sm;
    }
}

// Show / Hide Info / Input divs
.#{$prefix}my-profile {
    &-panel, &-save {
        &[aria-hidden="true"] {
            display: none;
        }
    }
}

// Interests
.#{$prefix}my-profile-interests {
    @include responsive-context($gutter-map-h) {
        padding: $responsive-variable;
        border-radius: $border-radius;
    }

    & + & {
        margin-top: $space;
    }

    &-list {
        margin-bottom: $gap;
        
        
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include media(medium) {
            grid-template-columns: repeat(1, 1fr);
        }


        &-item {
            display: flex;
            width: 100%;
            max-width: none;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: $gap-sm;
        }
    }

    &-container {
        @include media-range(tablet, medium) {
            display: flex;
            margin-right: 0;

            & > * {
                width: 50%;
            }

            & > * + * {
                margin-left: $gap;
            }
        }

        @include media(medium) {
            @include responsive-context($x-small-gap-map) {
                margin-top: $responsive-variable;
            }
        }

        @include media(small) {
            margin-top: $gap;
        }
    }
}
