﻿/// <reference path="../entries/main">

// ------------- SECTION SPLIT - STRUCTURE ------------- //
.#{$prefix}section-split {
    display: flex;
    margin-bottom: $gap;

    @include media(medium) {
        flex-direction: column;
    }

    @include media-min(medium) {

        > h3 {
            flex-basis: $max-input-width;
        }

        > div {
            flex-grow: 1;
        }
    }
}
// ------------- SECTION SPLIT FORM - STRUCTURE ------------- //
.#{$prefix}section-split-form {
    margin: $gap 0;

    legend {
        margin-bottom: $gap-sm;
    }

    &-input {
        @include media(small) {
            margin-bottom: $gap/2;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        margin-bottom: $gap;

        > li {
            padding: $gap $gap-sm;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            > input:checked + label + .ace-section-split-form-list-sub {
                display: block;
            }

            > label {
                flex-grow: 1;
                margin-left: $gap-sm;
                display: flex;
                justify-content: space-between;

                // ace-edit-address, ace-edit-payment, ace-delete-address
                button {
                    outline: 0;
                    padding: 0;
                    background: none;
                    border: none;
                    outline-offset: 0;
                    text-decoration: underline;
                }
            }

            + li {
                margin-top: $gap-sm;
            }
        }

        &-sub {
            display: none;
            width: 100%;
            padding-top: 2rem;

            .ace-section-split-form {
                margin: 0;
            }
        }
    }
}
// ------------- SECTION SPLIT FORM - CREDIT CARD ------------- //
.#{$prefix}credit-card-form {
    &-item {
        + * {
            margin-top: $gap-sm;
        }

        input, select {
            max-width: 100%;
        }

        &-split {
            display: flex;
            align-items: flex-end;

            > * {
                flex-grow: 1;

                + * {
                    margin-left: $gap-sm;
                }
            }
        }
    }

    &-type {
        position: relative;
        padding: $gap-sm;

        &-item {
            display: flex;
            justify-content: flex-start !important;

            input {
                margin-right: $gap-sm;
            }
        }
    }
}


// ------------- SECTION SPLIT FORM - SKIN ------------- //
.#{$prefix}section-split-form {
    legend {
        @extend %small;
        font-weight: $font-weight-bold;
    }

    &-list {
        > li {
            border-color: $primary-light;
            background: $white;

            > label {
                font-weight: $font-weight-normal;

                button {
                    color: $primary-medium;

                    @extend %small;
                }
            }
        }
    }
}
// ------------- SECTION SPLIT FORM - CREDIT CARD - SKIN ------------- //
.#{$prefix}credit-card-form {
    &-type {
        border-color: $primary-light;
    }
}
