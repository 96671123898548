/// <reference path="../entries/main">

%btn {
    @extend %label;
    @include font-size($label-font-sizes);
    font-family: $body-font;
    font-weight: $font-weight-bold;
    display: inline-block;
    padding: $button-form-padding;
    padding: var(--button-form-padding);
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border: $button-form-border solid transparent;
    text-transform: uppercase;
    letter-spacing: 0.19em;
    border-radius: $border-radius;

    &[disabled], &.disabled {
        cursor: not-allowed;
    }

    &.disabled {
        pointer-events: none;
    }

    .svg-icon {
        margin-left: $space;
        transition: $transition;
    }

    .svg-on-left {
        margin-left: 0;
    }

    &:active, &:hover, &:focus {
        border-radius: $border-radius;
    }
}

%btn-primary,
%btn-secondary,
%btn-outline,
%btn-link {
    @extend %btn;
}

@include theme-parent() {
    %btn-primary, %btn-round {
        @include button-helper();
    }


    %btn-secondary {
        @include button-helper( $_off: $themed-button-2-config );
        border-color: transparent;
    }

    %btn-outline {
        @include button-helper( $_off: $themed-button-3-config);
    }

    %btn-link {
        @include button-helper( $_off: (transparent, $themed-foreground), $_on: (transparent, $themed-light-text), $_active: ($themed-background, $themed-foreground), $_disabled: ($themed-background, $themed-light-text) );
    }

    %btn-caret {
        &::after {
            @include icon-chevron-right($themed-foreground);
        }
    }
}

%btn-remove {
    @extend %btn-secondary;
    backdrop-filter: blur($blur-radius);
}

%btn-round {
    @include round-focus;
    border-radius: 9999em;
    width: $square-button-size;
    height: $square-button-size;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;

    .svg-icon {
        margin: 0;
    }
}

%un-button-un-style {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        color: $primary-dark;
        cursor: not-allowed;
    }
}

%un-button {
    @extend %body;
    @extend %un-button-un-style;
}


%btn-caret {
    position: relative;
    padding-right: $link-caret-size + $space;
    font-weight: $font-weight-bold;
    border: none;

    &::after {
        content: '';
        height: $link-caret-size;
        width: $link-caret-size;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
    }
}

%btn-right-aligned-icon {
    display: block;
    position: relative;
    padding-right: 0.5em + $svg-icon-sm;

    &:hover,
    &:focus {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &::after {
        opacity: 0;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: $svg-icon-sm;
        height: $svg-icon-sm;
        transition: $transition;
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
    }
}

%btn-pill {
    @extend %btn;
    padding: $space $button-form-padding-h;
    border-radius: 100px;
    cursor: pointer;
    position: relative;


    @include theme-child() {
        background: $themed-foreground;
        color: $themed-background;
    }


    &:hover, &:active, &:focus {
        border-radius: 9999em;
    }

    .svg-icon {
        margin-left: 0;
    }
}

%btn-pill-checkmark {
    @extend %btn-pill;
    padding-left: $button-form-padding-h;

    @include theme-child() {
        background: $black-4;
        color: $themed-foreground;
    }

    &[disabled], &.active {
        padding-left: $button-form-padding-h + $_checkmark-width + $_radio-pill-checkmark-left;

        @include theme-child() {
            background: $themed-foreground;
            color: $themed-background;
        }

        &::before {
            content: '';
            border: $_radiocheck-weight solid;
            border-width: 0 0 $_radiocheck-weight $_radiocheck-weight;
            transform: rotate(-45deg);
            // Manually adjust based on other sizing and check shape
            top: .75rem;
            left: $_radio-pill-checkmark-left;
            border-radius: 0;
            width: $_checkmark-width;
            height: $_checkmark-height;
            opacity: 1;
            display: block;
            transition: $transition;
            position: absolute;
        }
    }
}
