﻿/// <reference path="../../entries/main">

.#{$prefix}subs {
    &-zoneselect {
        margin-top: $space;
    }

    &-sidebar {
        &-footer {
            flex: 1 1 auto;
            display: flex;
            align-items: flex-start;

            &-inner {
                flex: 1 1 auto;
            }
        }

        .btn-remove {
            margin-right: 0;
        }
    }

    &-cart {

        &[data-minicart-open="false"] {
            @include media(tablet) {
                display: none;
            }
        }

        &-listing-seated + .#{$prefix}subs-quantity, &-seatmaponly {
            padding-top: $gap-md;
            margin-top: $gap-md;
            border-top: $standard-border;
            border-top-color: $black-16;
        }

        &-summary {
            .error-message, .#{$prefix}subs-zoneselect {
                display: none;
            }

            & > * + * {
                margin-top: $space;
            }
        }

        &-trigger {
            display: block;
            max-width: none;
            margin-left: -$gap-sm;
            margin-right: -$gap-sm;
            margin-top: -$gap-sm;
            width: calc(100% + #{$gap-sm * 2});

            @include media-min(tablet) {
                display: none;
            }
        }

        &-item {
            &-alternate {
                &-label {
                    @extend %body;
                    text-transform: none !important;
                }
            }

            &-info {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
            }
        }

        &-link {
            position: fixed;
            z-index: $z-calendar-back-to-top;
            opacity: 0;
            min-width: 0;
            box-shadow: 0 0 20px 0 rgba($black, 0.25);
            padding: $space;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;

            @include media-min(tablet) {
                display: none;
            }

            &,
            * {
                pointer-events: none;
            }

            &[data-show-button="true"] {
                opacity: 1;

                &,
                * {
                    pointer-events: fill;
                }
            }

            &-progress {
                white-space: nowrap;
            }
        }

        .alternate-reservation-label {
            line-height: $body-line-height;
        }
    }
}