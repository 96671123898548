﻿/// <reference path="../../entries/main">

.ace-cal {
    &-grid {
        &-day {
            &-date {
                padding: $gap-sm;
                padding-bottom: 0;
                display: block;
                font-weight: $font-weight-bold;
            }

            &-has-events {
                transition: $transition;
            }

            &-today {
                background: $calendar-dark-blue;
            }
        }

        &-event {
            padding: $gap-sm;
            padding-top: 0;


            & + & {
                padding-top: $gap;
            }

            &-content {
                * + * {
                    margin-top: $space;
                }
            }

            &-cta {
                margin-top: $gap;

                a[class*="btn"] {
                    width: 100%;

                    @include media(large) {
                        padding: $space;
                    }
                }
            }
        }
    }
}

// BACKGROUND IMAGE

.ace-cal {
    &-grid {
        &-day {
            position: relative;

            &-date {
                position: relative;
                z-index: $z-calendar-date;
            }
        }

        &-event {
            &-bg {
                position: absolute;
                top: 0;
                bottom: -1px;
                left: 0;
                right: 0;
                pointer-events: none;
                background-size: cover;
                opacity: 0;
                transition: $transition;
                z-index: $z-calendar-background;

                & ~ * {
                    position: relative;
                    z-index: $z-calendar-date;
                }
            }


            &.highlighted &-bg {
                opacity: .5;
            }

            &:hover &-bg {
                opacity: 1;
            }

            &:focus-within &-bg {
                opacity: 1;
            }
        }
    }
}

.ace-cal {
    &-grid {
        &-day {
            &-has-events {
                &:hover {
                    background: $black;
                    color: $white;

                    .ace-cal-grid-event-cta a {
                        @extend %btn-secondary;

                        @include media(large) {
                            padding: $space;
                        }
                    }
                }

                &:focus-within { //must be separate so that IE doesn't drop styles entirely
                    background: $gray-dark;
                    color: $white;

                    .ace-cal-grid-event-cta a {
                        @extend %btn-secondary;

                        @include media(large) {
                            padding: $space;
                        }
                    }
                }
            }
        }

        &-day-has-events:hover &-event-name a {
            color: $white;
        }

        &-day-has-events:focus-within &-event-name a {
            color: $white;
        }

        &-event {
            &-time {
                font-weight: $font-weight-bold;
            }
        }
    }
}
// BACKGROUND IMAGE

.ace-cal {
    &-grid {
        &-day {
            position: relative;

            &-date {
                position: relative;
            }
        }
    }
}
