﻿/// <reference path="../../entries/main">

// utilities

.#{$prefix}gifts {
    &-heading {
        margin-bottom: $gap;

        @include media(tablet) {
            margin-bottom: $gap-md;
        }

        @include media(small) {
            margin-bottom: $gap-sm;
        }
    }

    &-item {
        position: relative;
        overflow: hidden;
        min-height: 25.75rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        align-items: flex-end;
        border-radius: $border-radius;

        @include responsive-context($standard-padding-map) {
            padding: $responsive-variable;
        }

        @include media(tablet) {
            min-height: 19.4375rem;
        }

        @include media(small) {
            min-height: 27rem;
        }

        &::before {
            @extend %full-cover;
            content: '';
        }
    }

    &-item-details {
        position: relative;
        width: 100%;
    }
}

@include theme-parent() {
    .#{$prefix}gifts-item::before {
        background-color: $themed-medium-color;
    }
}