﻿/// <reference path="../../entries/main">

// ------------- UPSELL OPTIONS LIST - STRUCTURE ------------- //
.#{$prefix}upsell-list {
    display: flex;
    margin: $gap-lg 0;
    flex-wrap: wrap;

    @include media(medium) {
        flex-direction: column;
    }

    > li {
        margin-bottom: $gap-sm;
        flex-grow: 1;
        position: relative;

        @include media-min(medium) {
            width: calc(50% - #{$space});
            max-width: calc(50% - #{$space});

            &:nth-child(even) {
                margin-left: $gap-sm;
            }
        }
    }

    &-option {
        padding: $gap-sm;
        display: block;
        width: 100%;

        &-inner {
            display: flex;
        }

        // TODO: Magic number
        &-image {
            margin-right: $gap-sm;
            flex-shrink: 0;
            flex-basis: 260px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: 1;

            h2 {
                margin-bottom: $gap / 2;
                text-align: left;
            }

            p {
                text-align: left;
            }

            &-action {
                display: flex;
                flex-grow: 1;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                .price-range {
                    text-align: right;
                }
            }
        }
    }
}

// ------------- UPSELL OPTIONS LIST - SKIN ------------- //
.#{$prefix}upsell-list {
    &-option {
        background: $primary-light;

        @extend %btn;

        &-content {

            h2 {
                @include font-size($h3-font-sizes);
                font-weight: 300;
            }

            p {
                font-weight: $font-weight-normal;
                color: $primary-dark;

                @extend %small;
            }

            &-action {
                .select-date, .price-range {
                    @extend %small;
                }

                .select-date {
                    font-weight: $font-weight-bold;
                    color: $primary-dark;
                }

                .price-range {
                    color: $primary-dark;
                }
            }
        }
    }
}
