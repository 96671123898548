﻿/// <reference path="../../entries/main">

.#{$prefix}login {
    &-form {
        input, select, textarea, .select-style {
            max-width: $max-input-width;
        }
    }
}

.ace-form-resetpassword {
    max-width: $max-input-width;
}
