﻿/// <reference path="../../entries/main">

@function getGutterDifferenceTotal($total-columns: 12, $num: 1, $_gutter: 1rem) {
    @return ($total-columns - $num) / $total-columns * $_gutter;
}

@function getPercentageTotal($total-columns: 12, $num: 1) {
    @return $num * (1/$total-columns) * 100%;
}

@function getColumnWidth($total-columns: 12, $num: 1, $gutter-value: $max-gutter-width, $overflow-handled: false, $add-gutter: false) {
    $_gutter-difference-total: getGutterDifferenceTotal($total-columns, $num, $gutter-value);
    $_percentage-total: getPercentageTotal($total-columns, $num);

    @if $add-gutter == false {
        @if $overflow-handled == true {
            @return calc(#{$_percentage-total} - #{$gutter-value});
        }

        @else {
            @return calc(#{$_percentage-total} - #{$_gutter-difference-total});
        }
    }

    @else {
        @if $overflow-handled == true {
            @return calc(#{$_percentage-total});
        }

        @else {
            @return calc(#{$_percentage-total} - #{$_gutter-difference-total - $gutter-value});
        }
    }
}

@mixin column-width($total-columns: 12, $num: 1, $overflow-handled: false, $add-margin: false) {
    @include responsive-context($gutter-map-h) {
        $_gutter-difference-total: getGutterDifferenceTotal($total-columns, $num, $responsive-variable);
        $_percentage-total: getPercentageTotal($total-columns, $num);

        @if $add-margin == true {
            margin-right: $responsive-variable;
        }

        @if $overflow-handled == true {
            width: calc(#{$_percentage-total} - #{$responsive-variable});
        }

        @else {
            width: calc(#{$_percentage-total} - #{$_gutter-difference-total});
        }
    }
}
