﻿/// <reference path="../entries/main">
$production-row-breakpoint: $medium-media-query-size;

.#{$prefix}production {
    &-row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: $gap-md 0;

        @include media-min($production-row-breakpoint) {
            align-items: center;
            justify-content: space-between;
        }

        @include media($production-row-breakpoint) {
            flex-direction: column;
        }

        & + & {
            border-top: $standard-border;

            @include theme-child() {
                border-top-color: $themed-light-border;
            }
        }

        &-secondary {
            border-top: $standard-border;
            padding-top: $gap-md;

            &:first-child {
                border-top: none;
            }

            @include theme-child() {
                border-top-color: $themed-light-border;
            }
        }

        &-eq {
            @include media-min($production-row-breakpoint) {
                & > * {
                    width: 50%;
                }
            }

            @include media($production-row-breakpoint) {
                & > * {
                    width: 100%;
                }
            }
        }
    }

    &-rows {
        &-borderless-bottom {
            .#{$prefix}production-row:last-child {
                border-bottom: none;
            }
        }
    }

    &.split {
        justify-content: space-between;
    }

    &-info {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        flex-grow: 1;
        align-items: center;

        &-title {
            @extend %body;
            @extend %bold;
        }

        &-split {
            @include media(tablet) {
                grid-template-columns: repeat(2, 1fr);

                & > *:nth-child(even) {
                    text-align: right;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &-date {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
    }

    &-date, &-cta {
        @include media($production-row-breakpoint) {
            width: 100%;
        }
    }


    &-image {
        width: 4rem;
        min-width: 4rem;
        margin-right: $gap-sm;

        &-inner {
            @extend %cropped-16-by-9;
            padding-bottom: ratioToPercent(1, 1);
        }

        & + .#{$prefix}production-info-title {
            @include media-min($production-row-breakpoint) {
                padding-left: $gap-md;
            }

            @include media($production-row-breakpoint) {
                padding-left: $gap;
            }
        }
    }
}
