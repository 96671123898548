﻿/// <reference path="../entries/main">


// STRUCTURE
.video-block.inline {
    max-width: $max-line-length;
}

.video {

    &-iframe {
        position: relative;
        padding-bottom: ratioToPercent(16, 9);
        width: 100%;
        max-width: 100%;

        & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-native {
        max-width: 100%;

        & > video {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &-thumbnail {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;

        &-trigger {
            @extend %un-button;
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
}

// PLAY BUTTON ON VIDEOS
$video-play-circle-size: 5rem;
$video-play-triangle-size: 1.25rem;

.video {
    &-thumbnail {
        &-trigger {
            &::before {
                @extend %focus;
                content: '';
                display: block;
                position: absolute;
                transition: $transition;
                background: rgba($black, 0.5);
                width: $video-play-circle-size;
                height: $video-play-circle-size;
                border-radius: 50%;
                margin-top: -$video-play-circle-size / 2;
                margin-left: -$video-play-circle-size / 2;
                top: 50%;
                left: 50%;
                z-index: 1;
            }

            svg {
                position: absolute;
                fill: $primary-light;
                width: $video-play-triangle-size;
                height: $video-play-triangle-size;
                margin-top: -$video-play-triangle-size / 2;
                margin-left: -$video-play-triangle-size / 2;
                top: 50%;
                left: 50%;
                z-index: 2;
            }

            &:hover,
            &:focus {
                &::before {
                    background: $black;
                    box-shadow: $shadow;
                }
            }
        }
    }
}

// Handle video directly inside main triggering a modal
// TODO: Fix styling when the modal can be turned on and off properly. Right now I'm just getting it out of the way.
main > .block > .video-block {

    .video-thumbnail {
        position: static;
    }
}
