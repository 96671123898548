﻿/// <reference path="../../entries/main">

// ------------- CART NOTICE - STRUCTURE ------------- //
.#{$prefix}cart-message {

    &-inner {
        padding: $gap $gap-lg $gap $gap;
        position: relative;
        display: flex;

        &-icon {
            margin-right: $gap-md;

            svg {
                width: $gap-md;
                height: $gap-md;
            }
        }

        &-content {
            flex-grow: 1;
        }

        h5 {
            margin-bottom: $gap-sm / 2;
        }
    }

    &-close {
        position: absolute;
        top: $gap;
        right: $gap;

        svg {
            height: $gap-sm;
            width: $gap-sm;
        }
    }
}


// ------------- CART NOTICE - STRUCTURE ------------- //
.#{$prefix}cart-message {

    &-inner {
        border-radius: $border-radius;

        h5 {
        }

        p {
            @extend %small;
        }
    }

    &-close {
        @extend %un-button-un-style;
    }
    // themes
    &-warning {
        .#{$prefix}cart-message-inner {
            color: $ui-invalid;
        }
    }
}
 