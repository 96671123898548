﻿/// <reference path="../entries/main">

// ------------- VALIDATION ERRORS - STRUCTURE ------------- //
.validation-summary-errors {
    scroll-margin-top: var(--nav-clearance);

    @include responsive-context($small-vertical-gap-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }

    &-no-margin {
        padding: 0;
    }
}

// ------------- VALIDATION ERRORS - STRUCTURE ------------- //
.field-validation-error {
    display: block;
    margin-top: $space;
}


.validation-summary-errors {
    border-color: $ui-invalid;
    color: $ui-invalid;

    &-success {
        color: $ui-valid;

        svg {
            fill: $ui-valid;
        }
    }
}

.field-validation-error,
.error-message {
    @extend %small;
    color: $ui-invalid;
}
