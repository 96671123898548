﻿/// <reference path="../../entries/main">

.#{$prefix}block,
.#{$prefix}grid,
.block {
    @include responsive-context($block-spacing-map) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }

    &-no-margin {
        margin-top: 0;
        margin-bottom: 0;

        @include responsive-context($block-margin-map) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }
    }
}

.block-minor {
    @include responsive-context($block-margin-map) {
        margin-top: $responsive-variable / 2;
        margin-bottom: $responsive-variable / 2;

        & + * {
            margin-top: $responsive-variable / 2;
        }
    }
}

.block-padded {
    margin: 0;

    @include responsive-context($block-spacing-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

.#{$prefix}block,
.block {
    &-inner {
        @extend %standard-padding-h;
        @extend %standard-max-width;
    }

    &-narrow {
        width: 100%;
        max-width: $max-width-narrow;
        margin-left: auto;
        margin-right: auto;

        @include media(tablet) {
            max-width: none;
        }
    }
    // Override to padding based on Padding Control Block
    &.reduced-top {
        padding-top: $gap-sm;
    }

    &.reduced-bottom {
        padding-bottom: $gap-sm;
    }

    &.block-halved {
        @include responsive-context($grid-spacing-map) {
            padding-top: $responsive-variable / 2;
            padding-bottom: $responsive-variable / 2;
        }
    }
}

// Override to margins above so that adjacent full-bleed blocks can be flush
.#{$prefix}block-full-bleed,
.block-full-bleed {
    margin-top: 0;
    margin-bottom: 0;
}


// Generic header
.header + * {
    @include responsive-context($gutter-map-v) {
        margin-top: $responsive-variable;
    }
}
