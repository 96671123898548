﻿/// <reference path="../entries/main">

.#{$prefix}minicart {
    border-top: $standard-border $black;

    &-borderless {
        border-top: none;
    }

    &.is-affixed {
        .#{$prefix}minicart-inner {
            margin-top: var(--nav-clearance);
        }
    }

    &-inner {
        transition: $transition;
        transition-property: margin;
        padding: $gap 0;
    }

    &-item, &-subtotal, &-subitem {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: $gap-md 0;
    }

    &-subitem {
        border: none;
        padding: $space 0;
    }

    &-subitems-inner {
        align-items: start;
    }

    &-subtotal {
        padding-top: $gap-md;
        margin-top: $gap-sm;
        border-top: 1px solid $black-16;
    }

    &-item {
        & + & {
            border-top: 1px solid $black-16;
        }
    }

    &-list > *:first-child {
        padding-top: 0;
    }

    &-header {
        padding: 0 0 $gap-md;
        border-bottom: 1px solid $black-16;
        margin-bottom: $gap-sm;
    }

    &-review {
        @include media($nav-breakpoint) {
            scroll-margin-top: $sticky-nav-height-mobile;
            scroll-margin-top: var(--nav-clearance);
            will-change: initial !important;
            transform: initial !important;
        }
    }

    &-skip {
        text-align: center;
    }

    .#{$prefix}cart-clear-btn {
        margin: 0;
    }
}
