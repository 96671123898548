﻿/// <reference path="../entries/main">

%visually-hidden {
    @include visually-hidden;
}

%hide {
    @include hide;
}

%text-top-margin {
    margin-top: 1em;
}

%medium-top-margin {
    margin-top: 0.5em;
}

%small-top-margin {
    margin-top: 0.325em;
}

%blur-background {
    filter: blur($blur-radius);
    /*animation: fadeIn $transition;*/

    &::after {
        @extend %full-cover;
        content: '';
        background-color: rgba($black, 0.6);
    }
}


%gradient-background {
    background-repeat: repeat, no-repeat;
    background-size: 110px 110px, cover;
    background-position: top left, center center;
}

@include theme-parent() {
    %border-top {
        border-top: 1px solid $themed-primary-border;
    }

    %border-bottom {
        border-bottom: 1px solid $themed-primary-border;
    }

    %unlink {
        &, & > * {
            text-decoration: none;
        }
    }
}

