﻿/// <reference path="../../entries/main">

@mixin button-helper( $_off: $themed-button-1-config, $_on: $themed-button-on-config, $_active: $themed-button-on-config, $_focus: $themed-button-1-config, $_disabled: $themed-button-disabled-config ) {
    background-color: nth($_off, 1);
    color: nth($_off, 2);
    border-color: transparent;


    @if length($_off) > 2 {
        border-color: nth($_off, 3);
    }

    svg {
        fill: nth($_off, 2);
    }



    &:active {
        background-color: nth($_active, 1);
        color: nth($_active, 2);

        svg {
            fill: nth($_active, 1);
        }
    }

    &:hover {
        background-color: nth($_on, 1);
        color: nth($_on, 2);
        border-color: transparent;

        svg {
            fill: nth($_on, 2);
        }
    }

    &:focus {
        @include generate-focus-style(nth($_focus, 2), nth($_disabled, 2));
        background-color: nth($_focus, 1);
        border-color: nth($_focus, 2);
        color: nth($_focus, 2);

        svg {
            fill: nth($_focus, 2);
        }
    }

    &[disabled] {
        background-color: nth($_disabled, 1);
        color: nth($_disabled, 2);
        box-shadow: none;
        cursor: not-allowed;
        background-image: none;

        svg {
            fill: nth($_disabled, 2);
        }

        &:hover {
            background-image: none;
            background-color: nth($_disabled, 1);
            color: nth($_disabled, 2);
        }
    }
}
