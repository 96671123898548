﻿/// <reference path="../../entries/main">
$addons-breakpoint: $medium-media-query-size;
.#{$prefix}addons {
    &-section {
        padding: $gap 0;

        & + & {
            border-top: $standard-border;

            @include theme-child() {
                border-color: $themed-foreground;
            }
        }
    }
}

.#{$prefix}addons-list {

    &-price {
        text-align: right;

        @include media-min($addons-breakpoint) {
            min-width: 7rem; //prevents the quantity selector from bouncing around
        }

        @include media($addons-breakpoint) {
            text-align: center;
        }

        &-labeladjusted {
            margin-top: $gap-sm;
        }
    }
}

.#{$prefix}addons-item {  
    & > *:first-child.ace-addons-list-production-select-item {
        @include responsive-context($card-padding-h, $card-padding-v) {
            margin-top: -$responsive-variable-2;
        }
    }
}

.#{$prefix}addons-list-production {
    &-image {
        @extend %cropped-16-by-9;
        background-color: black;
    }

    &-info, &-desc {
        display: flex;
        justify-content: space-between;
    }

    &-info {
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

    &-desc {
        @include media($addons-breakpoint) {
            & + * {
                margin-top: $gap-sm;
            }
        }
    }

    &-select-item {
        &-padded {
            & + & {
                border-top: 1px solid $black-16;
            }

            @include responsive-context($card-padding-h, $card-padding-v) {
                padding: $responsive-variable-2 $responsive-variable;
                margin-left: -$responsive-variable;
                margin-right: -$responsive-variable;
            }
        }
    }

    &-row {
        @include media(medium) {

        }
    }

    &-select-row {
        display: flex;

        @include media-min($addons-breakpoint) {
            align-items: center;
            justify-content: flex-end;

            & > * + * {
                margin-left: $gap;
            }
        }

        @include media($addons-breakpoint) {
            flex-direction: column;

            & > * + * {
                margin-top: $gap;
            }
        }
    }

    &-select-inner {
        margin-bottom: calc((var(--gutter-v) * -1) * 2);
    }
}