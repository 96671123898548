﻿/// <reference path="../../entries/main">

// ------------- CART NOTICE - STRUCTURE ------------- //
.#{$prefix}cart-notice {
    margin-bottom: $gap-sm;


    &-message {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;

        &-body {
            display: flex;
            align-items: flex-start;
        }

        svg {
            margin-right: $gap-md;
            height: $ui-icon-size;
            width: $ui-icon-size;
            flex-shrink: 0;
            align-self: center;
            margin-top: $space;
        }
    }
}



// ------------- CART WARNING - STRUCTURE ------------- //
.#{$prefix}cart-notice {


    &-warning {
        svg {
            fill: $ui-invalid;
        }
    }
}


.#{$prefix}minicart {
    .#{$prefix}cart-notice {
        padding-bottom: $gap-sm;

        &-message-body {
            padding-right: $space;
        }
    }
}