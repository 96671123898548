﻿/// <reference path="../../entries/main">

// utilities

.#{$prefix} {
    &account-section, &account-header {
        @extend %standard-padding-h;
        @extend %standard-padding-v;
        @extend %standard-max-width;
    }

    &account-header {
        &-content {
            display: flex;

            @include media-min(small) {
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }

        &-logout {
            @include media(small) {
                margin-bottom: $space;
            }
        }
    }
}

.block + .#{$prefix}account-section {
    padding-top: 0;
}