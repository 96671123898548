﻿/// <reference path="../entries/main">

// ------------- CART ITEM TABLE - STRUCTURE ------------- //
.#{$prefix}cart-item-table {
    border-collapse: collapse;
    margin: $gap-sm 0;

    th,
    td {
        padding: 0 $gap-lg 0 0;
    }

    tbody {
        td,
        th {
            border-top: 0;
        }
    }
}

@include media(medium) {
    .#{$prefix}cart-item-table {
        width: 100%;

        thead {
            display: none;
        }

        th,
        td {
            border: 0;
            display: block;
            padding: 0;
            margin-top: $gap-sm / 2;

            &[data-cell-name] {
                &::before {
                    content: attr(data-cell-name);
                    display: block;
                }
            }

            &:last-child {
                margin-bottom: $gap / 2;
            }
        }
    }
}


// ------------- CART ITEM TABLE - SKIN ------------- //
.#{$prefix}cart-item-table {

    th,
    td {
        font-weight: $font-weight-normal;

        @include font-size($body-font-sizes);
    }

    th {
        color: $primary-dark;
    }
}

@include media(medium) {
    .#{$prefix}cart-item-table {

        th,
        td {
            &[data-cell-name] {
                &::before {
                    color: $primary-dark;
                }
            }
        }
    }
}
