﻿// Registry of all z indexes so they are understandable in context to each other

$z-modal: 3000;
$z-navigation: 2000;
$z-subnavigation: 1500;
$z-flex-subs-minicart: 1000;
$z-flex-area-cart: 5;
$z-calendar-filters: 5;
$z-calendar-back-to-top: 10;
$z-calendar-date: 2;
$z-calendar-background: 1;
$z-loader: 100;
$z-header-content: 2;
$z-video-controls: 3;
$z-bottom-sticky-nav: 1000;