﻿/// <reference path="../../entries/main">

@mixin reset-theme() {
    $themed-background: $default-background !global;
    $themed-foreground: $default-foreground !global;
    $themed-link-config: $default-link-config !global;
    $themed-button-1-config: $default-button-1-config !global;
    $themed-button-2-config: $default-button-2-config !global;
    $themed-button-3-config: $default-button-3-config !global;
    $themed-button-on-config: $default-button-on-config !global;
    $themed-button-disabled-config: $default-button-disabled-config !global;
    $themed-focus-ring: $default-focus-ring !global;
    $themed-light-text: $default-light-text !global;
    $themed-light-color: $default-light-color !global;
    $themed-medium-color: $default-medium-color !global;
    $themed-inverted-color: $default-inverted-color !global;
    $themed-input-bg: $default-input-bg !global;
    $themed-base: $default-base !global;
    $themed-gray: $default-gray !global;
    $themed-gradient-gray: $default-gradient-gray !global;
    $themed-gradient-magenta: $default-gradient-magenta !global;
    $themed-gradient-purple: $default-gradient-purple !global;
    $themed-gradient-green: $default-gradient-green !global;
    $themed-gradient-red: $default-gradient-red !global;
    $themed-gradient-orange: $default-gradient-orange !global;
    $themed-gradient-blue: $default-gradient-blue !global;
    $themed-gradient-teal: $default-gradient-teal !global;
    $themed-red-light: $default-red-light !global;
    $themed-red-dark: $default-red-dark !global;
    $themed-orange-light: $default-orange-light !global;
    $themed-orange-dark: $default-orange-dark !global;
    $themed-green-light: $default-green-light !global;
    $themed-green-dark: $default-green-dark !global;
    $themed-teal-light: $default-teal-light !global;
    $themed-teal-dark: $default-teal-dark !global;
    $themed-blue-light: $default-blue-light !global;
    $themed-blue-dark: $default-blue-dark !global;
    $themed-purple-light: $default-purple-light !global;
    $themed-purple-dark: $default-purple-dark !global;
    $themed-magenta-light: $default-magenta-light !global;
    $themed-magenta-dark: $default-magenta-dark !global;
    $themed-active-slide-background: $default-active-slide-background !global;
    $themed-active-slide-foreground: $default-active-slide-foreground !global;
}

@mixin theme-parent() {
    @include reset-theme();

    @content;

    @each $_theme, $_values in $themes {
        .theme-#{$_theme},
        [class*="theme"] .theme-#{$_theme} {
            $themed-background: map-get($_values, 'background') !global;
            $themed-foreground: map-get($_values, 'foreground') !global;
            $themed-link-config: map-get($_values, 'links') !global;
            $themed-button-1-config: map-get($_values, 'button-1') !global;
            $themed-button-2-config: map-get($_values, 'button-2') !global;
            $themed-button-3-config: map-get($_values, 'button-3') !global;
            $themed-button-on-config: map-get($_values, 'button-on') !global;
            $themed-button-disabled-config: map-get($_values, 'button-disabled') !global;
            $themed-focus-ring: map-get($_values, 'focus-ring') !global;
            $themed-light-text: map-get($_values, 'light-text') !global;
            $themed-light-color: map-get($_values, 'light-color') !global;
            $themed-medium-color: map-get($_values, 'medium-color') !global;
            $themed-inverted-color: map-get($_values, 'inverted-color') !global;
            $themed-input-bg: map-get($_values, 'input-bg') !global;
            $themed-primary-border: map-get($_values, 'primary-border') !global;
            $themed-light-border: map-get($_values, 'light-border') !global;
            // Background Colors
            $themed-base: map-get($_values, 'base') !global;
            $themed-gray: map-get($_values, 'gray') !global;
            $themed-gradient-gray: map-get($_values, 'gradient-gray') !global;
            $themed-gradient-magenta: map-get($_values, 'gradient-magenta') !global;
            $themed-gradient-purple: map-get($_values, 'gradient-purple') !global;
            $themed-gradient-green: map-get($_values, 'gradient-green') !global;
            $themed-gradient-red: map-get($_values, 'gradient-red') !global;
            $themed-gradient-orange: map-get($_values, 'gradient-orange') !global;
            $themed-gradient-blue: map-get($_values, 'gradient-blue') !global;
            $themed-gradient-teal: map-get($_values, 'gradient-teal') !global;
            $themed-red-light: map-get($_values, 'red-light') !global;
            $themed-red-dark: map-get($_values, 'red-dark') !global;
            $themed-orange-light: map-get($_values, 'orange-light') !global;
            $themed-orange-dark: map-get($_values, 'orange-dark') !global;
            $themed-green-light: map-get($_values, 'green-light') !global;
            $themed-green-dark: map-get($_values, 'green-dark') !global;
            $themed-teal-light: map-get($_values, 'teal-light') !global;
            $themed-teal-dark: map-get($_values, 'teal-dark') !global;
            $themed-blue-light: map-get($_values, 'blue-light') !global;
            $themed-blue-dark: map-get($_values, 'blue-dark') !global;
            $themed-purple-light: map-get($_values, 'purple-light') !global;
            $themed-purple-dark: map-get($_values, 'purple-dark') !global;
            $themed-magenta-light: map-get($_values, 'magenta-light') !global;
            $themed-magenta-dark: map-get($_values, 'magenta-dark') !global;
            $themed-active-slide-background: map-get($_values, 'active-slide-background') !global;
            $themed-active-slide-foreground: map-get($_values, 'active-slide-foreground') !global;
            @content;
        }
    }

    @include reset-theme();
}

@mixin theme-child($themes: $themes) {
    @include reset-theme();

    @each $_theme, $_values in $themes {
        @at-root {
            .theme-#{$_theme} & {
                $themed-background: map-get($_values, 'background') !global;
                $themed-foreground: map-get($_values, 'foreground') !global;
                $themed-link-config: map-get($_values, 'links') !global;
                $themed-button-1-config: map-get($_values, 'button-1') !global;
                $themed-button-2-config: map-get($_values, 'button-2') !global;
                $themed-button-3-config: map-get($_values, 'button-3') !global;
                $themed-button-on-config: map-get($_values, 'button-on') !global;
                $themed-button-disabled-config: map-get($_values, 'button-disabled') !global;
                $themed-focus-ring: map-get($_values, 'focus-ring') !global;
                $themed-light-text: map-get($_values, 'light-text') !global;
                $themed-light-color: map-get($_values, 'light-color') !global;
                $themed-medium-color: map-get($_values, 'medium-color') !global;
                $themed-inverted-color: map-get($_values, 'inverted-color') !global;
                $themed-input-bg: map-get($_values, 'input-bg') !global;
                $themed-primary-border: map-get($_values, 'primary-border') !global;
                $themed-light-border: map-get($_values, 'light-border') !global;
                // Background Colors
                $themed-base: map-get($_values, 'base') !global;
                $themed-gray: map-get($_values, 'gray') !global;
                $themed-gradient-gray: map-get($_values, 'gradient-gray') !global;
                $themed-gradient-magenta: map-get($_values, 'gradient-magenta') !global;
                $themed-gradient-purple: map-get($_values, 'gradient-purple') !global;
                $themed-gradient-green: map-get($_values, 'gradient-green') !global;
                $themed-gradient-red: map-get($_values, 'gradient-red') !global;
                $themed-gradient-orange: map-get($_values, 'gradient-orange') !global;
                $themed-gradient-blue: map-get($_values, 'gradient-blue') !global;
                $themed-gradient-teal: map-get($_values, 'gradient-teal') !global;
                $themed-red-light: map-get($_values, 'red-light') !global;
                $themed-red-dark: map-get($_values, 'red-dark') !global;
                $themed-orange-light: map-get($_values, 'orange-light') !global;
                $themed-orange-dark: map-get($_values, 'orange-dark') !global;
                $themed-green-light: map-get($_values, 'green-light') !global;
                $themed-green-dark: map-get($_values, 'green-dark') !global;
                $themed-teal-light: map-get($_values, 'teal-light') !global;
                $themed-teal-dark: map-get($_values, 'teal-dark') !global;
                $themed-blue-light: map-get($_values, 'blue-light') !global;
                $themed-blue-dark: map-get($_values, 'blue-dark') !global;
                $themed-purple-light: map-get($_values, 'purple-light') !global;
                $themed-purple-dark: map-get($_values, 'purple-dark') !global;
                $themed-magenta-light: map-get($_values, 'magenta-light') !global;
                $themed-magenta-dark: map-get($_values, 'magenta-dark') !global;
                $themed-active-slide-background: map-get($_values, 'active-slide-background') !global;
                $themed-active-slide-foreground: map-get($_values, 'active-slide-foreground') !global;
                @content;
            }
        }
    }

    @include reset-theme();
}
