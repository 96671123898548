﻿/// <reference path="../../entries/main">

// ------------- CART WRAPPER - STRUCTURE ------------- //
.#{$prefix}cart-wrapper {
    @include media(medium) {
        flex-direction: column;
    }

    .ace-cart-group {
        flex-grow: 1;
    }
}

// ------------- CART CTA - STRUCTURE ------------- //

.ace-cart-cta {
    margin-top: $gap;
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: $gap-sm;
    }

    @include media(large) {
        align-items: flex-start;
    }

    @include media-min(medium) {
        align-items: flex-end;
    }
}

// ------------- CART WRAPPER - STRUCTURE ------------- //
.#{$prefix}cart-wrapper {
    .ace-cart-summary {
        &-inner {
            background: $white;
        }

        &-inner-item {
            padding-top: $gap-sm;
            
            &.border-top {
                border-top: 1px solid $default-light-border;
            }
        }
    }
}

