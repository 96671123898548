﻿/// <reference path="../../entries/main">

@each $_theme, $_values in $themes {
    $_background: map-get($_values, 'background');
    $_foreground: map-get($_values, 'foreground');
    $_focus-ring: map-get($_values, 'focus-ring');

    $_text-color: $_foreground;

    @if map-has-key($_values, 'text-color') {
        $_text-color: map-get($_values, 'text-color');
    }

    .theme-#{$_theme},
    *[class*="theme"] .theme-#{$_theme},
    %theme-#{$_theme} {
        --text-color: #{$_text-color};
        --background-color: #{$_background};
        --focus-ring: #{$_focus-ring};
        --light-color: #{map-get($_values, 'light-color')};
        --light-text: #{map-get($_values, 'light-text')};
        color: $_text-color;
    }

    .theme-#{$_theme},
    %theme-#{$_theme} {
        // The background should be easily overridden in situations where you want the color scheme but not a new region
        background-color: $_background;
    }
}
