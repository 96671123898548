﻿/// <reference path="../entries/main">
$upcoming-events-breakpoint: "medium";

// Header
.upcoming-performances {

    &-block-header {
        margin: 0;

        @include theme-child() {
            border-bottom: none;
        }

        @include media(tablet) {
            flex-direction: column;
            padding-bottom: $gap-sm;

            & > * {
                width: 100%;
            }
        }

        .block-heading {
            @include media-min(tablet) {
                @include responsive-context($standard-padding-map) {
                    padding-right: $responsive-variable / 2;
                }
            }
        }
    }

    &-block-header-info {
        @include media(tablet) {
            justify-content: flex-start;
            flex-direction: column;
            margin: $gap-sm 0;
        }

        @include media-min(tablet) {
            .block-heading {
                max-width: 50%;
            }
        }
    }
}
// Event
.upcoming-event {
    @include responsive-context($gutter-map-h) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }

    @include media-min($upcoming-events-breakpoint) {
        display: flex;
        flex-wrap: wrap;
    }

    &-padded {
        @extend %standard-padding-h;
    }

    &-card {
        @extend %standard-max-width;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
            border-bottom: none;
        }
    }


    &-half {
        @include media($upcoming-events-breakpoint) {

            & + & {
                margin-top: $gap-sm;
            }
        }

        @include media-min($upcoming-events-breakpoint) {
            display: flex;
            width: 50%;

            & + & {
                @include theme-child() {
                    border-left: 1px solid $themed-light-border;
                }

                @include responsive-context($gutter-map-h) {
                    width: calc(50% - #{$responsive-variable});
                    padding-left: $responsive-variable/2;
                    margin-left: $responsive-variable/2;
                    flex-grow: 1;
                }
            }

            & > * {
                width: 100%;
            }

            & > * + * {
                @include responsive-context($gutter-map-h) {
                    padding-left: $responsive-variable;
                }
            }

            &-padded-right {
                @include responsive-context($gutter-map-h) {
                    padding-right: $responsive-variable;
                }
            }
        }
    }

    &-content {
        display: flex;

        @include media($upcoming-events-breakpoint) {
            flex-direction: column-reverse;
        }
    }

    &-price {
        min-width: 11rem;

        @include media-min($upcoming-events-breakpoint) {
            text-align: right;
        }
    }

    &-details {
        flex-grow: 1;

        &-description {
            margin-top: $space;
        }
    }

    &-date {
        margin-bottom: $gap;
        font-weight: $font-weight-bold;
        display: flex;
        flex-wrap: wrap;

        @include media-min($upcoming-events-breakpoint) {
            width: 11rem;
            margin: 0;
            display: block;
        }

        &-day {
            @include media($upcoming-events-breakpoint) {
                margin-right: $gap-sm;
            }
        }
    }

    &-image {
        flex-grow: 1;

        &-container {
            @extend %cropped-16-by-9;
        }

        @include media($upcoming-events-breakpoint) {
            margin-bottom: $gap;
            padding-bottom: $gap;

            @include theme-child() {
                border-bottom: 1px solid $themed-light-border;
            }
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-ctas {
        margin-top: $gap;

        & > * + * {
            margin-left: $space;
        }
    }
}

.ticketed-upcoming-performance-panel {

    .ace-cart-item-row-info {

        @include media(tablet) {
            display: flex;
        }

        @include media(medium) {
            span {
                display: block;

                & + & {
                    margin-left: 0 !important;
                }
            }
        }
    }
}
