﻿/// <reference path="../../entries/main">
// ------------- ACE MY PROFILE HEADER - STRUCTURE ------------- //

// Page structure
.#{$prefix}bestavailable {
    &-content {
        @extend %standard-padding-h;
        @extend %standard-padding-v;
        @extend %standard-max-width;
    }

    &-price {
        border-top: 1px solid;
        border-color: $default-light-border !important;
        padding: $gap-sm 0 0;
        @include media(small) {
            flex-direction: row;
        }
    }
    &-header {
        display: flex;
        gap: $gap-sm;
    }
}
