﻿/// <reference path="../entries/main">

%h1, %h2, %h3{
    font-family: $heading-font;
    font-weight: $font-weight-normal;
}
%h4, %h5, %h6 {
    font-family: $body-font;
    font-weight: $font-weight-bold;
}
%h1, .h1-style {
    @include font-size($h1-font-sizes);
}

%h2, .h2-style {
    @include font-size($h2-font-sizes);
}

%h3, .h3-style {
    @include font-size($h3-font-sizes);
}

%h4, .h4-style {
    @include font-size($h4-font-sizes);
}

%h5, .h5-style {
    @include font-size($h5-font-sizes);
}

%h6, .h6-style {
    @include font-size($h6-font-sizes);
    font-family: $accent-font;
}