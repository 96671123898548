﻿/// <reference path="../../entries/main">

// utilities

.#{$prefix} {
    &event-nav {
        border-top: 1px solid $black;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include responsive-context($x-small-gap-map) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }

        &-title {
            margin-top: $space;
        }
    }

    &event-intro {
        & + .#{$prefix}event-nav {
            @include responsive-context($x-small-gap-map) {
                margin-top: $responsive-variable;
            }
        }
    }

    &event-heading {
        @include responsive-context($xx-small-gap-map) {
            margin-bottom: $responsive-variable;
        }

        &-first {
            @extend %visually-hidden;
        }
    }

    &event-section {
        & + & {
            @include responsive-context($small-vertical-gap-map) {
                margin-top: $responsive-variable;
            }
        }
    }
}

.past-performances-list {
    .ticketed-item-section {
        &:after {
            content: none;
        }
    }
}