﻿/// <reference path="../../entries/main">

// ------------- CONFIRMATION DETAILS - STRUCTURE ------------- //
.#{$prefix}confirmation {


    &-section {
        padding-top: $space;

        @include media(small) {
            flex-direction: column;
        }

        &.ace-layout {
            @include media(medium) {
                border-top: none;
            }
        }

        & + & {
            border-top: 1px solid $default-light-border;
        }
    }

    &-subsection {
        margin: $gap-sm 0;

        @include media(small) {
            margin: 0 0 $gap-sm;
            padding-top: $gap-sm;

            & + & {
                border-top: 1px solid $default-light-border;
            }
        }
    }

    &-wrapper {
        // Dumb ACE override 
        padding: 0 !important;
    }
}
// ------------- CONFIRMATION SENT - STRUCTURE ------------- //
.#{$prefix}confirmation-sent {
    display: flex;
    margin-bottom: $gap;

    @include media(medium) {
        flex-direction: column;
    }

    @include media-min(medium) {
        justify-content: flex-end;

        &-inner {
            max-width: $max-input-width;
        }
    }

    &-inner {
        padding: $space $gap-sm;
        margin-bottom: $gap-sm;
    }
}
