﻿/// <reference path="../../../entries/main">

// GENERAL
.nav-anchor {
    display: flex;
    padding-top: $gap-md;
    margin-bottom: $gap-sm;

    @include media(tablet) {
        padding-top: $gap-sm;
        padding-bottom: $gap-sm;
        margin-bottom: 0;
    }

    &-container {
        position: sticky;
        top: calc(var(--total-nav-height) - 1px);
        z-index: $z-bottom-sticky-nav;

        @include theme-child() {
            background: $themed-background;
            border-bottom: 1px solid $themed-light-border;
        }
    }

    &-list {
        flex-grow: 1;
        display: flex;
        font-weight: $font-weight-bold;
        padding-right: $gap-sm;
        align-items: center;
        transition: all $transition ease-in-out;

        &-item {
            @extend %unlink;
        }
    }
}

// Desktop
.nav-anchor-list-desktop {
    margin-bottom: $space;

    @include media(tablet) {
        @include hide();
    }

    & * + * {
        margin-left: $gap-md;
    }
}

// Mobile Custom Dropdown
.nav-anchor-list-mobile {
    width: 100%;

    @include theme-child() {
        border: 1px solid $themed-inverted-color;
    }

    @include media-min(tablet) {
        @include hide();
    }



    .nav-anchor-list {
        flex-direction: column;
        align-items: flex-start;

        &-item {
            margin: $space;
        }
    }

    .nav-anchor-list-trigger {
        @extend %un-button;
        position: relative;
        padding: $space;
        padding-right: $gap;
        width: 100%;
        font-weight: $font-weight-bold;

        &::after {
            content: '';
            background-size: cover;
            display: block;
            position: absolute;
            right: $gap-sm;
            width: $gap-sm;
            height: $space;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition;

            @include theme-child() {
                @include icon-chevron($_bgcolor: $themed-inverted-color);
            }
        }

        &[aria-expanded="true"] {
            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
}
