﻿/// <reference path="../../entries/main">

// ------------- CART NOTIFICATION - STRUCTURE ------------- //
.#{$prefix}cart-notification {
    display: flex;
    justify-content: space-between;

    @include media(medium) {
        flex-direction: column;
    }
}

// ------------- CART NOTIFICATION INNER - STRUCTURE ------------- //
.#{$prefix}cart-notification-inner {
    display: flex;
    flex-basis: calc(50% - #{$gap-sm});
    margin-bottom: $gap;
    flex-direction: column;

    @include media(medium) {
        margin-bottom: $gap-sm;
        flex-basis: 100%;
    }
}

// ------------- CART NOTIFICATION ITEM - STRUCTURE ------------- //
.#{$prefix}cart-notification-item {
    display: flex;

    @include media-min(medium) {
        margin-bottom: $gap;
    }

    @include media(medium) {
        margin-bottom: $gap-sm;
    }
}

// ------------- CART NOTIFICATION ITEM MESSAGE - STRUCTURE ------------- //
.#{$prefix}cart-notification-item-message {
    padding: $gap-sm;
    margin-left: $gap-sm;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

// ------------- CART NOTIFICATION ITEM IMG - STRUCTURE ------------- //
.#{$prefix}cart-notification-item-img {
    flex-basis: $gap-lg;
    display: flex;
    align-items: center;

    img {
        width: 100%;
        height: auto;
    }
}

// ------------- CART NOTIFICATION ITEM - SKIN ------------- //
.#{$prefix}cart-notification-item {
    background: $white;
}
