﻿/// <reference path="../../entries/main">

.#{$prefix}cart-item {
    &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-barcode {
            display: block;
            width: 100%;
            max-width: pxToRem(150px);
            margin: 0 auto;
        }

        @include media(small) {
            display: block;
        }

        &-half {
            @include media(small) {
                display: flex;
            }
        }
    }

    &-rows {
        @include stack($spacing-map: $x-small-gap-map, $inner: true);

        & > *:first-child {
            border-top: none;
            padding-top: 0;
        }

    }

    &-row-info {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        span + span {
            margin-left: $space;
        }
        @include responsive-context($gutter-map-h) {
            width: 100%;

            & > * {
                flex: 1 1 0;
                margin-right: $responsive-variable;

                &:last-child {
                    margin-right: 0;
                }
            }
        }


        @include media(small) {
            display: block;
        }

        & > *:only-child {
            width: 100%;
            flex: 1 1 auto;
        }
    }

    &-row-price,
    &-row-supplementary {
        display: block;

        @include media-min(small) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex: 0 1 auto;
            width: 15%;
        }

        &:only-child {
            width: 100%;
            flex: 1 1 auto;
        }
    }
}

// Grid upgrade with more intricate responsive stacking
@supports(display: grid) {
    .#{$prefix}cart-item-row-info {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));





        @include responsive-context($gutter-map-h) {
            grid-column-gap: $responsive-variable;
        }

        @include media-min(large) {
            grid-column-gap: 2rem;
        }

        @include media(small) {
            grid-template-columns: 1fr;
        }

        &.#{$prefix}cart-item-row-info-half {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @include media(small) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

        & > * {
            margin-right: 0;

            @include media(small) {
                grid-column: span 2;

                &:first-child {
                    grid-column: span 4;
                }

                &:nth-child(4) {
                    grid-column: span 4;
                }
            }

            &:only-child {
                grid-column: 1 / -1;
            }
        }
    }
}
