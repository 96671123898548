﻿/// <reference path="../entries/main">

// ------------- CART TIMER - STRUCTURE ------------- //
.cart-timer {
    padding: $space;

    &-inner {
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        padding-left: $gap;
        padding-right: $gap;

        &-expired {
            display: none;
        }
    }

    &.expired &-inner {
        &-active {
            display: none;
        }

        &-expired {
            display: block;
        }
    }
}

// ------------- CART TIMER - SKIN ------------- //
.cart-timer {
    background: $primary-medium;

    &.expired {
        background: $ui-invalid;
    }

    &-inner {
        color: $white;
    }
}