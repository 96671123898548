﻿/// <reference path="../../entries/main">

.membership {
    &-header {
        border-bottom: 1px solid $default-light-border;
    }

    &-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &-panel {
        transition: $transition;
        flex-grow: 0;

        &.panel-child-open {
            flex-grow: 1;
        }
    }

    &-benefits {
        ul {
            padding-left: 0;
            li {
                padding-left: $gap;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: .375rem;
                    left: 0;
                    background-repeat: no-repeat;
                    background-position: top left;
                    @include icon-checkmark($_bgcolor: red);
                    height: .875rem;
                    width: 1.125rem;
                }

                & + li {
                    margin-top: $gap-sm;
                }
            }
        }
    }

    &-level {
        &-list-wrapper {
            // gap-sm is the gap we want and $gap is the height of the button at the bottom of the container
            padding-bottom: ($gap-sm + $gap);
        }

        &-cta {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 0 $gap-sm;

            .radio__control {
                @include hide();
                position: absolute;
                z-index: 1;
            }

            &-btn {
                @extend %btn-primary;
                width: 100%;
            }
        }

        &-gallery {
            .gallery {
                &-slide {
                    position: relative;
                    padding: $gap-md;
                    $gallery-item-gap: $gap-md*2;

                    width: calc(33% - #{$gallery-item-gap});

                    @include media(medium) {
                        width: calc(50% - #{$gallery-item-gap});
                    }

                    @include media(tablet) {
                        width: calc(97.5% - #{$gallery-item-gap});
                    }
                }

                &-2 {
                    @include hide-splide-functionality(tablet);

                    @include media-min(tablet) {
                        @include static-splide-overrides;

                        .gallery-slide {
                            width: 50%;
                        }
                    }
                }

                &-3 {
                    @include hide-splide-functionality;

                    @include media-min(medium) {
                        .gallery-slide {
                            width: 33.33%;
                        }
                    }
                }

                &-4 {
                    @include hide-splide-functionality;

                    @include media-min(medium) {
                        .gallery-slide {
                            width: 25%;
                        }
                    }
                }

                &-full {
                    .gallery-slide {
                        transition: $transition;

                        &.is-active-slide {
                            background-color: $primary-dark;
                            color: $primary-light;

                            .membership-level-cta-btn {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-membership-anchor] {
    scroll-margin-top: var(--nav-clearance);
}