/// <reference path="../../entries/main">

body {
    @extend %body;
}

.large {
    @extend %large;
}

.small {
    @extend %small;
}

a {
    @extend %hyperlink;

    &[href*="mailto"] {
        word-break: break-word;
    }

    &[class*="btn"] {
        text-decoration: none !important;
    }
}

b,
strong,
.bold {
    font-weight: $font-weight-bold;
}

.nowrap {
    white-space: nowrap;
}

em,
i,
.italic {
    font-style: italic;
}

sup,
sub {
    font-size: calc(50% + 0.4rem);
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.4em;
}

sub {
    top: 0.4em;
}

.align {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }
}

cite {
    @extend %body;
    font-style: normal;
}

.header + * {
    @extend %text-top-margin;
}

.label {
    @extend %label;
}

.tag {
    @extend %tag;
}

.quote {
    @extend %quote;
}

.light {
    @extend %light;
}

.uppercase {
    text-transform: uppercase;
}

.normal-weight {
    font-weight: $font-weight-normal;
}

.medium-weight {
    font-weight: $font-weight-medium;
}

.preheading {
    display: block;
    margin-bottom: $space;
}

.has-preheading {
    display: flex;
    flex-direction: column;

    & > * {
        max-width: 100%;
        min-height: 1px;
    }

    .preheading {
        order: -1;
        margin-top: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &[id] {
        scroll-margin-top: $sticky-nav-height + $gap-sm;
        scroll-margin-top: calc(var(--nav-clearance) + #{$gap});
    }
}

a {
    @include theme-child() {
        &[class*="btn"] {
            text-decoration: none;
        }
    }
}
.pingfang {
    font-family: $secondary-font;
}

.stxingkai {
    font-family: $tertiary-font;
}