﻿/// <reference path="../index.scss" />

@mixin blur-adjustment() {
    width: calc(100% + #{$blur-radius*2});
    height: calc(100% + #{$blur-radius*2});
    left: -$blur-radius;
    right: -$blur-radius;
    top: -$blur-radius;
    bottom: -$blur-radius;
}
