﻿/// <reference path="../../entries/main">

// ------------- ACE GIFT PREVIEW SELECTED - STRUCTURE ------------- //
.#{$prefix}gift-preview-selected {
    max-width: $max-content-width;
    margin-bottom: 2rem;
}

// ------------- ACE GIFT PREVIEW OPTIONS - STRUCTURE ------------- //

.#{$prefix}gift-preview-options {
    display: flex;

    @include media(medium) {
        flex-wrap: wrap;
    }

    li {
        margin-bottom: $gap-sm;
        flex-basis: calc(100% / 5 - #{$gap-sm});
        padding: $space;

        @include media(medium) {
            flex-basis: calc(100% / 3 - #{$space});
        }

        + li {
            margin-left: $gap-sm;

            @include media(medium) {
                margin-left: $space;

                &:nth-child(4n) {
                    margin-left: 0;
                }
            }
        }
    }
}
// ------------- ACE GIFT PREVIEW MESSAGE - STRUCTURE ------------- //
.#{$prefix}gift-preview-message {
    padding: $gap-sm;
}

// ------------- ACE GIFT PREVIEW OPTIONS - SKIN ------------- //
.#{$prefix}gift-preview-options {
    li {
        input:checked + label,
        input:focus + label,
        input:hover + label {
            box-shadow: 0 0 0 2px transparent;
        }
    }
}

// ------------- ACE GIFT PREVIEW OPTIONS - SKIN ------------- //
.#{$prefix}gift-preview-options {
    li {
        input:checked + label,
        input:focus + label,
        input:hover + label {
            outline-color: $primary-dark;
        }
    }
}

// ------------- ACE GIFT PREVIEW MESSAGE - SKIN ------------- //
.#{$prefix}gift-preview-message {
    color: $primary-dark;
    border-color: $primary-light;

    @include font-size($body-font-sizes);
}
