﻿/// <reference path="../../entries/main">

.password-input {
    position: relative;
    max-width: $max-input-width;

    &-toggle, &-lock {
        display: block;
        position: absolute;
        right: $gap;
        top: $gap;
        transform: translateY(-50%);
        width: $ui-icon-size;
        height: $ui-icon-size;
        background: no-repeat center center;
    }

    &-toggle {
        @include icon-eye-shown($form-input-accent);
        cursor: pointer;

        &[aria-pressed="true"] {
            @include icon-eye-hidden($form-input-accent);
        }
    }

    &-lock {
        right: auto;
        left: $svg-icon-sm / 2;
        @include icon-lock($primary-dark);
    }

    input:not([type="checkbox"]):not([type="radio"]) {
        padding-right: $svg-icon-sm * 2;
    }
}
