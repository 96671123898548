﻿/// <reference path="../../entries/main">
$grid-border-color: $black-16;

.ace-cal {
    &-grid {

        &-days,
        &-weekdays {
            display: flex;
            flex-wrap: wrap;
            margin-right: -1px;

            & > * {
                width: calc(#{1/7 * 100% - 0.001%} - 1px);
                min-height: 150px;
                margin-right: 1px;
                margin-bottom: 1px;
                flex: 0 0 auto;
            }

            @supports (display: grid) {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                //undoing IE fallbacks
                margin-right: 0;

                & > * {
                    width: auto;
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        &-weekdays {
            position: sticky;
            top: calc(var(--nav-clearance) - #{$space});
            transition: $transition;
            z-index: $z-calendar-filters;
            height: $weekdays-height;
            padding-top: $gap-sm;
            padding-bottom: $gap-sm;
            background: $white;
            margin-top: -1px;
            transition: 0.3s all ease-in-out;
        }

        &-weekday {
            min-height: 0;
            text-align: center;
            font-weight: $font-weight-bold;
            padding: $space 0;
        }

        &-month {
            @include responsive-property('margin-bottom', $standard-padding-map);

            &-name {
                margin-bottom: $gap-sm;
                //clear the sticky nav(s)
                margin-top: -$gap - $filter-height - $weekdays-height;
                padding-top: $gap + $filter-height + $weekdays-height;
            }
        }
    }
}

.ace-cal {
    &-grid {
        &-weekdays {
            background: $white;
            border-top: 1px solid $black;
        }

        &-day {
            background: $white;
            border-bottom: 1px solid $grid-border-color;
            min-width: calc(100%/7);

            .ace-cal-grid-day-wrapper {
                border-right: 1px solid $grid-border-color;
            }

            &-empty {
                background: $black-4;
            }

            &-wrapper {
                height: 100%;
            }

            &:nth-child(7n+1) {

                .ace-cal-grid-day-wrapper {
                    border-left: 1px solid $grid-border-color;
                }
            }
        }

        &-days {
            border-top: 1px solid $grid-border-color;
        }
    }
}
