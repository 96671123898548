﻿/// <reference path="../../entries/main">
$search-app-breakpoint: medium;

.#{$adage-prefix}search {
    &-container {
        @extend %standard-padding-h;
        padding-bottom: $space;

        &-inner {
            margin-top: $gap-sm;

            @include media-min(medium) {
                display: flex;
            }
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: $gap-sm 0;
        flex-wrap: wrap;
        gap: $gap-sm;
    }

    &-pagination {

        &-list {
            display: flex;
            align-items: center;

            &-item {
                padding: 0 $space;
            }
        }

        &-link {
            @extend %unlink;

            span {
                color: $gray-dark;
            }

            &.selected span {
                color: $black;
            }
        }

        &-arrows {
            display: flex;

            button[disabled] {
                opacity: 0.5;
            }
        }

        &-icon {
            display: flex;
            width: $gap-md;
            height: $gap;
            background-repeat: no-repeat;
            background-position: center;



            &-next {
                @include icon-chevron-alt($black);
            }

            &-prev {
                @include icon-chevron-alt($black);
                transform: rotate(180deg);
            }

            &-first {
                @include icon-chevron-double($black);
                transform: rotate(180deg);
            }

            &-last {
                @include icon-chevron-double($black);
            }
        }
    }
}
