﻿/// <reference path="../entries/main">

main .EPiServerForms {
    @extend %standard-margin-v;

    .FormRange .FormRange__Input {
        max-width: $max-input-width;
    }

    .Form__Element .FormRange {
        max-width: $max-input-width;

        & > span {
            display: flex;
            width: 100%;
        }
    }

    & > * {
        @extend %standard-max-width;
        @extend %standard-padding-h;
    }

    .Form__Element {
        .Form__Element__Caption {
            display: block;
            @extend %body;
            font-weight: $font-weight-bold;
        }

        legend.Form__Element__Caption {
            margin-bottom: $gap-sm;
        }
    }

    .FormSubmitButton {
        @extend %btn-primary;
    }

    .Form__Status .Form__Warning__Message {
        background-color: transparent;
        color: $ui-invalid;
        padding: 0;
    }

    .FormChoice {
        label {
            display: grid;
            grid-template-columns: 1em auto;
            gap: $_radiocheck-padding;
            font-weight: $font-weight-normal;

            &.FormChoice--Image__Item {
                display: flex;
                margin: 0;
                gap: 0;
                max-width: $max-input-width;

                & > span {
                    margin: 0 $_radiocheck-padding;
                    width: 6.25rem;
                    text-align: left;
                }

                & > img {
                    max-width: 6.25rem;
                    border: $standard-border;
                    border-color: transparent;
                }

                & + .FormChoice--Image__Item {
                    margin-top: 1em;
                }
            }
        }

        label + label {
            margin-top: 1em;
        }

        label:focus-within {
            color: var(--form-control-color);
        }

        input[type="radio"], input[type="checkbox"] {
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            /* For iOS < 15 */
            background-color: transparent;
            /* Not removed via appearance */
            margin: 0;
            font: inherit;
            color: currentColor;
            width: $_radiocheck-size;
            height: $_radiocheck-size;
            border: $_radiocheck-weight solid transparent;
            display: grid;
            place-content: center;
            padding: 0;
            position: relative;

            &::before {
                content: "";
            }
        }

        input[type="radio"], input[type="checkbox"] {
            &::before {
                width: $_radiocheck-size;
                height: $_radiocheck-size;
                background-size: cover;
                position: absolute;
                top: .1875em;
                left: 0;
            }

            &::after {
                // Manually adjust based on other sizing and check shape
                top: .25em;
                left: 0.4em;
                width: .875rem;
                height: .6875rem;
                background-size: cover;
                position: absolute;
            }

            &:checked::after {
                content: "";
            }
        }
    }

    .FormCaptcha {
        .FormTextbox__Input, img {
            width: 100%;
            max-width: $max-input-width;
        }

        img {
            width: 100%;
            max-width: $max-input-width;
            display: block;
            margin: 1rem 0;
        }
    }

    .Form__NavigationBar {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: $max-input-width;
        justify-content: space-between;

        .Form__NavigationBar__ProgressBar {
            margin: 0 $space;
            background-color: $black-16;
            border-color: $black;
        }

        .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Progress {
            background-color: $black;
        }

        .Form__NavigationBar__Action {
            @extend %btn-primary;
            background-image: none;
            height: auto;
            width: auto;
        }
    }
}


@include theme-parent() {
    .FormChoice label input[type="checkbox"]::before {
        @include icon-unchecked-checkbox-bg($themed-inverted-color);
    }

    .FormChoice label input[type="checkbox"]::after, .FormChoice label input[type="radio"]::after {
        @include icon-checkmark-bg($themed-inverted-color);
    }

    .FormChoice label input[type="checkbox"]:checked::before {
        @include icon-checkbox-bg($themed-inverted-color);
    }

    .FormChoice label input[type="radio"]::before {
        @include icon-unchecked-radio-bg($themed-inverted-color);
    }

    .FormChoice label input[type="radio"]:checked {
        &::before {
            @include icon-radio-bg($themed-inverted-color);
        }

        & ~ img {
            border-color: $themed-inverted-color;
        }
    }
}