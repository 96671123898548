﻿/// <reference path="./__index">

$typography-breakpoints: (
    default,
    medium
);

/// parameters: size, line-height, letter-spacing (optional)
$h1-font-sizes: ( //h1
    name: h1-text,
    default : (4rem, 1.1, -0.005em),
    medium : (2.75rem, 1.1, -0.005em)

);

$h2-font-sizes: ( //h2
    name: h2-text,
    default : (2.75rem, 1.2, 0em),
    medium : (2rem, 1.2, 0.017em)

);

$h3-font-sizes: ( //h3
    name: h3-text,
    default : (1.75rem, 1.3, 0.004em),
    medium: (1.5rem, 1.3, 0.023em)

);

$h4-font-sizes: ( //h4
    name: h4-text,
    default : (1.5rem, 1.4, 0.007em),
    medium : (1.25rem, 1.4, 0.007em)

);

$h5-font-sizes: ( //h5
    name: h5-text,
    default: (1.25rem, 1.5, 0.01em),
    medium: (1.125rem, 1.5, 0.013em)

);

$h6-font-sizes: (  //h6
    name: h6-text,
    default : (1.125rem, 1.6, 0.013em),
    medium : (1rem, 1.6, 0.013em)

);

$label-font-sizes: (
    name: label-text,
    default: (1rem, 1.075, 0.112em),
    medium: (0.875rem, 1.1, 0.109em)

);

$quote-font-sizes:(
    name: quote-text,
    default: (1.75rem, 1.4, 0em),
    medium: (1.5rem, 1.4, 0.011em)

);

$large-body-font-sizes: (
    name: large-body-text,
    default: (1.25rem, 1.6, 0.009em),
    medium: (1.125rem, 1.6, 0.013em)

);

$body-font-sizes: ( //body
    name: body-text,
    default : (1.125rem, 1.7, 0.012em),
    medium: (1rem, 1.7, 0.015em)
);


$tag-font-sizes: ( //tag
    name: tag-text,
    default : (1rem, 1.1, 0.12em),
    medium: (.875rem, 1.1, 0.12em)
);

$small-body-font-sizes: ( //body medium
    name: small-body-text,
    default : (0.875rem, 1.6, 0.025em),
    medium: (0.875rem, 1.6, 0.019em)
);

$_all-font-size-maps: (
    $h1-font-sizes,
    $h2-font-sizes,
    $h3-font-sizes,
    $h4-font-sizes,
    $h5-font-sizes,
    $h6-font-sizes,
    $label-font-sizes,
    $quote-font-sizes,
    $large-body-font-sizes,
    $body-font-sizes,
    $tag-font-sizes,
    $small-body-font-sizes
);