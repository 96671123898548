﻿/// <reference path="../../../entries/main">

.nav {
    &-header {
        border-bottom: 1px solid $default-light-border;
        padding-bottom: $space;

        @include responsive-context($small-vertical-gap-map) {
            padding-top: $responsive-variable/2;
        }
    }

    &-bar {
        @extend %flex-between;
        @extend %standard-padding-h;
        @extend %standard-max-width;
        position: relative;
        align-items: center;

        &-logo-closed, &-logo-open {
            @include responsive-context($logo-height-map) {
                max-height: $responsive-variable;
                width: auto;
            }
        }

        @include media(small) {
            min-height: $sticky-nav-height-mobile;
        }

        &-logo-open {
            display: none;
        }

        &-logo, &-menu {
            display: block;
            width: 20%;

            @include media(medium) {
                width: 33%;
            }

            @include media(small) {
                width: 50%;
            }
        }

        &-home {
            display: inline-block;
        }

        &-menu {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-menu-is-open &-bar-logo {
        &-open {
            display: block;
        }

        &-closed {
            display: none;
        }
    }

    &-menu-is-open &-utility-count {
        color: $black;
        background-color: $white;
    }

    &-bar-utility {
        display: flex;
        flex: 1 0 auto;

        & > * {

            @include responsive-context($small-gutter-map-h) {
                margin-left: calc(#{$responsive-variable} - #{$svg-inline-spacing * 2});
            }
        }
    }
}
