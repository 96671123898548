﻿// Basic palette
$white: #ffffff;
$black: #000000;
$charcoal: #1b2327;
$black-4: rgba($black, 0.04);
$black-8: rgba($black, 0.08);
$black-16: rgba($black, 0.16);
$black-32: rgba($black, 0.32);
$black-40: rgba($black, 0.4);
$black-56: rgba($black, 0.56);
$black-64: rgba($black, 0.64);
$black-80: rgba($black, 0.8);
$white-4: rgba($white, 0.04);
$white-8: rgba($white, 0.08);
$white-16: rgba($white, 0.16);
$white-32: rgba($white, 0.32);
$white-40: rgba($white, 0.4);
$white-56: rgba($white, 0.56);
$white-64: rgba($white, 0.64);
$white-80: rgba($white, 0.8);
$charcoal-8: rgba($charcoal, 0.08);
//Calendar Grid
$gray-lighter: mix($black, $white, 8%);
$gray-light: mix($black, $white, 16%);
$gray-dark: mix($black, $white, 72%);
$gray-medium: mix($black, $white, 48%);
$gray-neutral: mix($black, $white, 30%);

// Project colors
$primary-light: $white;
$primary-dark: $black;
$primary-medium: $black;

//Calendar
$calendar-light-blue: #CBE2F1;
$calendar-dark-blue: #5573EB;

$background-overlay: $black;
$form-input-accent: $black;
$ui-invalid: #e31837;
$ui-valid: #399c88;
$ui-warning: #f8980e;

$shadow: 0.16rem 0.16rem 0.6rem rgba($primary-dark, 0.3);

$themed-background: $white;
$themed-foreground: $primary-dark;
$themed-link-config: ($primary-dark, $primary-dark);
$themed-button-1-config: ($primary-dark, $white);
$themed-button-2-config: ($white, $primary-dark, $primary-dark);
$themed-button-3-config: (transparent, $primary-dark, $primary-dark);
$themed-button-on-config: ($black-64, $white);
$themed-button-disabled-config: ($black-16, $white);
$themed-focus-ring: $black;
$themed-light-text: $black;
$themed-light-color: $primary-light;
$themed-medium-color: $primary-dark;
$themed-inverted-color: $primary-dark;
$themed-input-bg: $black-4;
$themed-primary-border: $black;
$themed-light-border: $black-16;
$themed-light-text: $black-64;
$themed-active-slide-background: $primary-dark;
$themed-active-slide-foreground: $white;
$themed-screen: $charcoal-8;


// Themed Backgrounds:

$themed-base: $white;
$themed-gray: rgba(0,0,0,0.04);
$themed-red-light: #F7DAD0;
$themed-red-dark: #D76451;
$themed-orange-light: #FBEAD8;
$themed-orange-dark: #C17C38;
$themed-green-light: #CEEAB8;
$themed-green-dark: #62973B;
$themed-teal-light: #CBE2F1;
$themed-teal-dark: #4E99B6;
$themed-blue-light: #D3D9FE;
$themed-blue-dark: #5573EB;
$themed-purple-light: #F6DBFE;
$themed-purple-dark: #BF74EA;
$themed-magenta-light: #FBD3F3;
$themed-magenta-dark: #E04DB2;

// Dark Themed Backgrounds
$dark-base: $black;
$dark-gray: rgba(255, 255, 255, .12);
$dark-red-light: #C3392D;
$dark-red-dark: #A11313;
$dark-orange-light: #AA6726;
$dark-orange-dark: #844912;
$dark-green-light: #538330;
$dark-green-dark: #3E6324;
$dark-teal-light: #328098;
$dark-teal-dark: #035D67;
$dark-blue-light: #2F52CE;
$dark-blue-dark: #002F87;
$dark-purple-light: #9851CC;
$dark-purple-dark: #572D84;
$dark-magenta-light: #C41F93;
$dark-magenta-dark: #810065;

