﻿/// <reference path="../../entries/calendar">

@keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@mixin spinner($color, $dur, $width, $height: $width) {
    width: $width;
    height: $height;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 2px 1px 0 $color;
    animation: spin #{$dur} linear infinite;
}

.ace-cal {
    &-loading {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $white;
        // needs to be above tabs-list
        z-index: 11;

        @at-root {
            html:not([data-calendar-loading='true']) & {
                @include hide;
            }
        }

        img {
            position: absolute;
            width: $loader-size;
            height: auto;
            top: 50%;
            left: 50%;
            margin-top: ($loader-size / 2) * -1;
            margin-left: ($loader-size / 2) * -1;
        }
    }

    &-loader {
        @include spinner($secondary-calendar-medium, 1s, ($loader-size / $loader-weight));

        &-container {
            display: inline-block;
            transform: scale($loader-weight);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: (($loader-size / $loader-weight) / 2) * -1;
            margin-left: (($loader-size / $loader-weight) / 2) * -1;
        }
    }
}
