//----------------------
// Red Hat Font Mixin
//----------------------

@mixin printDMSerifFont(
  $weightValue: 400,
  $weightName: normal,
  $familyName: "DMSerifText",
  $style: "normal"
  ) {

  $filePath: "/Static/" + $familyName + "/" + $familyName + "-" + $weightName;

  // Using woff2 gives us the following browser support:
  // Chrome 36+
  // Safari 10+
  // Firefox 39+
  // Opera 23+
  // Edge 14+
  // Android 5+
  // iOS 10+
  @font-face {
    font-family: $familyName;
    src: url('#{$filePath}.woff2') format('woff2');
    font-style: #{$style};
    font-weight: $weightValue;
    font-display: fallback;
  }

  @font-face {
    font-family: $familyName;
    src: url('#{$filePath}Italic.woff2') format('woff2');
    font-style: italic;
    font-weight: $weightValue;
    font-display: fallback;
  }
}

@include printDMSerifFont(400, "Regular", $familyName: "DMSerifText");
@include printDMSerifFont(400, "Italic", $familyName: "DMSerifText", $style: "Italic");
