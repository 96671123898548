﻿/// <reference path="../entries/main">

// ------------- LOADER - STRUCTURE ------------- //
.#{$prefix}loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-loader;

    img {
        position: absolute;
        width: $loader-size;
        height: auto;
        top: 50%;
        left: 50%;
        margin-top: ($loader-size / 2) * -1;
        margin-left: ($loader-size / 2) * -1;
    }

    &-spinner {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

            &:nth-child(1) {
                animation-delay: -0.45s;
            }

            &:nth-child(2) {
                animation-delay: -0.3s;
            }

            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

// ------------- LOADER - SKIN ------------- //
.#{$prefix}loader {
    background: $white;

    &-spinner {
        div {
            border: 8px solid $primary-medium;
            border-color: $primary-medium transparent transparent transparent;
        }
    }
}
