﻿/// <reference path="../../../entries/main">

.nav-menu {
    transition: $transition opacity;
    border-top: 1px solid $white-32;

    &[aria-hidden="true"] {
        opacity: 0;
        height: 0;
        visibility: hidden;

        &,
        * {
            pointer-events: none;
        }
    }

    &[aria-hidden="false"] {
        opacity: 1;
    }

    &-inner {
        @extend %standard-padding-h;
        @extend %standard-max-width;
        display: flex;
        flex-direction: column;

        @include media(small) {
            display: block;
        }

        @include responsive-context($medium-vertical-gap-map) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }
    }

    &-wrapper {
        display: flex;
        padding-top: $gap;
        padding-bottom: $gap;

        @include media(medium) {
            flex-direction: column;
        }

        @include theme-child() {
            border-top: 1px solid $themed-foreground;
            border-bottom: 1px solid $white-32;
        }
    }

    &-row {
        display: flex;

        @include media(small) {
            flex-direction: column;
        }
    }

    &-half {
        width: 50%;
        flex: 1 1 auto;

        @include media(medium) {
            width: 100%;
        }
    }

    &-content {
        margin-left: $gap;
        padding-left: $gap;
        border-left: 1px solid $white-32;

        @include media(medium) {
            border-top: 1px solid $white-32;
            border-left: none;
            margin-left: 0;
            padding-left: 0;
            padding-top: $gap;
            width: 100%;

            .header-5050-header {
                min-height: 0;
            }
        }

        .header-5050-footer {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 0;
        }

        &-cta {
            margin-top: $gap;

            @include media(small) {
                width: 100%;
            }
        }
    }

    &-search {
        width: 100%;
        margin-top: 0;
        margin-bottom: $gap * 2;
        display: flex;

        @include media($navigation-utility-breakpoint) {
            margin-top: $gap-md;
            margin-bottom: $gap-md;
        }

        input {
            max-width: none;
            margin-right: $gap-sm;
        }
    }

    &-footer {
        margin-top: $gap-sm;

        &-link {
            margin-right: $gap-sm;
            text-decoration: underline;
            @extend %hyperlink;
        }
    }
}


.nav-menu-primary {
    padding-left: $gap;
    border-left: 1px solid $white-32;

    &-item + &-item {
        margin-top: $gap-md;
    }

    @include media(small) {
        border-top: 1px solid $white-32;
        border-left: none;
        margin-top: $gap-sm;
        margin-bottom: $gap;
        padding-top: $gap-sm;
        padding-left: 0;
    }
}

// Featured Section
.nav-menu-featured {
    margin-right: $gap;

    &-link {
        margin-bottom: $space;
    }

    &-item {
        padding-bottom: $gap-md;

        & + & {
            padding-top: $gap-md;
            margin-top: $gap-md;
            border-top: 1px solid $white-32;
        }

        @include media(medium) {
            padding-bottom: $gap-sm;

            & + & {
                padding-top: $gap-sm;
                margin-top: $gap-sm;
            }
        }
    }
}


// Trigger {
.nav-menu-trigger {
    @extend %un-button;
    border-radius: 2px;
    text-transform: uppercase;

    &-x {
        fill: currentColor;
        transition: none;
        stroke: none !important;
    }

    &[aria-expanded="false"] &-x {
        display: none;
    }

    &[aria-expanded="true"] &-hamburger {
        display: none;
    }
}

@include theme-parent() {
    .nav-menu-trigger {
        border: 1px solid $themed-foreground;
    }
}
