﻿/// <reference path="../../entries/main">

.pdp-performances {

    &-container {
        @extend %flex-fiftyfifty;

        & > * {
            &:nth-child(odd) {

                @include theme-child() {
                    border-bottom: 1px solid $themed-light-border;
                    padding-bottom: $gap;
                    margin-bottom: $gap;

                    @include media-min(medium) {
                        border-right: 1px solid $themed-light-border;
                        border-bottom: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include media(medium) {
            display: block;

            & > * {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:first-child ~ * {
                    margin-top: $gap-md;
                }
            }
        }
    }

    &-description {
        padding-right: $gap-sm;
    }

    &-list {
        @include media-min(medium) {
        margin-left: -$gap-sm;
    }
    }

    &-item {
        padding: $gap-sm;
        margin-bottom: 1px;

        &-row {
            display: flex;

            @include media(small) {
                flex-direction: column;
            }

            @include media-min(small) {
                align-items: center;

                &-date {
                    margin-right: $gap-md;
                }
            }

            & + & {
                margin-top: $space;
            }
        }
    }

    &-buy-cta {
        min-width: pxToRem(160px);

        @include media(small) {
            width: 100%;
            margin-top: $gap-sm;
            margin-left: 0;
        }

        &-container {
            text-align: right;
        }
    }
}
