﻿/// <reference path="../../entries/calendar">

.ace-cal {
    &-production {
        &-tickets {
            @include responsive-context($card-padding-h, $card-padding-v) {
                padding: $responsive-variable-2 $responsive-variable;
            }
        }
    }

    &-list {
        @include theme-child() {
            border-top: 1px solid $themed-primary-border;
        }

        &-month-empty {
            @include responsive-context($gutter-map-h) {
                padding-top: $responsive-variable;
                padding-bottom: $responsive-variable;
                margin-bottom: $responsive-variable;
            }
        }

        &-days-empty {
            margin-top: $gap-sm;
        }

        &-load-more {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        &-month-name {
            margin: $gap-sm 0;
        }
    }
}

@include theme-parent() {
    .ace-cal {
        &-production-tickets {
            background: $themed-background;
        }
    }
}