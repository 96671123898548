﻿/// <reference path="../../entries/main">
.#{$adage-prefix}search {
    &-app-form {
        @include media-min(small) {
            display: flex;
        }

        @include media(medium) {
            margin-top: $gap-md * 2;
        }

        &-inner {
            display: flex;
            margin-top: $gap-sm;
            gap: $gap-sm;

            @include media-min(small) {
                flex: 1 1 auto;
                margin-top: 0;
            }
        }

        select {
            width: auto;
            min-width: 300px;
            border-top: none;
            border-left: none;
            border-right: none;

            @include media(small) {
                width: 100%;
            }
        }

        &-textbox {
            flex: 1 1 auto;
            position: relative;

            input {
                &[type=text] {
                    margin: 0;
                    width: 100%;
                    max-width: none;
                    background: $white;
                    border: 1px solid $black;
                    border-radius: 0;
                }
            }
        }

        &-button {
            white-space: nowrap;
            border: none;
        }

        .global-sort {
            position: relative;

            &-label {
                position: absolute;
                top: -$gap;
                left: 0;
            }
        }
    }

    &-autocomplete {
        position: absolute;
        background-color: $primary-light;
        margin-top: 0;
        width: 100%;
        display: none;
        border-bottom: 1px solid $primary-dark;
        border-left: 1px solid $primary-dark;
        border-right: 1px solid $primary-dark;
        margin-top: 0 !important;

        &.autocomplete-on {
            display: block;
        }

        &-list {
            &-item {
                padding: $space;

                &:hover, &.highlighted {
                    background-color: $gray-light
                }
            }
        }
    }

    &-results-info {
        display: flex;
        gap: $space / 2;
        margin: $gap-sm 0;
    }
}
