﻿/// <reference path="../../entries/main">

// ------------- ACE GUEST PASSWORD - STRUCTURE ------------- //
.#{$prefix}guest-password {
    .validation-summary-errors {
        margin-top: $gap;
    }

    .field-validation-error {
        scroll-margin-top: var(--nav-clearance);
    }
}


// ------------- ACE GUEST PASSWORDS INPUTS - STRUCTURE ------------- //
.#{$prefix}guest-password-inputs {
    margin-top: $gap;
}
