﻿/// <reference path="../entries/main">

.header {
    &-block {
        min-height: calc(100vh - #{$sticky-nav-height});
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // Using initial value that won't be updated on scroll, but is sized to avoid the bottom menu
        min-height: calc((var(--vh-initial) * 100) - var(--total-nav-height));
        display: flex;
        position: relative;
        will-change: min-height;
        transform: translateZ(0);

        @include media(tablet) {
            min-height: calc(50vh - #{$sticky-nav-height});
            min-height: calc((var(--vh-initial) * 75) - var(--nav-clearance));
        }
    }

    &-overlay {
        background: $background-overlay;
    }
}

.header-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba($black, .3) 0%, rgba($black, .6) 100%);
    z-index: $z-header-content;
    backdrop-filter: blur(5px);

    &-pretitle {
        text-transform: uppercase;
    }

    &-wrapper {
        color: $white;
        @extend %standard-padding-h;
        @extend %standard-max-width;
    }

    &-inner {
        display: flex;
        border-top: 1px solid $white;
        margin-bottom: $gap;
        margin-top: $gap-md;
        padding-top: $gap-md;

        @include media(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-header {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        & * + * {
            margin-top: $gap-sm;
        }

        @include media(tablet) {
            width: 100%;


            & * + * {
                margin-top: $space;
            }
        }
    }

    &-footer {
        display: flex;
        align-items: flex-end;

        @include media(tablet) {
            align-items: flex-start;
            width: 100%;
            margin-top: $gap-sm;
            justify-content: space-between;

            & > * + * {
                margin-top: $gap-sm;
            }
        }

        @include media(small) {
            flex-direction: column;
        }


        &-ctas {
            & * + * {
                margin-left: $space;
            }

            @include media(tablet) {
                width: 100%;
            }

            @include media(small) {
                display: flex;
                flex-direction: column;

                & * + * {
                    margin-top: $space;
                    margin-left: 0;
                }
            }
        }
    }

    .video-full-bleed-controls {
        position: relative;
        right: auto;
        bottom: auto;
        top: auto;
        left: auto;
        z-index: $z-video-controls;
        margin-left: $space;
        margin-top: 0;
        display: flex;

        * + * {
             margin-left: $space;
        }

        @include theme-child() {
            .btn-icon-round {
                border-radius: 100%;
                margin: 0;
                height: pxToRem(50px);
                width: pxToRem(50px);
                padding: .75rem;
            }

            svg {
                margin: 0;
                width: $gap-sm;
                height: $gap-sm;
            }
        }

        @include media(small) {
            display: none;
        }
    }
}

// Page header specific to header block
.home-header-block {
    @include media(tablet) {

        .header-content-inner {
            flex-direction: row;
        }

        .header-content-footer {
            justify-content: flex-end;
            align-items: flex-end;
        }

        .header-content-header, .header-content-footer-ctas {
            width: auto;
        }
    }

    @include media(small) {
        .header-content-footer-ctas {
            width: 100%;
        }

        .header-content-inner {
            flex-direction: column;
        }
    }
}
