﻿/// <reference path="../../entries/calendar">

.ace-cal {
    &-sub-nav {
        padding-top: $gap-md;
        padding-bottom: $gap-md;
        transition: $transition;
        z-index: 1;
        margin-bottom: $gap-sm;

        @include responsive-context( $standard-padding-map) {
            margin-top: $responsive-variable;
        }
    }

    &-event-nav {
        @include responsive-context( $gutter-map-v) {
            margin-bottom: $responsive-variable;
        }

        button {
            margin-right: $space;
            margin-bottom: $space;
        }
    }
}
