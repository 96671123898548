﻿/// <reference path="../entries/main">
$preview-card-breakpoint: "tablet";

// Card
.preview-card {
    border-top: $standard-border $black;

    &-card {
        @extend %standard-max-width;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
            border-bottom: none;

            &:first-child {
                border-color: $black;
            }

            &:last-child {
                border-bottom: 1px solid $black;
            }
        }
    }

    &-details {
        display: flex;
        flex-direction: column;
        gap: $gap;
        padding-bottom: $gap;

        &-primary {
            @include media(medium) {
                gap: $gap-sm;
            }
        }

    }

    &-main {
        padding: $gap 0;

        @include media-min(small) {
            display: flex;
            width: 100%;

            & > * {
                width: 50%;
                padding-right: $gap;

                &:last-child {
                    padding-right: 0;
                }

                &:nth-child(even) {
                    padding-left: $gap;
                }

                &:nth-child(odd) {
                    @include ace-layout-psuedo-decorator;

                    &::after {
                        top: 0;
                        right: 0;
                    }

                    @include theme-child() {
                        &::after {
                            background-color: $themed-light-border;
                        }
                    }

                    &.preview-card-section-borderless {
                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }

        @include media(small) {
            & > * {
                & + * {
                    margin-top: $gap;
                }
            }
        }

        &-limited {
            max-width: $max-line-length * 2;
        }
    }


    &-image {
        background-color: $black;
        @extend %cropped-16-by-9;

        img {
            object-position: var(--focal-point-h) var(--focal-point-v);
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-ctas {
        margin-top: $gap;

        & > * + * {
            margin-left: $space;
        }
    }

    &-event-name {
        @extend %unlink;

        @include media-min($preview-card-breakpoint) {
            margin-top: $space;
        }
    }

    &-panel {
        border-top: $standard-border transparent;
        transition: $transition;

        &.panel-child-open {
            border-color: $black-16;
        }

        &-inner {
            padding-top: $gap;

            &.full {
                @include media-min(medium) {
                    padding-left: $max-standard-padding;
                    padding-right: $max-standard-padding;
                }
            }
        }
    }

    &-seat-details {
        span {
            margin-right: $gap-sm;
        }
    }

    &-seat-info {
        display: flex;

        &-container {
            margin: $gap-sm 0;
        }

        .seat-detail {
            margin-right: $gap-sm;

            @include media(small) {
                margin-right: $space;
            }
        }

        @include media(small) {
            flex-direction: column;

            & + & {
                margin-top: $gap-sm;
            }
        }
    }

    &-renewal-fallback-form {
        margin-top: $gap;
    }
}
