﻿/// <reference path="../../entries/main">


input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent; 
    cursor: pointer;
    width: 100%;
    max-width: $max-input-width;
    border: none;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
    background: $black-16;
    height: 0.5rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
    background: $black-16;
    height: 0.5rem;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -9px; /* Centers thumb on the track */
    background-color: $black;
    height: 1.5rem;
    width: .75rem;
}

/***** Thumb Styles *****/
/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: $black;
    height: 1.5rem;
    width: .75rem;
}