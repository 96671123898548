﻿/// <reference path="../entries/main">
.ticketed-item {
    border-top: $standard-border $black-16;

    &-panel {
        border-top: $standard-border transparent;
        transition: $transition;

        &.panel-child-open {
            border-color: $black-16;
        }

        &-inner {
            padding-top: $gap-md;

            &.full, .full {
                @include media-min(medium) {
                    padding-left: $max-standard-padding;
                    padding-right: $max-standard-padding;
                }
            }
        }
    }

    &-image {
        background-color: $black;
        @extend %cropped-16-by-9;
        background-size: cover;
    }

    &-inner {
        padding: $gap 0;

        @include media-min(small) {
            display: flex;
            width: 100%;

            & > * {
                width: 50%;
                padding-right: $gap;

                &:last-child {
                    padding-right: 0;
                }

                &:nth-child(even) {
                    padding-left: $gap;
                }

                &:nth-child(odd) {
                    @include ace-layout-psuedo-decorator;

                    &::after {
                        top: 0;
                        right: 0;
                    }

                    @include theme-child() {
                        &::after {
                            background-color: $themed-light-border;
                        }
                    }
                }
            }
        }

        @include media(small) {
            & > * {
                & + * {
                    margin-top: $gap;
                }
            }
        }
    }


    &-toggle {
        @extend %btn-primary;

        &[aria-expanded=true] {
            @extend %btn-outline;
        }
    }


    &-icon {
        fill: none;
        stroke: $primary-dark;
    }
}
