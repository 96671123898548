﻿/// <reference path="../../entries/main">

// ------------- CART TOTALS LIST - STRUCTURE ------------- //
.#{$prefix}cart-totals-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media(medium) {
        align-items: flex-start;
    }

    > li {
        display: flex;
        width: 100%;

        @include media(medium) {
            width: 100%;
            justify-content: space-between;
        }

        .label, .value {
            display: block;
            text-transform: none;
        }

        .label {
            width: 100%;
        }

        .value {
            text-align: right;

            @include media(medium) {
                width: 100%;
            }
        }

        &.ace-cart-total {
            padding-top: $gap-sm;
            border-top: 1px solid $black-16;
            margin-top: $gap-sm;
        }
    }
}

// ------------- CART TOTALS LIST SUB - STRUCTURE ------------- //
.#{$prefix}cart-totals-list-sub {
    width: 100%;

    > li {
        display: flex;
        width: 100%;

        .label, .value {
            display: block;
        }

        .label {
            width: 100%;
        }

        .value {
            text-align: right;

            @include media(medium) {
                width: 100%;
            }
        }
    }
}



// ------------- CART TOTALS LIST - SKIN ------------- //
.#{$prefix}cart-totals-list {
    > li {
        .label, .value {
            @extend %body;
        }

        .value {
            font-weight: $font-weight-bold;
        }

        &.ace-cart-total {
            @include font-size($body-font-sizes);
        }
    }
}

// ------------- CART TOTALS LIST SUB - SKIN ------------- //
.#{$prefix}cart-totals-list-sub {
    > li {
        .label, .value {
            color: $primary-dark;
        }

        .value {
            font-weight: $font-weight-bold;
        }
    }
}

// ------------- CART SUMMARY TOTALS - SKIN ------------- //
.#{$prefix}cart-summary-totals {
    border-color: $primary-light;
}

.#{$prefix}cart-total {
    .label {
        @extend %large;
    }
}

.#{$prefix}cart-actions-btn {
    width: 100%;
    max-width: 100%;

    a {
        width: 100%;
        max-width: 100%;
    }

    &-continue {
        a {
            @extend %btn-secondary;
        }
    }

    &-checkout {
        a {
            @extend %btn-primary;
        }
    }
}

.#{$prefix}cart-empty {
    text-align: center;
}
