﻿/// <reference path="../entries/main">


// STRUCTURE
.header-5050 {
    @extend %flex-fiftyfifty-bordered;


    & > * {
        &:nth-child(2n+1) {
            @include theme-child() {
                border-right-color: $themed-light-border;
            }

            @include media(tablet) {
                border-right: none;
            }
        }
    }

    &-container {

        @include media-min(tablet) {
            padding-top: $gap-sm;

            @include theme-child() {
                border-top: 1px solid $themed-primary-border;
            }
        }
    }


    &-header {
        padding-bottom: $gap-sm;
        // value pulled from Figma
        min-height: pxToRem(200px);

        &.no-margin {
            min-height: 0;
        }
    }

    &-footer {
        padding: $gap-md 0;
        border-top: 1px solid;
        flex-grow: 1;
        // value pulled from Figma
        min-height: pxToRem(130px);

        @include theme-child() {
            border-top-color: $themed-light-border;
        }

        &.no-margin {
            min-height: 0;
            padding: 0;
        }
    }


    &-content {
        display: flex;
        flex-direction: column;

        @include theme-child() {
            border-right-color: $themed-light-border;

            @include media(tablet) {
                padding-top: $gap-sm;
                border-top: 1px solid $themed-primary-border;
            }
        }
    }

    &-btn-container {
        display: flex;
        align-items: center;

        & > * + * {
            margin-left: $gap-sm;
        }

        @include media(small) {
            flex-direction: column;

            & > [class*="btn"] {
                width: 100%;
            }

            & > * + * {
                margin-left: 0;
                margin-top: $gap-sm;
            }
        }
    }

    &-block-padded {
        @extend %block-padding-v;
    }

    &-image {
        @include media(tablet) {
            margin-top: 0;
            margin-bottom: $gap-md;
        }
        img {
            width: 100%;
        }
    }
}

// When image is present, remove height requirements
.header-5050 {
    .header-5050-footer, .header-5050-header {
        @include media(tablet) {
            min-height: 0;
        }
    }
}
