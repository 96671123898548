﻿/// <ref/// <reference path="../../../entries/main">
$navigation-breakpoint: medium;
$navigation-utility-breakpoint: tablet;


.nav {
    z-index: $z-navigation;
    transition: $transition transform;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: $sticky-nav-height;

    @include media(medium) {
        min-height: $sticky-nav-height-mobile;
    }

    @include supports-vars {
        &-has-loaded {
            position: fixed;
        }
    }

    &:not(&-has-loaded) {
        transition: none;
    }

    a {
        text-decoration: none;
        border: 1px solid transparent;

        &:hover {
            border-color: transparent;
        }
    }

    &:not(&-menu-is-open) {
        background: $default-background;
    }

    &-menu-is-open {
        height: 100vh;
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
        // this is just for the "jump" if vh unit size changes
        transition: $transition height;

        .nav-header {
            border-bottom: none;
        }
    }


    &-background {
        @extend %full-cover;
        pointer-events: none;
        transition: $transition;
        transition-property: opacity;
        position: fixed;
        opacity: 0;

        &-blur {
            position: absolute;
            @extend %blur-adjustment;
            @extend %blur-background;
            @extend %background-with-focal-point;
            pointer-events: none;
        }

        ~ * {
            position: relative;
        }
    }

    &-menu-is-open &-background {
        opacity: 1;
    }
}

@import '_nav-bar.scss', '_nav-utility.scss', '_nav-menu.scss', '_nav-subnav.scss', '_nav-anchor.scss';
