﻿/// <reference path="../entries/main">

.header-video {
    &-container {
        @extend %full-cover;
        overflow: hidden;
        position: relative;
        display: flex;
    }

    &-image-container {
        @extend %full-cover;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &-container &-image-container {
        position: absolute;
    }

    &-full-bleed {
        @extend %full-cover;
        position: absolute;
    }
}

// Deal with showing/hiding on load

.header-video .video-full-bleed-container,
.header-video-image-container {
    transition: $transition;
    opacity: 0;
    pointer-events: none;
}

.header-block .video-full-bleed-container {
    &:not([aria-hidden='true']) {
        opacity: 1;
    }
}

.header-video-image-container {
    &:not([aria-hidden='true']) {
        opacity: 1;
    }

}

