﻿/// <reference path="../entries/main">

$link-list-gutter: $gap-sm;

.linklist {
    display: flex;
    flex-wrap: wrap;
    padding-top: $gap-sm;
    margin-bottom: -$gap-sm;

    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: $link-list-gutter;
        margin-bottom: $link-list-gutter;
        align-items: flex-start;

        @include media(small) {
            padding: $link-list-gutter 0;
        }
    }

    &-item {
        width: calc(100% / 4);
        display: flex;
        margin-bottom: $link-list-gutter;

        @include media-range(small, medium) {
            width: calc(100% / 2);

            &:nth-child(even) {
                .linklist-wrapper {
                    padding-right: 0;
                }
            }
        }

        @include media(small) {
            width: 100%;
        }
    }

    &-link {
        flex-grow: 1;

        a {
            font-weight: $font-weight-bold;
            @extend %unlink;
        }
    }

    &-icon svg {
        height: $gap-md;
        width: $gap-md;

        @include theme-child() {
            fill: none;
            stroke: $themed-foreground;
            stroke-width: 2px;
        }
    }
}

// Handle Borders For Each Layout
@include theme-parent() {
    .linklist {
        border-top: 1px solid $themed-primary-border;

        &-item {
            border-bottom: 1px solid $themed-light-border;

            @include media-min(medium) {
                .linklist-wrapper {
                    border-right: 1px solid $themed-light-border;
                }

                &:nth-child(4n) {
                    .linklist-wrapper {
                        border: none;
                    }
                }
            }

            @include media-range(small,medium) {

                &:nth-child(odd) {
                    .linklist-wrapper {
                        border-right: 1px solid $themed-light-border;
                    }
                }

                &:nth-child(even) {
                    .linklist-wrapper {
                        border: none;
                    }
                }
            }
        }
    }
}
