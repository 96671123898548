﻿@keyframes splide-loading {
    0% {
        transform: rotate(0);
    }

    to {
        transform: rotate(1turn);
    }
}

.splide__container {
    position: relative;
    box-sizing: border-box;
}

.splide__list {
    margin: 0 !important;
    padding: 0 !important;
    width: -webkit-max-content;
    width: max-content;
    will-change: transform;
}

.splide.is-active .splide__list {
    display: flex;
}

.splide__pagination {
    display: inline-flex;
    align-items: center;
    width: 95%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
}

.splide__pagination li {
    list-style-type: none;
    display: inline-block;
    line-height: 1;
    margin: 0;
}

.splide {
    visibility: hidden;
}

.splide, .splide__slide {
    position: relative;
    outline: none;
}

.splide__slide {
    box-sizing: border-box;
    list-style-type: none !important;
    margin: 0;
    flex-shrink: 0;
}

.splide__slide img {
    vertical-align: bottom;
}

.splide__slider {
    position: relative;
}

.splide__spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #999;
    border-left-color: transparent;
    animation: splide-loading 1s linear infinite;
}

.splide__track {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
    -webkit-user-select: none;
    user-select: none;
}

.splide--fade > .splide__track > .splide__list {
    display: block;
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
    position: relative;
    z-index: 1;
    opacity: 1;
}

.splide--rtl {
    direction: rtl;
}

.splide--ttb > .splide__track > .splide__list {
    display: block;
}

.splide--ttb > .splide__pagination {
    width: auto;
}

// Video extension

.splide__slide--has-video {
    cursor: pointer;
}

.splide__slide--has-video:hover .splide__video__play {
    opacity: 1;
}

.splide__slide__container--has-video {
    cursor: pointer;
    position: relative;
}

.splide__slide__container--has-video:hover .splide__video__play {
    opacity: 1;
}

.splide__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
}

.splide__video div {
    height: 100%;
}

.splide__video iframe, .splide__video video {
    width: 100%;
    height: 100%;
}

.splide__video__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ccc;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

.splide__video__play::after {
    content: "";
    display: inline-block;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 7px 0 7px 11px;
    margin-left: 4px;
}