﻿/// <reference path="../entries/main">


.toggletip {
    &-container {
        position: relative;
    }

    &-trigger {
        text-decoration: none;
        display: inline-block;

        & > * {
            display: inline-block;
        }
    }

    &-content {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        margin-bottom: $gap-sm;
        border: 1px solid $black;
        background: $white;
        padding: $gap;
        padding-right: $gap * 2 + $space;
        max-width: $max-content-width / 2 - $gap;
        z-index: 2;

        @include media(small) {
            min-width: 60vw;

        }

        &[aria-hidden="true"] {
            display: none;
        }

        &::after {
            content: '';
            width: $ui-icon-size;
            height: $ui-icon-size;
            display: block;
            border: 1px solid $black;
            border-width: 0 0 1px 1px;
            position: absolute;
            top: 100%;
            left: $gap * 2;
            transform: rotate(-45deg) translateY(-50%);
            background: $white;
            margin-top: -2px;
        }

        & > *:nth-child(2) ~ * {
            margin-top: $space;
        }
    }

    &-close {
        position: absolute;
        top: $gap;
        right: $gap;

        &::before,
        &::after {
            background-color: $black;
        }
    }
    // Container around a form label that has a toggletip
    &-label {
        display: flex;
        align-items: center;
        position: relative;
    }

    &-label &-trigger {
        margin-left: $space;
    }
}
