﻿/// <reference path="../../entries/calendar">

.ace-cal {
    &-back-to-top {
        @include responsive-context($standard-padding-map) {
            right: ($responsive-variable / 2) - (($svg-icon-sm *1rem/1em) / 2);
        }

        @include media(small) {
            right: $space;
        }

        bottom: $gap;
        position: fixed;
        z-index: 10;
        transition: $transition opacity;
        opacity: 0;
        pointer-events: none;

        &[data-show-button="true"] {
            opacity: 1;
            pointer-events: fill;
        }
    }
}
