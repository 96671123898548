﻿/// <reference path="../entries/main">
$bio-image-map: ( default : 188px, large : 164px, medium : 164px, small : 132px );

// Styles
.bio {

    &-header {
        @extend %block-header;
    }

    &-content {
        margin-top: $gap-sm;
        margin-left: $gap;
        width: calc(50% - #{$gap});

        @include media(small) {
            width: 100%;
            margin-top: $space;
            margin-left: 0;
        }
    }


    &-reveal-cta {
        display: block;
        margin: 0 auto;
    }

    &-items {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        text-align: center;



        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: $gap;
            flex-grow: 1;

            @include theme-child() {
                border-right: 1px solid $themed-light-border;
            }

            &-inner {
                @extend %unlink;
            }

            &-image {
                position: relative;
                width: 100%;
                padding-bottom: 100%;
                height: 0;
                margin-bottom: $gap-sm;

                &-container {
                    margin: 0 auto;

                    @include responsive-context($bio-image-map) {
                        min-width: $responsive-variable;
                        max-width: $responsive-variable;
                        min-height: $responsive-variable;
                        max-height: $responsive-variable;
                    }
                }



                .block {
                    margin: 0;
                }

                img, picture {
                    object-position: var(--focal-point-h) var(--focal-point-v);
                    object-fit: cover;
                    border-radius: 50%;
                    @extend %full-cover;
                }
            }
        }

        &-item-wrapper {
            width: calc((100 / 4) * 1%);
            max-width: calc((100 / 4) * 1%);
            margin-bottom: $gap;
            display: flex;

            @include theme-child() {
                border-bottom: 1px solid $themed-light-border;
            }

            @include media-min(medium) {
                &:nth-child(4n+4) {
                    .bio-items-item {
                        border-right: none;
                    }
                }
            }


            @include media(medium) {
                width: calc((100 / 2) * 1%);
                max-width: calc((100 / 2) * 1%);

                &:last-child, &:nth-child(even) {
                    .bio-items-item {
                        border-right: none;
                    }
                }
            }

            @include media(small) {
                width: 100%;
                max-width: 100%;

                .bio-items-item {
                    border-right: none;
                }
            }
        }
    }
}

// No Image Overrides
.bio-items-item-wrapper.no-images {

    .bio-items-item {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding-left: $gap;
    }

    @include media-min(medium) {
        &:nth-child(4n-7) {
            .bio-items-item {
                padding-left: 0;
            }
        }
    }

    @include media(medium) {
        &:nth-child(odd) {
            .bio-items-item {
                padding-left: 0;
            }
        }
    }

    @include media(small) {
        .bio-items-item {
            padding-left: 0;
        }
    }
}
