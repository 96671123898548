﻿/// <reference path="../../entries/main">

.#{$prefix}payment {

    &-section {
        border-top: 1px solid $black;
        padding: $gap 0;
    }

    &-subsection {
        border-top: 1px solid $black-16;
        padding: $gap 0;
    }

    &-section, &-subsection {
        &.flush-bottom {
            padding-bottom: 0;
        }
    }

    &-schedule {
        @extend %responsive-column-gap;
        counter-reset: installment;

        @include media-min(medium) {
            column-count: 2;
        }

        &-item {
            display: flex;
            width: 100%;
            counter-increment: installment;
            justify-items: flex-start;
            padding-right: $gap;

            &::before {
                @extend %label;
                content: counter(installment) ".";
                min-width: 3ch;
                display: inline-block;
                text-align: right;
                margin-right: $space;
                flex: 0 0 auto;
            }

            & > *:last-child {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    &-group {
        & + & {
            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable/2;
            }
        }
    }

    &-summary {
        border-top: $standard-border $black;
        padding-bottom: $gap-sm;
        margin-bottom: $gap-sm;

        .flex-between {
            align-items: center;
        }
    }
}

// Payment Specific Line Items
.payment-line-item {
    border-top: $standard-border $black-16;

    &-main {
        @extend %standard-max-width;
        padding-top: $gap-md;
    }
}


.acceptance-mark {
    width: auto;
    flex: 0 0 auto;
    height: calc(var(--body-text-height) * var(--body-text));

    * + & {
        margin-left: $space;
    }

    &-input {
        display: flex;
        align-items: center;
    }

    &.input-indicator {
        height: calc(var(--body-text-height) * var(--body-text) + var(--button-form-padding-h) * 2);
    }
}

.payment-modal-container {
    .modal-inner {
        padding: $gap;
    }

    .modal-header {
        margin-bottom: 0;
        margin-left: -$gap;
        margin-right: -$gap;
        padding: $gap;
        padding-bottom: $gap-sm;
    }

    &-logo {
        text-align: center;
        margin-top: -$space;

        img {
            max-height: 3rem;
        }
    }

    .adyen-checkout {
        &__payment-method--selected {
            background: transparent;
            border-radius: 0;
            border: none;
        }

        &__payment-method--standalone .adyen-checkout__payment-method__header,
        &__payment-method__details {
            padding-left: 0;
            padding-right: 0;
        }

        &__input {
            border: $button-form-border solid transparent;
            border-radius: $border-radius;
            background: $black-4;
            border-bottom: 1px solid $black;

            &:focus, &--focus, &--focus:hover {
                box-shadow: none;
                border-color: $black;
            }
        }

        &__input {
            &--focus, &:hover, &:active, &:focus:hover, &:active, &:active:hover {
                box-shadow: none;
                border-color: $black;
            }
        }

        &__button {
            border-radius: 0;
            background: $black;

            &:hover, &:active, &:focus {
                background: $black-64;
            }
        }

        &-form-instruction {
            margin-bottom: $gap-md;
        }


        &__label--focused .adyen-checkout__label__text {
            color: $black;
        }


        &__card__brands img {
            display: block;
        }
    }

    .payment-modal-active-payment-plan {
        .adyen-checkout__checkbox {
            display: none;
        }
    }

    .tms-content-width {
        display: none;
    }
}
