﻿/// <reference path="../../entries/main">

@function generate-background-icon($color: $black, $type: 'invalid-icon') {
    $_url-color: currentColor;

    @if $color != currentColor {
        @if str-slice(#{$color}, 1, 1) == '#' {
            $_url-color: '%23' + str-slice(#{$color}, 2, -1);
        }

        @else {
            $_url-color: $color;
        }
    }

    @if $type == 'eye-hidden' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M3.4,2.5L2,3.9l2.7,2.7c-1.6,1.3-2.9,3-3.7,5C2.7,15.9,7,19,12,19c1.5,0,3-0.3,4.3-0.8l3.4,3.4l1.4-1.4L3.4,2.5z M9.5,11.4 C9.5,11.4,9.5,11.4,9.5,11.4l2.6,2.5c0,0-0.1,0-0.1,0C10.6,14,9.5,12.9,9.5,11.4C9.5,11.5,9.5,11.5,9.5,11.4z M12,17 c-3.8,0-7.2-2.1-8.8-5.5C3.9,10.1,4.9,8.9,6.1,8l1.8,1.8c-0.2,0.6-0.4,1.2-0.4,1.8c0,2.5,2,4.5,4.5,4.5c0.6,0,1.2-0.1,1.8-0.4l1,1 C13.9,16.9,13,17,12,17z M20.8,11.5C19.2,8.1,15.8,6,12,6c-0.7,0-1.3,0.1-2,0.2L8.4,4.6C9.5,4.2,10.7,4,12,4c5,0,9.3,3.1,11,7.5 c-0.7,1.8-1.8,3.3-3.2,4.5l-1.4-1.4C19.4,13.8,20.2,12.7,20.8,11.5z M13,9.2l-2.1-2.1C11.3,7.1,11.6,7,12,7c2.5,0,4.5,2,4.5,4.5 c0,0.4-0.1,0.7-0.1,1.1l-2.1-2.1C14,9.9,13.6,9.5,13,9.2z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'eye-shown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M23,11.5C21.3,7.1,17,4,12,4S2.7,7.1,1,11.5c0,0,0,0,0,0c0,0,0,0,0,0C2.7,15.9,7,19,12,19S21.3,15.9,23,11.5 C23,11.5,23,11.5,23,11.5C23,11.5,23,11.5,23,11.5z M12,17c-3.8,0-7.2-2.1-8.8-5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C4.8,8.1,8.2,6,12,6 s7.2,2.1,8.8,5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C19.2,14.9,15.8,17,12,17z M16.5,11.5C16.5,9,14.5,7,12,7s-4.5,2-4.5,4.5c0,0,0,0,0,0 c0,0,0,0,0,0c0,2.5,2,4.5,4.5,4.5S16.5,14,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5z M12,14 c-1.4,0-2.5-1.1-2.5-2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C9.5,10.1,10.6,9,12,9s2.5,1.1,2.5,2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0 C14.5,12.9,13.4,14,12,14z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'select-dropdown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' height='43' width='43'%3E%3Cpolygon fill='#{$_url-color}' points='26.5,19 21.5,24 16.5,19 '/%3E%3C/svg%3E");
    }

    @elseif $type == 'invalid-icon' {
        @return url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10 20.5C4.4858 20.5 0 16.0146 0 10.5C0 4.9858 4.4858 0.5 10 0.5C15.5142 0.5 20 4.9858 20 10.5C20 16.0146 15.5142 20.5 10 20.5ZM10 2.5C5.5889 2.5 2 6.0889 2 10.5C2 14.9111 5.5889 18.5 10 18.5C14.4111 18.5 18 14.9111 18 10.5C18 6.0889 14.4111 2.5 10 2.5ZM9 4.5V11.5H11V4.5H9ZM11.25 14.5C11.25 15.1904 10.6904 15.75 10 15.75C9.30964 15.75 8.75 15.1904 8.75 14.5C8.75 13.8096 9.30964 13.25 10 13.25C10.6904 13.25 11.25 13.8096 11.25 14.5Z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'valid-icon' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height='16' width='16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M8,1.3c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7S11.7,1.3,8,1.3z M11.7,6.5l-4.9,4.9 c-0.1,0.1-0.3,0.1-0.4,0L4.2,9.1C4.1,9,4.1,8.9,4.2,8.8l0.6-0.6c0.1-0.1,0.3-0.1,0.4,0l1.5,1.5l4.2-4.2c0.1-0.1,0.3-0.1,0.4,0 l0.6,0.6C11.8,6.2,11.8,6.4,11.7,6.5z'/%3E%3C/svg%3E");
    }

    @elseif $type == 'arrow-right' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'arrow-left' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M4,12l8,8l1.4-1.4L7.8,13H20v-2H7.8l5.6-5.6L12,4L4,12z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'plus-sign' {
        @return url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M14.25 10.25H9.75V14.75H8.25V10.25H3.75V8.75H8.25V4.25H9.75V8.75H14.25V10.25Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'minus-sign' {
        @return url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M14,8H0V6h14V8z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-down' {
        @return url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L7 6.5L13 0.5' stroke='#{$_url-color}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-up' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M16.59 15.705L18 14.295L12 8.29498L6 14.295L7.41 15.705L12 11.125L16.59 15.705Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-right' {
        @return url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'checkmark' {
        @return url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M6.00002 11.2L1.80002 6.99998L0.400024 8.39998L6.00002 14L18 1.99998L16.6 0.599976L6.00002 11.2Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'lock' {
        @return url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M14,7h-1V5c0-2.8-2.2-5-5-5S3,2.2,3,5v2H2C0.9,7,0,7.9,0,9v10c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V9 C16,7.9,15.1,7,14,7z M8,16c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S9.1,16,8,16z M11.1,7H4.9V5c0-1.7,1.4-3.1,3.1-3.1 s3.1,1.4,3.1,3.1V7z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'checkbox-bg' {
        @return url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M16.5,19h-14c-0.7,0-1.3-0.3-1.8-0.7S0,17.2,0,16.5v-14c0-0.7,0.3-1.3,0.7-1.8S1.8,0,2.5,0h11C13.8,0,14,0.2,14,0.5 S13.8,1,13.5,1h-11C2.1,1,1.7,1.2,1.4,1.4S1,2.1,1,2.5v14c0,0.4,0.2,0.8,0.4,1.1S2.1,18,2.5,18h14c0.4,0,0.8-0.2,1.1-0.4 s0.4-0.7,0.4-1.1v-7C18,9.2,18.2,9,18.5,9S19,9.2,19,9.5v7c0,0.7-0.3,1.3-0.7,1.8S17.2,19,16.5,19z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'unchecked-checkbox-bg' {
        @return url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M16.5,19h-14C1.1,19,0,17.9,0,16.5v-14C0,1.1,1.1,0,2.5,0h14C17.9,0,19,1.1,19,2.5v14C19,17.9,17.9,19,16.5,19z M2.5,1 C1.7,1,1,1.7,1,2.5v14C1,17.3,1.7,18,2.5,18h14c0.8,0,1.5-0.7,1.5-1.5v-14C18,1.7,17.3,1,16.5,1H2.5z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'checkmark-bg' {
        @return url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M3.5,11c-0.1,0-0.3,0-0.4-0.1l-3-3C0,7.7,0,7.3,0.1,7.1s0.5-0.2,0.7,0l2.6,2.6l9.6-9.6c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7 l-10,10C3.8,11,3.6,11,3.5,11z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'unchecked-radio-bg' {
        @return url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10.5,21C4.7,21,0,16.3,0,10.5C0,4.7,4.7,0,10.5,0C16.3,0,21,4.7,21,10.5C21,16.3,16.3,21,10.5,21z M10.5,1 C5.3,1,1,5.3,1,10.5S5.3,20,10.5,20s9.5-4.3,9.5-9.5S15.7,1,10.5,1z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'radio-bg' {
        @return url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10.5,21c-1.2,0-2.5-0.2-3.6-0.6c-2.1-0.8-3.9-2.2-5.2-4.1C0.5,14.3-0.1,12.1,0,9.8c0.1-2.2,1-4.4,2.5-6.1 C4,2,6,0.8,8.2,0.3c2.2-0.5,4.5-0.3,6.6,0.7C15,1,15.1,1.3,15,1.6s-0.4,0.4-0.7,0.3c-1.9-0.8-4-1-6-0.6c-2,0.4-3.8,1.5-5.1,3.1 C2,5.9,1.2,7.8,1,9.9c-0.1,2,0.4,4.1,1.5,5.8c1.1,1.7,2.8,3,4.7,3.7c1.9,0.7,4,0.8,6,0.2c2-0.6,3.7-1.8,4.9-3.4 c1.2-1.6,1.9-3.6,1.9-5.7V9.6c0-0.3,0.2-0.5,0.5-0.5S21,9.3,21,9.6v0.9c0,2.2-0.7,4.5-2.1,6.3c-1.3,1.8-3.3,3.1-5.4,3.8 C12.5,20.9,11.5,21,10.5,21z' /%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'chevron-double' {
        @return url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpath d='M6.8,18.5c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l5.6-5.6L6.4,6.4c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l6,6 c0.2,0.2,0.2,0.5,0,0.7l-6,6C7,18.5,6.9,18.5,6.8,18.5z'/%3E%3Cpath d='M11.2,18.7c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l5.6-5.6l-5.6-5.6c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l6,6 c0.2,0.2,0.2,0.5,0,0.7l-6,6C11.5,18.6,11.4,18.7,11.2,18.7z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'chevron-alt' {
        @return url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpath d='M9,18.5c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l5.6-5.6L8.6,6.4c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l6,6 c0.2,0.2,0.2,0.5,0,0.7l-6,6C9.3,18.5,9.1,18.5,9,18.5z'/%3E%3C/svg%3E");
    }
}

@mixin icon-eye-hidden($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-hidden');
}

@mixin icon-eye-shown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-shown');
}

@mixin select-dropdown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-down');
}

@mixin icon-chevron-right($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-right');
}

@mixin icon-invalid($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'invalid-icon');
}

@mixin icon-arrow($_bgcolor, $_direction: 'right') {
    @if $_direction == 'right' {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
    }

    @else {
        background-image: generate-background-icon($_bgcolor, 'arrow-left');
    }
}

@mixin icon-plus($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'plus-sign');
}

@mixin icon-minus($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'minus-sign');
}

@mixin icon-chevron($_bgcolor, $_direction: 'down') {
    @if $_direction == 'down' {
        background-image: generate-background-icon($_bgcolor, 'chevron-down');
    }

    @else {
        background-image: generate-background-icon($_bgcolor, 'chevron-up');
    }
}

@mixin icon-checkmark($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'checkmark');
}

@mixin icon-lock($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'lock');
}

@mixin icon-unchecked-checkbox-bg($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'unchecked-checkbox-bg'); 
}

@mixin icon-unchecked-radio-bg($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'unchecked-radio-bg');
}

@mixin icon-checkbox-bg($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'checkbox-bg');
}

@mixin icon-checkmark-bg($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'checkmark-bg');
}

@mixin icon-radio-bg($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'radio-bg');
}
@mixin icon-chevron-double($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-double');
}
@mixin icon-chevron-alt($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-alt');
}
