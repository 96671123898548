﻿/// <reference path="../../entries/main">
$ace-layout-breakpoint: $tablet-media-query-size;

@mixin ace-layout-psuedo-decorator($pseudo: "after") {
    position: relative;

    &::#{$pseudo} {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
    }
}

.#{$prefix}layout {
    display: flex;
    flex-direction: column;
    margin-bottom: -1 * $max-gutter-height;
    margin-bottom: calc(var(--gutter-v) * -1);
    width: 100%;

    & > * {
        flex: 0 1 auto;
        margin-bottom: $max-gutter-height;
        margin-bottom: var(--gutter-v);
    }

    &-align-center {
        @include media-min(medium) {
            align-items: center;
        }
    }

    &-no-vertical-margin {
        margin-bottom: 0;

        & > * {
            margin-bottom: 0;
        }
    }

    &.#{$prefix}layout-flush {
        padding-top: 0;
        padding-bottom: 0;
    }
}

// Variants based on pattern of items across
.#{$prefix}layout {
    &.reverse-mobile {
        @include media(small) {
            flex-direction: column-reverse;
        }
    }

    @include media-min(small) {
        flex-direction: row;
        flex-wrap: wrap;

        &-2-eq,
        &-4-eq {
            & > * {
                width: 50%;
                padding-right: $gap;

                &:last-child {
                    padding-right: 0;
                }

                &:only-child {
                    padding-right: $gap;
                }
            }
        }

        &-2-eq {
            & > *:nth-child(even) {
                @include ace-layout-psuedo-decorator($pseudo: 'before');
                padding-left: $gap;

                @include theme-child() {
                    &::before {
                        background-color: $themed-light-border;
                    }
                }

                &::before {
                    top: 0;
                    left: 0;
                }
            }
        }

        &-2-eq-large {
            @include media-min(large) {
                & > * {
                    padding-right: $gap-lg;

                    &:nth-child(even) {
                        padding-left: $gap-lg;
                    }
                }
            }
        }
    }

    &-3-eq {
        @include media(tablet) {
            flex-direction: column;
        }

        @include media-min(tablet) {
            & > * {
                width: calc(#{$one-third} - #{$max-gutter-width});
                width: calc(#{$one-third} - var(--gutter-h));
            }
        }
    }

    &-3-eq-to-2 {
        @include media(tablet) {
            flex-direction: column;
        }

        @include media-min(tablet) {
            & > * {
                width: calc(50% - #{$max-gutter-width});
                width: calc(50% - var(--gutter-h));
            }
        }

        @include media-min(large) {
            & > * {
                width: calc(#{$one-third} - #{$max-gutter-width});
                width: calc(#{$one-third} - var(--gutter-h));
            }
        }
    }

    @include media-min(medium) {
        &-4-eq {
            & > * {
                width: calc(25% - #{$max-gutter-width});
                width: calc(25% - var(--gutter-h));
            }
        }
    }
}

// Variants with layouts other than equal
.#{$prefix}layout {
    &-8-4,
    &-4-8 {
        @include media($ace-layout-breakpoint) {
            flex-direction: column;
        }
    }

    &-4-8-large {
        @include media(large) {
            flex-direction: column;
        }
    }

    @include media-min($ace-layout-breakpoint) {

        &-8-4 > *:nth-child(odd),
        &-4-8 > *:nth-child(even) {
            // 8
            width: calc(#{$one-third * 2} - #{$gap});
            border-top: 1px solid;
            @include ace-layout-psuedo-decorator;

            @include theme-child() {
                border-top-color: $themed-primary-border;

                &::after {
                    background-color: $themed-light-border;
                }
            }
        }

        &-8-4 > *:nth-child(odd)::after {
            right: -$gap;
        }

        &-4-8 > *:nth-child(even)::after {
            left: -$gap;
        }


        &-4-8 > *:nth-child(odd),
        &-8-4 > *:nth-child(even) {
            // 4
            width: calc(#{$one-third} - #{$gap});
            border-top: 1px solid;

            @include theme-child() {
                border-top-color: $black;
            }
        }

        &-8-4, &-4-8 {
            & > *:nth-child(even) {
                margin-left: $gap;
            }
        }

        &-8-4, &-4-8 {
            & > *:nth-child(odd) {
                margin-right: $gap;
            }
        }
    }

    @include media-min(large) {
        &-4-8-large > *:nth-child(even) {
            // 8
            width: calc(#{$one-third * 2} - #{$max-gutter-width});
            width: calc(#{$one-third * 2} - var(--gutter-h));
        }

        &-4-8-large > *:nth-child(odd) {
            // 4
            width: calc(#{$one-third} - #{$max-gutter-width});
            width: calc(#{$one-third} - var(--gutter-h));
        }
    }

    @include theme-child() {
        @include media($ace-layout-breakpoint) {
            padding-top: $gap-sm;
            border-top: 1px solid $themed-primary-border;
        }
    }

    &.ace-layout-borderless {
        & > * {
            border-top: none;

            &::after, &::before { //Hide the border
                display: none;
            }
        }
    }

    &.ace-layout-borderless {
        &-mobile {
            @include media(small) {
                border-top: none;
                padding-top: 0;
            }
        }

        &-tablet {
            @include media(tablet) {
                border-top: none;
                padding-top: 0;
            }
        }

        &-medium {
            @include media(medium) {
                border-top: none;
                padding-top: 0;
            }
        }
    }
}



.#{$prefix}sidebar {
    @include responsive-context($gutter-map-h) {
        padding: $responsive-variable;
        border-radius: $border-radius;
    }

    & + & {
        margin-top: $space;
    }
}



.#{$prefix}layout {
    &.ace-layout-borderless {
        border-top: none;

        & > * {
            border-top: none;

            &::after, &::before { //Hide the border
                display: none;
            }
        }
    }

    &.ace-layout-borderless-mobile {
        @include media(small) {
            border-top: none;
            padding-top: 0;
        }
    }
}
