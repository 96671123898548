﻿/// <reference path="../../entries/main">

.hide {
    display: none;
    visibility: hidden;
}

.#{$prefix}no-margin-b {
    margin-bottom: 0;
}

.svg-icon, .svg-icon-small {
    fill: currentColor;
    vertical-align: middle;
    display: inline-block;
    // visual adjustment to align better with text
    margin-bottom: 0.125em;
}

.svg-icon {
    width: $svg-icon-sm;
    height: $svg-icon-sm;
}

.svg-icon-small {
    width: $svg-icon-sm * .75;
    height: $svg-icon-sm * .75;
}

.svg-on-right {
    margin-left: $svg-inline-spacing;
}

.svg-on-left {
    margin-right: $svg-inline-spacing;
}

.btn-round {
    border-radius: 999em;
}

.gray {
    padding-top: 1px;
    padding-bottom: 1px;
}

.card {
    @extend %card-padding;

    &-vertical-padding-reduced {
        @include responsive-context($xx-small-gap-map) {
            padding-bottom: $responsive-variable;
        }

        & + & {
            @include responsive-context($xx-small-gap-map) {
                padding-top: $responsive-variable;
            }
        }
    }
}

.card-sm {
    @extend %small-card-padding;
}

.transparent {
    background-color: transparent;
}
