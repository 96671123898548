﻿/// <reference path="../../entries/main">
$filters-width: 20rem;

.#{$adage-prefix}search {
    &-filters {
        width: $filters-width;
        margin-right: $gap-md*2;

        @include media($search-app-breakpoint) {
            width: 100%;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            gap: $space;
            padding-bottom: $space;
            margin-bottom: $gap;
            border-bottom: 1px solid $default-light-border;

            @include media($search-app-breakpoint) {
                margin-bottom: $gap-sm;
                flex-direction: column;
            }

            &-desktop {
                display: none;

                @include media-min($search-app-breakpoint) {
                    display: block;
                }
            }
        }

        &-clear {
            @extend %hyperlink;
            @extend %un-button;
        }

        &-list {
            @include media($search-app-breakpoint) {
                margin-bottom: $gap;
            }

            &-item {
                display: flex;

                & + & {
                    margin-top: $gap;
                }

                input[type=checkbox] {
                    margin-top: 7px;
                }

                label {
                    flex: 1 1 auto;
                }

                &-number {
                    @extend %small;
                    margin-top: 0 !important;
                    display: inline-block;
                }
            }
        }

        &-reset {
            @include media($search-app-breakpoint) {
                display: none;
            }
        }

        &-trigger {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-min($search-app-breakpoint) {
                display: none;
            }

            &-icon {
                @include select-dropdown($black);
                height: $gap-md;
                width: $gap-md;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        &-mobile {
            &-controls {
                display: flex;
                justify-content: space-between;
                border-top: 1px solid $primary-dark;
                border-bottom: 1px solid $primary-dark;
                padding: $space 0;
                align-items: center;

                @include media-min($search-app-breakpoint) {
                    display: none;
                }
            }

            &-reset {
                @extend %un-button;
                text-decoration: underline;
            }
        }

        &-display-toggle {
            @include link-helper();
            @extend %small;


            &.show-toggle {
                &:after {
                    content: "+";
                    display: inline-block;
                    margin-left: $space;
                }
            }

            &.hide-toggle {
                &:after {
                    content: "-";
                    display: inline-block;
                    margin-left: $space;
                }
            }
        }

        &-section {
            @include media($search-app-breakpoint) {
                display: none;
            }
        }
    }
}

    .#{$adage-prefix}search-filters.active {
        .#{$adage-prefix}search-filters-section {
            display: block;
        }

        .#{$adage-prefix}search-filters-trigger-icon {
            transform: rotate(180deg)
        }
    }
