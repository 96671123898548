﻿/// <reference path="../entries/main">


// STRUCTURE
.audio-block.inline {
    max-width: $max-line-length;
}

.audio-block, .audio-item {
    & > audio {
        width: 100%;

        @include theme-child() {
            background: $themed-foreground;
        }
    }

    .audio-image-wrapper {
        position: relative;

        & > audio {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    audio {
        @include theme-child {
            background: $white;
            border: 1px solid $themed-light-border;

            &::-webkit-media-controls-panel {
                background: $white;
            }
        }
    }
}