﻿/// <reference path="../../entries/main">


.pdp {
    &-info {
        display: flex;
        margin-bottom: $gap-lg;

        @include media(tablet) {
            margin-bottom: $gap;
        }

        @include media(small) {
            flex-direction: column;
        }

        &-header {
            @extend %block-header;
            margin-top: $gap-lg;

            @include media(tablet) {
                margin-top: $gap;
            }
        }

        &-item {
            & + & {
                margin-top: $gap;
            }
        }
    }
    // Left Columns
    &-info-details {
        display: flex;
        width: 25%;

        .pdp-info-col {
            width: 100%;
            padding-right: $gap;
            margin-right: $gap-md;

            @include theme-child() {
                border-right: 1px solid $themed-light-border;
            }
        }

        &.two-col {
            width: 50%;

            @include media(small) {
                width: 100%;
            }

            .pdp-info-col {
                width: 50%;
            }
        }

        @include media(medium) {
            flex-direction: column;
            margin-right: $gap;


            .pdp-info-col + .pdp-info-col {
                margin-top: $gap;
            }

            @include theme-child() {
                border-right: 1px solid $themed-light-border;

                .pdp-info-col {
                    width: 100%;
                    border-right: none;
                    margin-right: 0;
                }
            }
        }

        @include media(small) {
            width: 100%;
            margin-bottom: $gap;
            margin-right: 0;
            padding-bottom: $gap;

            @include theme-child() {
                border-right: none;
                border-bottom: 1px solid $themed-light-border;
            }
        }
    }
    // Right Column
    &-info-description {
        width: 50%;

        @include media(small) {
            width: 100%;
        }

        &-header {
            margin-bottom: $gap-sm;
        }
    }
}
