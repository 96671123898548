﻿/// <reference path="../../entries/main">

@import '_information.scss', '_performances.scss', '_sticky-nav.scss';

.pdp-page {
    div[id], section[id] {
        scroll-margin-top: $sticky-nav-height + $gap-sm;
        scroll-margin-top: calc(var(--pdp-nav-clearance) + #{$gap});

        @include media(tablet) {
            scroll-margin-top: calc(var(--pdp-nav-clearance) + #{$gap-lg});
        }
    }
}
