﻿/// <reference path="../../entries/main">

.content-header {
    @extend %block-padding-v;

    &.reduced-padding {
        @include responsive-context($block-margin-map) {
            padding-top: $responsive-variable / 2;
            padding-bottom: $responsive-variable / 2;
        }
    }
}
