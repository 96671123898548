﻿/// <reference path="../entries/main">

$_modal-max-width: $max-modal-width;
$_modal-overlay-color: rgba($black, 0.6);
$_modal-background-color: $white;

// STRUCTURE & LAYOUT
.modal {
    &-container {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        z-index: $z-modal;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        height: calc(var(--vh, 1vh) * 100);
        opacity: 1;
        display: flex;
        justify-content: center;
        overflow-y: auto;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
            // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: $_modal-max-width;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $black-16;
        align-items: center;

        @include responsive-context($gutter-map-h) {
            padding: $responsive-variable;
        }

        @include responsive-context($gutter-map-h, $gutter-map-v) {
            margin-top: -$responsive-variable-2;
            margin-left: -$responsive-variable;
            margin-right: -$responsive-variable;
            margin-bottom: $responsive-variable-2;
        }
    }

    &-footer {
        margin-top: $gap-sm;
        border-top: 1px solid $black-16;

        @include responsive-context($gutter-map-h) {
            padding: $responsive-variable;
        }

        @include responsive-context($gutter-map-h, $gutter-map-v) {
            margin-top: $responsive-variable-2;
            margin-left: -$responsive-variable;
            margin-right: -$responsive-variable;
            margin-bottom: -$responsive-variable-2;
        }
    }
}



// STYLING
.modal {
    &-container {
        @include responsive-context($gutter-map-h, $gutter-map-v) {
            padding: $responsive-variable-2 $responsive-variable;
        }

        background-color: $_modal-overlay-color;
        transition: 0.3s opacity;
    }

    &-inner {
        @include responsive-context($gutter-map-h, $gutter-map-v) {
            padding: $responsive-variable-2 $responsive-variable;
        }

        background-color: $_modal-background-color;
        pointer-events: auto;

        &-full-bleed {
            padding: 0;
        }

        .#{$prefix}form-item {
            margin-right: 0;
        }
    }

}

// CLOSE BUTTON
.modal {
    &-close {
        pointer-events: auto;
    }
}
