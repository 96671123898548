/// <reference path="../../entries/calendar">

.mini-cal {
    &-column {
        position: relative;

        @include media(medium) {
            margin-top: 0;

            [data-sticky-element] {
                &,
                & + .sticky-placeholder {
                    @include hide;
                }
            }
        }
    }

    &-nav {
        position: sticky;
        align-self: flex-start;
        transition: $transition;

        @include responsive-context($gutter-map-h, $gutter-map-v) {
            top: $responsive-variable-2;
            top: calc(var(--nav-margin-offset) + #{$responsive-variable-2} + #{$max-standard-padding});
            margin-bottom: $responsive-variable-2;
        }
    }

    &-container {
        display: inline-block;
        max-width: 100%;
        width: 20rem;

        @include theme-child {
            border-top: 1px solid $themed-foreground;
        }
    }

    &-footer {
        @include responsive-property('margin-top', $grid-spacing-map);
    }

    &-day,
    &-day-btn,
    &-day-inactive,
    &-weekday {
        @include font-size($body-font-sizes);
    }

    &-day,
    &-day-inactive {
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            display: block;
        }

        & > * {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
        }
    }

    &-day,
    &-day-inactive {
        text-align: center;
    }

    &-day-btn[disabled],
    &-day-inactive {
        cursor: not-allowed;
    }

    &-weekdays,
    &-days {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: calc((99% / 7) - #{$sliver});
            margin-right: $sliver;
            margin-bottom: $sliver;
            flex: 0 1 auto;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(7, minmax(0, 1fr));
            grid-gap: $sliver;

            & > * {
                width: auto;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        max-width: 100%;
    }

    &-weekdays {
        margin-top: $gap-sm;
        margin-bottom: $space;
    }

    &-weekday {
        text-align: center;
        font-weight: $font-weight-bold;

        @include media-min(large) {
            min-width: 3ch;
        }

        @include media-min(x-large) {
            min-width: 4ch;
        }
    }

    &-day-btn {
        font-family: $body-font;
        padding: 0;
        cursor: pointer;
        background: $calendar-light-blue;
        color: $black;
        border: 1px solid $black;

        & > * {
            pointer-events: none;
        }
    }


    &-modal {
        .mini-cal-nav {
            max-width: 18.75rem;
            margin-left: auto;
            margin-right: auto;
            padding: 0;

            .modal-close {
                width: 100%;
                margin-top: $gap-sm;
            }
        }
    }

    &-month-header {
        select {
            
        }
        label {
            padding: $gap-sm 0;
        }
    }
}

@include theme-parent() {
    .mini-cal {
        &-day-btn {
            background: $calendar-light-blue;
            color: $black;
        }

        &-day-btn[data-currentdate='true'] {
            background: $calendar-dark-blue;
            border-color: $themed-foreground;
        }


        &-day[disabled],
        &-day-btn[disabled] {
            background: $black-8;
            color: $black;
            border: none;
        }


        &-day-inactive .mini-cal-day-btn {
            background: $black-4;
            color: $black-56;
        }

        &-nav {
            background: $themed-background;
        }

        &-day.mini-cal-day-today {
            background: $calendar-dark-blue;
            border: 1px solid $black;
        }
    }
}
