﻿/// <reference path="../entries/main">
@import '_gallery-vendor.scss';

$splide-arrows-width: 6.875rem;
$splide-arrows-width-reduced: 5.875rem;
$splide-arrow-btn-size-reduced: 2.5rem;

// STRUCTURE AND SLIDE SETUP
.gallery {

    &-wrapper {
        @extend %standard-padding-h;
        @extend %standard-max-width;

        &:not(.gallery-wrapper-static) {
            padding-right: 0 !important;
            overflow-x: hidden;
            max-width: none;

            @include media-min(1800px) {
                @include max-width-offset($space-offset: $space);
            }
        }
    }

    &-header {
        @extend %block-header;

        &-row {
            @include media-min(tablet) {
                display: flex;
                justify-content: space-between;

                & > * + * {
                    margin-left: $gap-sm;
                }
            }

            @include media(tablet) {

                & > * + * {
                    margin-top: $space;
                }
            }
        }
    }

    &-slide {
        position: relative;
        width: calc(70% - #{$gap-md});
        white-space: normal;
    }

    &-slide-inner {
        @extend %cropped-16-by-9;

        img, picture {
            object-position: var(--focal-point-h) var(--focal-point-v);
            object-fit: cover;
        }

        &.audio-item {
            & > audio {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }
    }

    .splide.is-active .splide__list {
        width: 100%;
    }

    .splide__slide {
        padding-right: $gap-md;

        @include theme-child() {
            border-right: 1px solid $themed-light-border;
        }

        &:last-child {
            border-right: none;
        }
    }

    .splide__arrows {
        position: relative;
        padding-left: 0;
    }

    &-item-caption, &-item-solo {
        margin-top: $gap-md;

        &-title {
            margin-bottom: $gap-sm;
        }

        .title {
            font-weight: $font-weight-bold;
        }

        .caption {
            color: $black-64;
        }
    }

    &-item-solo {
        @include media-min(tablet) {
            display: flex;
            width: 100%;
        }

        &-title {
            margin-top: $space;

            @include media-min(tablet) {
                @include font-size($h2-font-sizes);
            }
        }

        &-caption {
            @include media-min(tablet) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        & > * {
            @include media-min(tablet) {
                width: 50%;

                &:nth-child(odd) {
                    padding-right: $gap-md;
                    border-right: $standard-border;

                    @include theme-child() {
                        border-right-color: $themed-light-border;
                    }
                }

                &:nth-child(even) {
                    padding-left: $gap-md;
                }
            }

            @include media(tablet) {
                & + * {
                    margin-top: $gap-md;
                }
            }
        }

        &-media {
            @extend %cropped-16-by-9;
        }
    }
}
// CONTROLS AND PAGINATION

.gallery .carousel {

    &-controls {
        display: flex;
        justify-content: space-between;
        padding-bottom: $space;
    }

    &-arrow {
        @extend %un-button;

        .label {
            text-transform: uppercase;
            @include font-size($large-body-font-sizes);
        }

        &::before, &::after {
            content: none;
        }

        &[disabled], &:hover, &:focus {
            background-color: $white-8;

            span {
                color: $black-32;
            }

            svg {
                fill: $black-32;
            }
        }

        svg {
            @include theme-child() {
                fill: $themed-inverted-color;
            }

            width: $gap-sm;
            height: $gap-sm;
        }
    }

    &-track {
        margin-bottom: $gap-sm;
    }

    &-arrow-left {
        left: 0;
        right: auto;
    }

    &-arrow-right {
        left: auto;
        right: 0;
    }

    &-pagination {
        width: 100%;
        margin: 0 auto;
        padding-left: 0;

        @include media-min(small) {
            @include max-width-offset($property: "margin-right", $space-offset: $space);
        }
    }
}
// Theme Specific
.theme-dark {
    .carousel-arrow {
        &[disabled], &:hover, &:focus {
            background: transparent;

            span {
                color: $white-32;
            }

            svg {
                fill: $white-32;
            }
        }
    }

    .gallery-item-caption .caption {
        color: $white-64;
    }
}
