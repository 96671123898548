﻿/// <reference path="../entries/main">

// Not your typical 5050! See _header-5050.scss for that
// This is a large image that goes to one edge of the page, with text next to it that sits aligned with the page margin
// The layout is done with grid, but the IE fallback is a simpler layout

.fiftyfifty {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    &-item {
        align-self: end;
    }
}

// Flexbox method fallback
.fiftyfifty {
    &-inner {
        @extend %standard-padding-h;
        display: flex;

        @include media(small) {
            display: block;
        }

        &.reversed {
            flex-direction: row-reverse;
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-image {
        flex: 0 0 auto;

        @include responsive-context($standard-padding-map, $gutter-map-h) {
            padding-left: $responsive-variable-2;
            padding-right: $responsive-variable-2;

            @if ($responsive-breakpoint == default) or (map-get($breakpoints, $responsive-breakpoint) > $medium-media-query-size) {
                width: 8 / 12 * 100%;
            }

            @elseif (map-get($breakpoints, $responsive-breakpoint) > $small-media-query-size) {
                width: 7 / 12 * 100%;
            }

            @else {
                width: auto;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $responsive-variable-2;
            }
        }
    }
}

// Grid progressive enhancement and undoing flexbox width, margin, padding
@supports(display: grid) {
    .fiftyfifty {
        $self: &;
        max-width: none;

        &-inner {
            @extend %responsive-grid-columns;
            @extend %responsive-grid-gap;
            display: grid;
            padding-left: 0;
            padding-right: 0;
        }

        &-image {
            width: auto;
            margin: 0;
            padding: 0;

            @include media-min(large) { // At extra large browser sizes, clamp the max image height at the height a 16:9 image would be when it's 3/4 of the max content width size
                max-height: ($max-content-width * 0.75) / 16 * 9;
                overflow: hidden;
                display: flex;

                &-picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: var(--focal-point-h) var(--focal-point-v);
                }
            }
        }

        &-inner {
            &.reversed {
                #{$self}-image {
                    grid-column: 6 / -1;
                }

                #{$self}-content {
                    grid-column: 2 / span 4;
                    grid-row: 1;
                }
            }

            &.normal {
                #{$self}-image {
                    grid-column: 1 / span 9;
                }

                #{$self}-content {
                    grid-column: 10 / span 4;
                }
            }

            @include media(medium) {
                &.reversed {
                    #{$self}-image {
                        grid-column: 7 / -1;
                    }

                    #{$self}-content {
                        grid-column: 2 / span 5;
                    }
                }

                &.normal {
                    #{$self}-image {
                        grid-column: 1 / span 8;
                    }

                    #{$self}-content {
                        grid-column: 9 / span 5;
                    }
                }
            }

            @include media(small) {
                &:nth-child(n) #{$self}-image {
                    grid-column: 1 / -1;
                }

                &:nth-child(n) #{$self}-content {
                    grid-column: 2 / -2;
                    grid-row: auto;
                }
            }
        }
    }
}

.fiftyfifty-withcolor .fiftyfifty-content {
    @include responsive-context($standard-padding-map) {
        padding-bottom: $responsive-variable;
    }
}
