﻿/// <reference path="../../entries/calendar">

.calendar-page {
    &-heading {
        @include media-range(small, large) {
            grid-column: left-edge / center;
        }

        &-container {
            align-items: end;
        }
    }
    &-promo {
        grid-column: span 2;

        @include media(large) {
            grid-column: span 4;
        }

        @include media(small) {
            grid-column: left-edge / right-edge;
        }

        &-trigger {
            margin-top: $space;
        }
    }
}