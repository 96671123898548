﻿/// <reference path="../entries/main">

.thumbnail-list {
    &-item {
        padding-bottom: $gap-md;

        @include media-min(small) {
            display: flex;
            align-items: flex-start;
            padding-bottom: $gap;
        }

        & + & {
            border-top: 1px solid;
            padding-top: $gap-md;

            @include media-min(small) {
                padding-top: $gap;
            }
        }
    }

    li:last-child {
        padding-bottom: 0;
    }

    &-item-large-title {
        @extend %h3;
        margin-bottom: $gap-md;
    }

    &-item-title {
        @extend %bold;
    }

    &-item-img {
        @include media-min(small) {
            width: 31%;
            max-width: 13.5rem;
        }

        & + .thumbnail-list-item-content {
            @include media-min(small) {
                padding-left: $max-gutter-width;
            }

            @include media(small) {
                padding-top: $gap-sm;
            }
        }
    }

    &-item-img-inner {
        width: 100%;
        padding-bottom: ratioToPercent(16, 9);
        overflow: hidden;
        position: relative;

        img {
            @extend %cover-img;
        }
    }
}

@include theme-parent() {
    .thumbnail-list-item + .thumbnail-list-item {
        border-top-color: $themed-foreground;
    }
}

