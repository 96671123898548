﻿/// <reference path="../../entries/main">

.btn,
.btn-primary {
    @extend %btn-primary;
}

.btn-secondary {
    @extend %btn-secondary;
}

.btn-outline {
    @extend %btn-outline;
}

.btn-link, .#{$prefix}btn-link {
    @extend %un-button;
    @extend %hyperlink;
}

.btn-remove {
    @extend %btn-remove;
}

.btn-pill {
    @extend %btn-pill;
}
.btn-pill-checkmark {
    @extend %btn-pill-checkmark;
}

.un-button {
    @extend %un-button;
}

.btn-full-width {
    width: 100%;
    max-width: 100%;
}

.btn-caret {
    @extend %un-button;
    @extend %btn-caret;
}

.btn-wide {
    @include media(small) {
        width: 100%;
        max-width: 100%;

        & + & {
            margin-top: $space;
        }
    }
}

// used only on logout button
.#{$prefix}link {
    @extend %hyperlink;
    font-weight: $font-weight-bold;
    text-decoration: none;
}

// only used in add-ons and package-listing apps
.#{$prefix}ui-btn {
    text-align: center;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    transition: $transition all;
    color: $primary-dark;
    padding: $space $gap-sm;
}

.btn-spinning {
    position: relative;
    color: transparent !important;

    * {
        visibility: hidden;
    }

    &:after {
        content: "";
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 5px solid transparent;
        border-top-color: $white;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease-in-out infinite;
    }
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}