﻿/// <reference path="../../entries/main">

.donation-history {

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: $gap;

        @include media(tablet) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    &-actions {
        * + * {
            margin-left: $space;
        }

        @include media(tablet) {
            margin-top: $gap-sm;
        }
    }

    &-year {
        border-top: 1px solid $primary-dark;

        & + & {
            @include responsive-context($block-margin-map) {
                margin-top: $responsive-variable;
            }
        }

        &-header {
            padding: $space 0 $gap-md;
        }

        &-listing {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
        }
    }

    &-year-item {
        display: grid;
        grid-template-columns: .75fr 1fr 1fr;
        border-top: 1px solid $default-light-border;
        padding-top: $gap;

        & + & {
            margin-top: $gap;
        }

        @include media(tablet) {
            grid-template-columns: 1fr;
        }

        &-image {
            margin-right: $gap;

            @include media(tablet) {
                margin-right: 0;
                margin-bottom: $gap-sm;
            }
        }
    }

    &-accordion-trigger {
        padding: $gap 0;
        position: relative;
        padding-left: $accordion-icon-size * 5;
        border-bottom: 1px solid $default-light-border;
        border-top: 1px solid $default-light-border;

        &::before {
            content: '';
            width: $accordion-icon-size;
            height: $accordion-icon-size/2;
            background-size: cover;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition transform;

            @include theme-child() {
                @include select-dropdown($_bgcolor: $black);
            }
        }

        &.active {
            border-bottom: none;

            &::before {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
}
