﻿/// <reference path="../entries/main">

// Similar to ticket list in cart, but stacks differently for dates
// Indicators + CTA have different widths expected.

.#{$prefix}date {
    &-row {
        @extend %border-top;
        @extend %large;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(small) {
            display: block;

            & > *:first-child ~ * {
                margin-top: map-get($gutter-map-v, x-small);
            }
        }
    }

    &-rows {
        @include stack($spacing-map: $x-small-gap-map, $inner: true);
    }

    &-row-info {
        flex: 0 1 auto;
        display: flex;
        align-items: center;

        @include responsive-context($gutter-map-h, $all-breakpoints: true) {
            $_breakpoint: map-get($breakpoints, $responsive-breakpoint);

            @if $_breakpoint == null or ($_breakpoint > $medium-media-query-size) {
                width: calc(75% - #{$responsive-variable / 3});
            }

            @elseif $_breakpoint > $small-media-query-size {
                width: calc(#{$one-third * 2} - #{$responsive-variable * 2 / 3});
            }

            @else {
                width: 100%;
            }

            & > * {
                flex: 1 1 0;
                margin-right: $responsive-variable;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @include media(small) {
            flex-wrap: wrap;

            & > * {
                flex: 1 1 100%;
                margin-right: 0;
            }
        }

        & > *:only-child {
            width: 100%;
            flex: 1 1 auto;
        }
    }

    &-row-price,
    &-row-supplementary {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include media-min(small) {
            flex: 0 1 auto;
            width: 25%;
        }

        @include media-range(small, medium) {
            width: $one-third;
        }

        &:only-child {
            width: 100%;
            flex: 1 1 auto;
        }
    }
    // Button or other info, should not be bottom aligned like price would
    &-row-supplementary {
        align-self: center;
        align-items: center;

        @include media(small) {
            &,
            & > *,
            [class*="btn"] {
                width: 100%;
            }
        }
    }
}

// Grid upgrade with more intricate responsive stacking
@supports(display: grid) {
    .#{$prefix}date-row-info {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include responsive-context($small-gutter-map-h) {
            grid-column-gap: $responsive-variable;
        }

        @include media(medium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        & > * {
            margin-right: 0;

            @include media(medium) {
                &:nth-child(3) {
                    grid-column: span 2;
                }
            }

            @include media(small) {
                &:nth-child(2) {
                    text-align: right;
                }
            }

            &:only-child {
                grid-column: 1 / -1;
            }
        }
    }
}
