/// <reference path="../../entries/main">

/// minimum size for form element font-size to prevent iOS resizing is 16px

%form-element {
    @extend %body;
    padding: $button-form-padding;
    padding: var(--button-form-padding);
    width: 100%;
    max-width: $max-input-width;
    border: $button-form-border solid transparent;
    border-radius: $border-radius;
    font-family: $body-font;
    outline-offset: 0;
    outline: none;

    &:focus {
        outline: none;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &[aria-invalid=true], &.input-validation-error {
        border-color: $ui-invalid;
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;

        @include icon-invalid($ui-invalid);
    }
}

input,
select,
textarea,
.select-style {
    @extend %form-element;
}



select {
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
        display: none;
    }
}

select,
.select-style {
    padding-right: 0.5rem + ($button-form-padding-v * 2);
    background-size: auto;
    background-position: right 0.75em top 1.45em;
    background-repeat: no-repeat;
}

// --- Labels --- //

label {
    @extend %body;
    font-weight: $font-weight-bold;
}

.label-hint {
    font-style: italic;
    flex: 0 1 auto;
    font-weight: $font-weight-normal;
}

.label-desc {
    display: block;
    font-weight: $font-weight-normal;
    margin-top: $space;
}

.required {
    @extend %small-top-margin;
    color: $ui-invalid;
    position: relative;
    vertical-align: baseline;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.dollar-input {
    position: relative;

    &::before {
        content: '$';
        position: absolute;
        left: $button-form-padding-h;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
    }

    input {
        padding-left: $button-form-padding-h*2;
    }
}

.form-field input + label {
    margin-top: $space;
}


@include theme-parent() {
    input,
    select,
    textarea {
        background-color: $themed-input-bg;
        border-bottom: 1px solid $themed-inverted-color;
        color: $themed-inverted-color;
    }

    select,
    .select-style {
        @include select-dropdown($themed-foreground);

        &:focus {
            @include select-dropdown($themed-medium-color);
        }
    }
}

.field-validation-valid {
    display: none;
}