﻿// REQUIRES %un-button placeholder class to remove default button styles
/// <reference path="../entries/main">

$_tab-padding: $gap-sm;
$_tab-panel-padding: $gap-sm;
$_tab-selected-underline-width: $selected-underline-width;

.tabs {
    &-list {
        display: flex;

        &-item {
            flex: 0 1 auto;
            margin-bottom: -1px;
            overflow: hidden;
            display: block;
            margin-right: $gap-md;

            &:last-child {
                margin-right: 0;
            }
        }

        &-tab {
            @extend %un-button;
            padding: $button-form-padding-v 0;
            border-bottom: $_tab-selected-underline-width solid transparent;
            position: relative;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover,
            &:focus,
            &[aria-selected="true"] {
                border-bottom: $_tab-selected-underline-width solid transparent;
            }
        }
    }

    &-panels {
        border-top: 1px solid transparent;
    }
}

// Mobile select dropdown style (mostly handled w/ link toggle styles)
.tabs {
    &-desktop {
        @include media(small) {
            display: none;
        }
    }

    &-mobile {
        width: 100%;
        margin-bottom: $gap-sm;

        @include media-min(small) {
            display: none;
        }

        &-tab {
            font-weight: $font-weight-normal;
            border-left: $_tab-selected-underline-width solid transparent;
        }
    }
}

@include theme-parent() {
    .tabs {
        &-list {
            border-bottom-color: $themed-foreground;

            &-tab {
                &:hover,
                &:focus,
                &[aria-selected="true"] {
                    border-bottom-color: $themed-foreground;
                }
            }
        }

        &-mobile {
            &-tab {
                &[aria-selected="true"] {
                    border-left-color: $themed-foreground;
                }
            }
        }

        &-panels {
            border-top-color: $themed-foreground;
        }
    }
}
