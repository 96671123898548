﻿/// <reference path="../entries/main">

%arrow-link-common {
    @extend %btn-caret;
    font-weight: $font-weight-bold;
}

.arrow-link, %arrow-link {
    $self: &;

    @extend %arrow-link-common;
    border: none;

    &-block {
        @extend %arrow-link-common;
        width: 100%;

        @include responsive-context($x-small-gap-map) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }
    }

    &-list {
        & > *,
        #{$self} {
            display: flex;
        }

        #{$self} {
            flex: 1 1 auto;
            align-items: center;
        }
    }

    &-list[class*="3"] {
        @include media(tablet) {
            --gutter-h: 0;
            --gutter-v: 0;

            & > *:first-child ~ * #{$self} {
                border-top: 0;
            }
        }
    }

    &-list[class*="2"],
    &-list[class*="4"] {
        @include media(small) {
            --gutter-h: 0;
            --gutter-v: 0;

            & > *:first-child ~ * #{$self} {
                border-top: 0;
            }
        }
    }
}

@include theme-parent() {
    .arrow-link, .arrow-link-block {
        &::before {
            @include icon-chevron-right($themed-foreground); 
        }

        &::after {
            @include icon-chevron-right(nth($themed-link-config, 2));
        }
    }

    .arrow-link-block {
        border-top: 1px solid $themed-foreground;
        border-bottom: 1px solid $themed-light-color;
        &:after {
            width: 1rem;
            height: 1rem;
        }
        &:focus {
            padding-left: $gap-sm;
            padding-right: calc(#{$gap-sm} + #{$svg-icon-sm});
            margin-left: -$gap-sm;
            margin-right: -$gap-sm;

            &::before,
            &::after {
                right: $gap-sm;
            }
        }
    }
}
