﻿/// <reference path="../../entries/main">



// ------------- ACE TOGGLE + PANEL ------------- //

.#{$prefix}renewals-toggle {

    &-container {
        border-top: $standard-border $default-light-border;
        border-bottom: $standard-border $default-light-border;
    }

    &-cta {
        padding: $gap 0;

        button {
            text-decoration: none;
            font-weight: $font-weight-bold;

            &::before {
                content: '';
                width: $accordion-icon-size;
                height: $accordion-icon-size/2;
                background-size: cover;
                display: inline-block;
                transition: $transition transform;
                @include select-dropdown($_bgcolor: $black);
                margin-right: $gap;
                margin-bottom: $space/4;
            }

            &[aria-expanded="true"] {
                &::before {
                    transform: translateY(-50%) rotate(-180deg);
                }
            }
        }
    }

    &-panel {
        &.panel-child-open {
            border-top: 1px solid transparent;
        }
    }

    &-list {
        border-top: $standard-border $default-light-border;
    }
}

// ------------- ACE RENEWALS ------------- //

.#{$prefix}renewals {

    &-item-footer {
        margin-top: $gap-md;
        font-weight: bold;
    }

    .#{$prefix}production-info {

        &-row {
            align-items: center;
        }

        @include media(small) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &-row {
                width: 100%;
                margin-bottom: $gap-sm;
            }
        }
    }

    .preview-card + .preview-card {
        margin-top: $gap-md * 2;
    }
}
