﻿/// <reference path="../../entries/main">

body {
    display: flex;
    flex-direction: column;
    //Hiding until nav gets worked on
    padding-top: var(--total-nav-height);
}

header {
    flex: 0 0 auto;
}

footer {
    flex: 0 0 auto;
}

main {
    flex: 1 0 auto;

    & > *:first-child {
        @include responsive-context($grid-spacing-map) {
            margin-top: $responsive-variable;
        }
    }

    & > .flush-top:first-child {
        margin-top: 0;
    }

    &::after {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($black, 0.4);
        z-index: $z-modal;
    }

    &.nav-open::after {
        content: '';

        @include media($nav-breakpoint) {
            content: none;
        }
    }
}

div[id], section[id] {
    scroll-margin-top: $sticky-nav-height + $gap-sm;
    scroll-margin-top: calc(var(--nav-clearance) + #{$gap});
}

.skip-link {
    position: absolute;
    padding: $space;
    z-index: $z-navigation + 1;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    background: $white;
    border-color: transparent;

    &:focus {
        transform: none;
    }
}

.svg-sprite {
    display: none;
}
