﻿/// <reference path="../entries/main">
$logo-margin: 3.5rem;
$logo-margin-mobile: $gap;
$standard-sponsor-logo-size: pxToRem(160px);
$medium-sponsor-logo-size: pxToRem(214px);
$large-sponsor-logo-size: pxToRem(246px);

// STRUCTURE
.sponsors {

    &-header {
        @extend %block-header;
    }

    &-content {
        margin-top: $gap-sm;
        margin-left: $gap;
        width: calc(50% - #{$gap});

        @include media(small) {
            width: 100%;
            margin-top: $space;
            margin-left: 0;
        }
    }

    &-items {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        text-align: center;



        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 $logo-margin;
            margin-bottom: $gap;
            flex-grow: 1;

            @include theme-child() {
                border-right: 1px solid $themed-light-border;
            }

            @include media(medium) {
                padding: 0 $logo-margin-mobile;
            }

            &-inner {
                max-width: $standard-sponsor-logo-size;
                margin: 0 auto $space;
            }

            &-image {
                position: relative;
                width: 100%;
                padding-bottom: 75%;
                height: 0;
                border-radius: 999em;

                img {
                    margin-bottom: $gap;
                    @extend %full-cover;
                    object-fit: contain;
                    object-position: center center;
                    margin: 0 auto;
                }
            }

            &-link {
                width: 100%;
            }
        }

        &-item-wrapper {
            width: calc((100 / 4) * 1%);
            max-width: calc((100 / 4) * 1%);
            margin-bottom: $gap;
            display: flex;

            @include theme-child() {
                border-bottom: 1px solid $themed-light-border;
            }

            @include media-min(medium) {
                &:last-child, &:nth-child(4n+4) {
                    .sponsors-items-item {
                        border-right: none;
                    }
                }
            }


            @include media(medium) {
                width: calc((100 / 2) * 1%);
                max-width: calc((100 / 2) * 1%);

                &:last-child, &:nth-child(even) {
                    .sponsors-items-item {
                        border-right: none;
                    }
                }
            }

            @include media(small) {
                width: 100%;
                max-width: 100%;

                .sponsors-items-item {
                    border-right: none;
                }
            }
        }
    }
}

// TIERED
.tiered-sponsors-items-row {
    margin-bottom: $gap;

    @include theme-child() {
        border-bottom: 1px solid $themed-light-border;
    }

    .sponsors-items-item-wrapper {
        margin-bottom: 0;
        border-bottom: none;
    }

    .sponsors-items-item {
        border-bottom: none;
        border-right: none;
        padding: 0;
    }
}

.tiered-large .sponsors-items-item-inner {
    max-width: $large-sponsor-logo-size;
}

.tiered-medium .sponsors-items-item-inner {
    max-width: $medium-sponsor-logo-size;
}
