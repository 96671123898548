﻿/// <reference path="../../entries/main">

.visually-hidden {
    @include visually-hidden;
}

.card-v {
    @include responsive-context($gutter-map-v) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

.stack > * + * {
    @extend %text-top-margin;
}

.stack-md > * + * {
    margin-top: $gap-sm;
}

.stack-sm > * + * {
    margin-top: $space;
}

.stack-h {
    & > * + * {
        margin-left: $space;
    }
}

.align-items-center {
    align-items: center;
}

.flex-row-to-column {
    @extend %flex-row-to-column;
}

.flex-between {
    @extend %flex-between;
}

.flex-column-between {
    @extend %flex-column-between;
}

.flex {
    display: flex;
}
.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-center {
    align-items: center;
}

.flex-fiftyfifty {
    @extend %flex-fiftyfifty;

    &-narrow {
        @extend %flex-fiftyfifty;


        @include media-min(small) {
            & > * {
                width: calc(50% - #{$space / 2});

                &:nth-child(odd) {
                    margin-right: $space;
                }
            }
        }

        @include media(small) {
            display: block;

            & > * {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:first-child ~ * {
                    margin-top: $space;
                }
            }
        }
    }

    &-bordered {
        @extend %flex-fiftyfifty;

        @include media-min(tablet) {
            & > * {
                width: calc(50% - #{$gap-md / 2});

                &:nth-child(odd) {
                    margin-right: $gap-md / 2;
                    padding-right: $gap-md;
                    border-right: 1px solid;
                }

                &:nth-child(even) {
                    padding-left: $gap-md / 2;
                }
            }
        }

        @include media(tablet) {
            display: block;

            & > * {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:first-child ~ * {
                    margin-top: $gap-md;
                }
            }
        }
    }
}
// Show/hide classes from Flex Subs
.#{$prefix}show-for-tablet {
    display: none;

    @media (max-width: $medium-media-query-size) {
        display: block;
    }
}

.#{$prefix}hide-for-tablet {
    display: block;

    @media (max-width: $medium-media-query-size) {
        display: none;
    }
}

.#{$prefix}show-for-small {
    display: none;

    @media (max-width: $medium-media-query-size) {
        display: block;
    }
}

.#{$prefix}hide-for-small {
    display: block;

    @media (max-width: $medium-media-query-size) {
        display: none;
    }
}

.space-between {
    & > * + * {
        margin-left: $space;
    }
}
