﻿/// <reference path="../../entries/main">

// ------------- ACE GUEST PASSWORDS CTA - SKIN ------------- //
.ace-guest-password-cta {
    @include media-min(medium) {
        p {
            @include font-size($small-body-font-sizes);
        }
    }
}
