@import
'../__reset.scss',
'../1.settings/__index.scss',
'../2.utilities/__index.scss',
'../3.patterns/__index.scss',
'../4.global/__index.scss',
'../5.components/__index.scss',
'../6.blocks/__index.scss',
'../7.pages/__index.scss'
;
