﻿/// <reference path="../entries/main">
.performance-listing {

    &-item {
        padding: $gap-sm 0 $space;
        align-items: center;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
        }

        & > * {
            margin-bottom: $space;
        }
    }
}
