﻿/// <reference path="../entries/main">

.quick-register {
    &-notice {
        &-message {
            display: flex;
            align-items: flex-start;
            width: 100%;
            justify-content: flex-start;

            svg {
                margin-right: $space;
                margin-top: 0;
                height: $ui-icon-size;
                width: $ui-icon-size;
                align-self: center;
            }
        }
    }
}
