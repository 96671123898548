﻿/// <reference path="../../entries/main">

@mixin generate-focus-style($_inner-color: $default-background, $_outer-color: $default-focus-ring) {
    $_base-weight: 1px;
    outline-offset: 1px;
    outline: 1px solid $default-focus-ring;
    border-radius: 0;
}

@mixin focused() {
    @include generate-focus-style();
}

@mixin focus() {
    outline: none;
    transition: $transition all;

    &:focus {
        @include focused;
    }
}

@mixin round-focus() {
    &:focus {
        @include generate-focus-style();
        outline-color: transparent;
    }
}
