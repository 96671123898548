﻿/// <reference path="../../entries/main">


.#{$adage-prefix}search {
    &-alert {
        padding: $space;
        margin-bottom: $gap-sm;

        &-error {
            background-color: $default-red-light;

            & > * {
                color: $dark-gradient-red;
            }
        }

        &-close {
            @extend %un-button;
            text-decoration: underline;
            margin-top: $gap-sm;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
