﻿/// <reference path="../../entries/main">
$search-result-spacer: 3rem;

.#{$adage-prefix}search {
    &-results {
        &-item {
            border-bottom: 1px solid $default-light-border;
            padding: $gap-md;

            @include media($search-app-breakpoint) {
                padding-left: 0;
                padding-right: 0;
            }

            &:first-child {
                padding-top: $space;
            }

            &-date {
                font-style: italic;
            }

            &-link {
                display: inline-block;
                margin-bottom: $gap-sm;
                @extend %unlink;

                .matched-term {
                    color: $dark-blue-light;
                }
            }

            &-categories {
                display: flex;
                gap: $space;
                margin-top: $gap;

                .tag:not(:first-child) {
                    border-left: 1px solid $default-light-border;
                    padding-left: $space;
                }
            }

            &-description {
                .matched-term {
                    font-weight: $font-weight-bold;
                }
            }

            &.best-bet {
                background: $default-gradient-blue;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-bottom-color: transparent;
                padding-top: $gap-md;

                @include media($search-app-breakpoint) {
                    margin-left: -$search-result-spacer;
                    margin-right: -$search-result-spacer;
                    padding-left: $search-result-spacer;
                    padding-right: $search-result-spacer;
                }

                @include media(small) {
                    margin-left: -$gap-md;
                    margin-right: -$gap-md;
                    padding-left: $gap-md;
                    padding-right: $gap-md;
                }
            }
        }
    }

    &-sorts {
        display: flex;
        padding-bottom: $gap-sm;
    }
}
