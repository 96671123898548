﻿/// <reference path="../../entries/main">
$footer-bg-blur: $blur-radius;
$footer-gutter: $gap;
$footer-social-icon: 1.5rem;
$footer-social-gutter: 0.9375rem;

.mfooter {
    @extend %standard-padding-v;
    position: relative;
    overflow: hidden;

    &-content {
        @extend %standard-max-width;
        @extend %standard-padding-h;
        position: relative;
        z-index: 2;

        &-inner {
            width: 100%;
            border-top: $standard-border;
            border-top-color: $white;

            @include media-min(medium) {
                padding-top: $gap;
                padding-bottom: $gap;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &-grid {
        display: flex;
        width: 100%;

        @include media(medium) {
            flex-wrap: wrap;
        }

        &-col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-min(medium) {
                width: 25%;
                padding-right: $gap;
            }

            @include media(medium) {
                width: 100%;
                border-bottom: $standard-border $white-32;
            }

            & > div:first-child {
                @include media-range(small, medium) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        &-item {
            @include media(medium) {
                width: 100%;
                padding: $gap-md 0;
            }

            @include media(small) {
                & + & {
                    border-top: $standard-border;
                    border-top-color: $white-32;
                }
            }

            &-border-bottom {
                border-bottom: $standard-border;
                border-bottom-color: $white-32;
            }

            &-border-top {
                border-top: $standard-border;
                border-top-color: $white-32;
            }
        }
    }

    &-subgrid {
        flex-grow: 1;
        display: grid;

        @include media-min(medium) {
            grid-template-columns: repeat(3, 1fr);

            & > * {
                padding-bottom: $gap;
                padding-left: $gap;
                border-left: $standard-border;
                border-left-color: $white-32;
            }

            & > *:nth-child(3) ~ * {
                padding-top: $gap;
                padding-bottom: 0;
                position: relative;

                &::before {
                    content: '';
                    top: 0;
                    position: absolute;
                    border-top: $standard-border;
                    border-top-color: $white-32;
                    width: calc(100% - #{$gap*2});
                }
            }

            & > *:nth-child(3n+3) {
                &::before {
                    width: calc(100% - #{$gap});
                    right: 0;
                }
            }
        }

        @include media-range(small, medium) {
            grid-template-columns: repeat(2, 1fr);
            margin: $gap-md 0;

            & > * {
                padding-top: $gap-md;
                padding-bottom: $gap-md;
            }

            & > *:nth-child(2n + 1) {
                padding-right: $gap-md;
                border-right: $standard-border;
                border-right-color: $white-32;

                &::before {
                    left: 0;
                }
            }

            & > *:nth-child(2n + 2) {
                padding-left: $gap-md;

                &::before {
                    right: 0;
                }
            }

            & > *:nth-child(2) ~ * {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: calc(100% - #{$gap-md});
                    top: 0;
                    height: 1px;
                    background-color: $white-32;
                }
            }
        }
    }

    &-show-on {
        &-desktop {
            @include media(medium) {
                display: none;
            }
        }

        &-tablet {
            @include media-min(medium) {
                display: none;
            }
        }
    }

    &-aux {
        width: 100%;

        @include media-min(medium) {
            border-top: $standard-border;
            border-top-color: $white-32;
            margin-top: $gap;
            padding-top: $gap;
            display: flex;
            justify-content: space-between;

            &-links, &-copyright {
                width: 25%;
            }

            &-nonprofit {
                width: 50%;
            }

            &-copyright {
                padding-right: $gap;
            }

            &-links, &-nonprofit {
                padding-left: $gap;
            }
        }

        @include media-range(small, medium) {
            display: flex;
            flex-wrap: wrap;

            &-nonprofit {
                width: 75%;
                margin-bottom: $gap-md;
            }

            &-copyright, &-links {
                width: 50%;
            }

            &-links {
                padding-left: $gap-md;
            }
        }

        @include media(small) {
            & > * + * {
                margin-top: $gap-md;
            }
        }

        &-link {
            & + & {
                margin-left: $gap-sm;
            }
        }

        &-nonprofit {
            &-desktop {
                @include media(medium) {
                    display: none;
                }
            }

            &-tablet {
                @include media-min(medium) {
                    display: none;
                }
            }
        }

        &-links {
            &-mobile {
                @include media-min(small) {
                    display: none;
                }
            }

            &-tablet {
                @include media(small) {
                    display: none;
                }
            }
        }
    }

    &-background {
        filter: blur($footer-bg-blur);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        @extend %blur-adjustment;
        z-index: 0;
    }

    &::after {
        content: '';
        background-color: $black-56;
        @extend %full-cover;
        z-index: 1;
    }

    &-heading {
        @extend %body;
        font-weight: $font-weight-bold;
    }

    &-social {
        display: flex;
        justify-content: flex-start;

        @include media-min(medium) {
            padding: $gap-sm 0 $gap;
        }

        @include media(small) {
            padding: 0 0 $gap;
        }


        li + li {
            margin-left: $footer-social-gutter;
        }

        a {
            height: $footer-social-icon;
            width: $footer-social-icon;
            border: none;
            display: block;
        }

        svg {
            fill: currentColor;
            width: 100%;
            height: $footer-social-icon;
            margin: 0;
        }
    }
}
