﻿/// <reference path="../../entries/main">

// Form wrapper that applies wrapping grid styles for all child items
.#{$prefix}form {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 0 auto;
        width: 100%;
    }

    @include responsive-context($gutter-map-h, $paragraph-margin-map, $all-breakpoints: true) {
        max-width: $max-input-width + $responsive-variable;
        margin-right: -$responsive-variable;
        margin-bottom: -$responsive-variable-2;

        & > * {
            margin-right: $responsive-variable;
            margin-bottom: $responsive-variable-2;
        }
    }

    @include media(tablet) {
        max-width: $max-input-width;
        margin-right: 0;

        & > * {
            margin-right: 0;
        }
    }

    &-section {
        & + & {
            @include responsive-context($block-margin-map) {
                margin-top: $responsive-variable;
            }
        }
    }

    &-fluid {
        max-width: none;

        input,select {
            max-width: none;
        }
    }

    &-centered {
        margin: 0 auto;
    }
}

.#{$prefix} {
    &form-item,
    &form-item-sm {
        & > * + * {
            @extend %small-top-margin;
        }
    }

    &form > &form-item {
        @include responsive-context($gutter-map-h, $all-breakpoints: true) {
            width: calc(100% - #{$responsive-variable});

            &-sm {
                width: calc(50% - #{$responsive-variable});
            }

            &-xs {
                width: calc(#{$one-third} - #{$responsive-variable});
            }
        }

        @include media(tablet) {
            &,
            &-sm,
            &-xs {
                width: 100%;
            }
        }
    }

    &form-item {
        &-sm {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & > * {
                flex: 0 0 auto;
            }

            @at-root {
                body[data-validation-errors] & {
                    justify-content: flex-start;
                }
            }
        }
    }

    &input-label {
        @extend %flex-between;
        align-items: baseline;
    }
}

// Input list
.#{$prefix}input-list {

    .reveal-panel {
        // nested expanding panel
        //@extend %radiocheck-offset;
        margin-top: $gap-sm;
        margin-bottom: $gap-sm;
    }

    & > * + * {
        @extend %medium-top-margin;
    }

    &-item {
        border-radius: $border-radius;
        background-color: $white;
        width: 100%;

        &.flex {
            @extend %flex-between;
            align-items: center;
        }

        & > label {
            padding-left: $radiocheck-size + $radiocheck-padding;
        }
    }
}
