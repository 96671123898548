﻿/// <reference path="../../entries/calendar">

.mini-cal-skip-link {
    @include focus();
    display: block;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: $transition opacity;
    padding: 0;

    &:focus {
        display: block;
        opacity: 1;
        max-height: none;
    }
}
