﻿/// <reference path="../entries/main">

.donate {

    &-block {
        @include media-min(tablet) {
            @include theme-child() {
                border-top: 1px solid $themed-foreground;
            }
        }
    }

    &-content {
        @include media(tablet) {
            @include theme-child() {
                border-top: 1px solid $themed-foreground;
            }
        }
    }

    &-header {
        flex-grow: 1;
    }

    &-footer {
        min-height: 0;
        flex-grow: 0;

        @include media-min(tablet) {
            border-top: none;
        }
    }

    &-amount {

        &-items {
            flex-grow: 1;
        }

        &-label {
            @extend %body;
            @extend %bold;
            margin-bottom: 0.75rem;
        }

        @include media-min(tablet) {
            display: flex;
        }

        &-custom {
            min-width: 50%;

            @include media-min(tablet) {
                margin-left: $gap-sm;
                padding-left: $gap-sm;
                flex-grow: 1;
                border-left: 1px solid $black-16;
            }

            @include media(tablet) {
                border-top: 1px solid $black-16;
                margin-top: $gap-md;
                padding-top: $gap-sm;
            }

            &-error {
                position: absolute;
            }
        }
    }
}
