﻿/// <reference path="../entries/main">



// STRUCTURE
.accordion {

    &-panel {
        overflow: hidden;
        transition: $transition height;

        &[aria-hidden="true"] {
            height: 0;
        }
    }
}


// STYLES
.accordion {
    margin-left: auto;
    margin-right: auto;

    &.align-left {
        margin-left: 0;

        .accordion-inner {
            padding-left: 0;
        }
    }


    @include media(tablet) {
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }

    &-heading {
        margin-bottom: $gap;

        &-container {
            margin-bottom: $gap;
            padding-top: $space;

            @include theme-child() {
                border-top: $standard-border $themed-primary-border;
                border-bottom: $standard-border $themed-light-border;
            }
        }
    }

    &-inner {
        padding-left: $gap;

        @include media(tablet) {
            padding-left: 0;
            border-top: $standard-border;

            @include theme-child() {
                border-top-color: $themed-light-border;
            }

            margin-top: $gap;
        }
    }

    &-bordered {
        padding-top: $gap;
        margin-top: $gap-sm;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
        }
    }

    &-trigger {
        @extend %un-button;
        @include font-size($h4-font-sizes);
        display: block;
        width: 100%;
        padding-top: $gap-md;
        display: flex;
        padding-bottom: $gap-md;
        align-items: center;

        @include media(small) {
            padding-top: $gap-sm;
            padding-bottom: $gap-sm;
        }

        &-icon {
            margin-right: $gap-sm;
            width: $gap-md * 2;
            height: $gap-md * 2;

            @include media(tablet) {
                width: $gap;
                height: $gap;
            }
        }
    }

    &-item {
        border-bottom: $standard-border;

        @include theme-child() {
            border-bottom-color: $themed-light-border;
        }
    }

    &-panel {
        &-content {
            padding-bottom: $gap-md;
        }
    }
}
// ARROW/INDICATOR
.accordion {
    &-trigger {
        position: relative;
        padding-right: $accordion-icon-size * 3;

        &::after {
            content: '';
            width: $accordion-icon-size;
            height: $accordion-icon-size/2;
            background-size: cover;
            display: block;
            position: absolute;
            right: $gap-sm;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition transform;

            @include theme-child() {
                @include select-dropdown($_bgcolor: $themed-inverted-color);
            }
        }

        &[aria-expanded="true"] {
            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }
        }

        &-alt {
            padding-left: 6rem;
            padding-right: 0;
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            @include font-size($h5-font-sizes);
            font-weight: $font-weight-bold;
            border-top: $standard-border;


            @include theme-child() {
                border-top-color: $themed-light-border;
            }

            &[aria-expanded=true] {
                @include theme-child() {
                    border-bottom: $standard-border;
                    border-bottom-color: $themed-light-border;
                }
            }

            &::after {
                left: $gap-sm;
                right: auto;
            }
        }
    }
}

.accordion.flex-fiftyfifty {
    & > * {
        &:nth-child(2n+1) {
            @include theme-child() {
                border-right-color: $themed-light-border;
            }
        }
    }
}
