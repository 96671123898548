﻿/// <reference path="../entries/main">
$preview-card-breakpoint: "tablet";

// Card
.production-card {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $gap-sm;
    padding-top: $gap-sm;


    @include media-min($preview-card-breakpoint) {
        padding-top: $gap;
        padding-bottom: $gap;
    }

    &-card {
        @extend %standard-max-width;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
            border-bottom: none;

            &:last-child {
                border-bottom: 1px solid $black;
            }
        }
    }


    &-item {
        width: 100%;


        @include media-min($preview-card-breakpoint) {
            display: flex;
            width: calc(100%/3);

            & + & {

                @include theme-child() {
                    border-left: 1px solid $themed-light-border;
                }

                @include responsive-context($gutter-map-h) {
                    /*width: calc(70% - #{$responsive-variable});*/
                    padding-left: $responsive-variable/2;
                    margin-left: $responsive-variable/2;
                    flex-grow: 1;
                }
            }

            & > * {
                width: 100%;
            }

            & > * + * {
                @include responsive-context($gutter-map-h) {
                    padding-left: $responsive-variable;
                }
            }
        }
    }

    &-content {
        display: flex;

        @include media($preview-card-breakpoint) {
            flex-direction: column-reverse;
        }
    }

    &-price {
        min-width: 11rem;

        @include media-min($preview-card-breakpoint) {
            text-align: right;
        }
    }

    &-details {
        flex-grow: 1;

        &-description {
            margin-top: $gap-sm;
        }
    }

    &-date {
        font-weight: $font-weight-bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $gap-sm;

        @include media-min($preview-card-breakpoint) {
            flex-direction: column;
        }

        &-decorator {
            flex-grow: 1;
            display: block;
            position: relative;
            margin: $gap-sm 0;


            @include media($preview-card-breakpoint) {
                max-width: pxToRem(91px);
                margin: 0 auto;
            }

            &:after {
                content: '';
                display: flex;
                width: 1px;
                height: 100%;
                //value from Figma
                max-height: pxToRem(91px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: $gap-sm 0;

                @include theme-child() {
                    background: $themed-light-border;
                }

                @include media($preview-card-breakpoint) {
                    height: 1px;
                    width: 100%;
                    max-height: none;
                    max-width: pxToRem(91px);
                    padding: 0 $gap-sm;
                }
            }
        }



        @include media-min($preview-card-breakpoint) {
            width: 9rem;
            margin-bottom: 0;
        }

        &-day {
            @include media($preview-card-breakpoint) {
                margin-right: $gap-sm;
            }
        }
    }

    &-image {
        flex-grow: 1;
        padding-left: $gap-md;

        &-container {
            @extend %cropped-16-by-9;
            padding-bottom: ratioToPercent(1, 1);
        }

        @include media($preview-card-breakpoint) {
            margin-bottom: $gap-sm;
            padding-bottom: $gap-sm;
            padding-left: 0;

            @include theme-child() {
                border-bottom: 1px solid $themed-light-border;
            }
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-ctas {
        margin-top: $gap;

        & > * + * {
            margin-left: $space;
        }
    }

    &-event-name {
        @extend %unlink;

        @include media-min($preview-card-breakpoint) {
            margin-top: $space;
        }
    }


    &-panel {
        width: 100%;
    }
}

.production-card .ace-date-rows {
    margin-top: $gap;

    .ace-date-row {
        padding-top: $gap-sm;
        padding-bottom: $gap-sm;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
        }
    }
}
