﻿/// <reference path="../../entries/main">

@mixin stack($spacing-map: '', $static-spacing: '', $border: false, $inner: false) {
    @if $spacing-map != '' {
        @include responsive-context($spacing-map) {
            @if $inner == false {
                & > *:not(.hide) + *:not(.hide) {
                    margin-top: $responsive-variable;

                    @if $border != false {
                        padding-top: $responsive-variable;
                    }
                }
            }

            @else {
                & > *:not(.hide) {
                    padding-top: $responsive-variable;
                    padding-bottom: $responsive-variable;
                }
            }
        }
    }

    @if $static-spacing != '' {
        @if $inner == false {
            & > *:not(.hide) + *:not(.hide) {
                margin-top: $static-spacing;

                @if $border != false {
                    padding-top: $static-spacing;
                }
            }
        }

        @else {
            & > *:not(.hide) {
                padding-top: $responsive-variable;
                padding-bottom: $responsive-variable;
            }
        }
    }
}
