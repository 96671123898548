﻿/// <reference path="../entries/main">

.#{$prefix} {
    &filters {
        display: flex;
        align-items: center;
        border-width: 1px 0;
        justify-content: space-between;

        @include media-min(tablet) {
            margin-top: 4rem;
            margin-bottom: 6.25rem;

            select {
                width: auto;
            }
        }

        @include media(tablet) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 2.875rem;
            & > * + * {
                margin-top: $gap-sm;
            }
        }



        &-list {
            width: 100%;
            display: flex;

            @include media($calendar-breakpoint-size) {
                display: block;
            }
        }

        &-clear {
            flex-shrink: 0;
        }

        &-container {
            @include media-min(tablet) {
                margin-top: $gap-lg;
            }
        }
    }

    &filter {
        margin-right: 1.375rem;
        flex: 0 1 auto;

        @include media($calendar-breakpoint-size) {
            width: auto;
            max-width: none;
            margin-right: 0;
            margin-bottom: $gap-md;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &clear-filters {
        margin-left: auto;
        margin-right: 0;

        @include media($calendar-breakpoint-size) {
            text-align: center;
        }
    }
}
