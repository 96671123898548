﻿/// <reference path="../entries/main">
.cc-floating.cc-theme-edgeless.cc-window {
    //3rd Party Plugin, specificity needed for overrides;
    padding: $gap;
    background-color: $white;
    color: $black;
    .cc-compliance {
        justify-content: flex-end;
        margin-top: $gap-md;
        padding-top: $gap-md;
        border-top: $standard-border $black; 
        a.cc-btn {
            @extend %btn-primary;
            flex: none;
        }
    }

    .cc-message {
        @extend %body;
        margin: 0;

        a {
            @extend %hyperlink;
        }
    }
}

