﻿/// <reference path="../../../entries/main">

.nav-utility {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;

    @include media(medium) {
        &-text {
            @include visually-hidden;
        }
    }

    &-desktop {
        @include media(tablet) {
            @include hide();
        }
    }

    &-mobile {

        @include media-min(tablet) {
            display: none;
        }
    }

    &-link {
        display: flex;
        align-items: center;
        margin-top: -$svg-inline-spacing;
        margin-bottom: -$svg-inline-spacing;
        position: relative;
        text-decoration: none;
        border-color: transparent;
    }

    &-icon,
    &-text,
    &-text-menu {
        margin: $svg-inline-spacing;
    }

    &-text-menu {
        letter-spacing: 2px;

        @include media(tablet) {
            display: none;
        }
    }

    &-icon {
        fill: none;

        @include media(medium) {
            width: $gap-md;
            height: $gap-md;
        }

        &-cart[data-cart-count] {
            position: relative;

            &:after {
                content: attr(data-cart-count);
                height: $gap-sm;
                width: $gap-sm;
                position: absolute;
                top: $space / 2;
                right: $space / 2;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $themed-red-dark;
                color: $white;
                border-radius: 50%;
                font-size: 0.75rem;
            }
        }
    }

    &-text, &-text-menu {
        font-weight: $font-weight-bold;
        margin-right: 0;
    }

    &-text-menu {
        @include theme-child {
            stroke: $themed-foreground;
        }
    }

    &-item {
        &:first-child {
            margin-left: 0;
        }
    }

    .badge {
        padding-left: $gap-sm;
        padding-right: $gap-sm;
        border-radius: $gap-sm;
    }

    .cart-count {
        font-size: .75rem;
        background: $default-red-dark;
        color: $white;
        padding: 0 7px;
        vertical-align: top;
        margin-left: -11px;
        margin-bottom: $gap-sm;
    }
}

@include theme-parent() {

    .nav-utility-icon {
        stroke: $themed-foreground;
    }
}