﻿/// <reference path="../entries/main">
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media(small) {
        flex-wrap: wrap;
    }

    @include media(mobile) {
        flex-direction: column;
        @include stack($paragraph-margin-map);
    }

    &-count {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(small) {
            flex-wrap: wrap;
            margin: $gap-sm 0;
        }

        &-item {
            padding: 0 $space;

            @include media(small) {
                padding: $space;
            }
        }
    }
}