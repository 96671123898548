﻿/// <reference path="../../entries/main">

.#{$prefix}subs {
    &-intro {
        border-top: $standard-border $black-16;
        border-bottom: $standard-border $black-16;
        padding: $gap-sm 0;
        margin-top: 3.375rem;

        @include media(tablet) {
            margin-top: 2.75rem;
        }
    }

    &-filter-bar {
        &-mobile {
            @extend %flex-between;
            align-items: center;

            @include media-min($calendar-breakpoint-size) {
                display: none;
            }
        }
    }

    &-filter-heading {
        @include media($calendar-breakpoint-size) {
            @include visually-hidden;
        }
    }

    &-filters {

        &-closed {
            @include media($calendar-breakpoint-size) {
                display: none;
            }
        }
    }

    &-package {
        padding: $gap 0 0;

        @include media(small) {
            padding: $gap-md 0 0;
        }

        &-viewmore {
            padding: $gap 0;
            border-top: $standard-border $black-16;
        }

        &-perfs {
            @include theme-child() {
                border-top: $standard-border;
                border-top-color: $themed-light-border;
            }

            .ace-production-row:last-child {
                border-bottom: none;
            }
        }


        &-img {
            @extend %cropped-16-by-9;
            background-color: black;
            border-radius: $border-radius;
        }

        &-preheader {
            @extend %tag;
        }

        &-details {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-direction: column;
            height: 100%;

            &-upper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;
                gap: $gap-sm;
                padding-bottom: $gap-sm;
            }
        }
    }
    // Package (fixed packages) or Production (flex packages)
    &-item {
        @include media-min(tablet) {
            padding: $gap;
        }

        &:not(.hide) {
            padding-top: 0;

            & ~ & {
                .#{$prefix}subs-package {
                    border-top: $standard-border $black;
                }

                @include media-min(tablet) {
                    padding: $gap;
                }
            }
        }

        &-renewal {
            background-color: $themed-orange-light;

            @include media(small) {
                margin: 0 -$gap-md;
                padding: $gap-md;
            }
        }

        &-details {
            display: flex;
            align-items: baseline;
            width: $one-third * 2;
            flex: 0 0 auto;

            @include media(medium) {
                display: block;
                width: 50%;
            }

            @include media(tablet) {
                width: 100%;
            }

            & > * {
                @include responsive-context($gutter-map-h) {
                    width: calc(50% - #{$responsive-variable / 2});
                    margin-right: $responsive-variable;
                }

                @include media(medium) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: $space;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-select {
            flex: 0 1 auto;
        }
    }
}
