﻿/// <reference path="../entries/main">
$tab-nav-breakpoint: $tablet-media-query-size;
// ------------- ACE HUB NAVIGATION STYLES ------------- //

.#{$prefix}tab-nav {
    @include theme-child() {
        background-color: $themed-red-light;
    }


    &-dropdown {
        @include media-min($tab-nav-breakpoint) {
            display: none;
        }

        @include media($tab-nav-breakpoint) {
            width: 100%;
            max-width: 100%;
        }

        &-linktoggle {
            width: 100%;
            max-width: 100%;
        }
    }

    &-list {
        display: flex;
        flex-wrap: nowrap;
        @extend %standard-padding-h;
        @extend %standard-max-width; 

        @include media($tab-nav-breakpoint) {
            display: none;
        }

        &-item {
            flex: 0 1 auto;
            display: block;
            margin-right: $gap-md;

            &:last-child {
                margin-right: 0;
            }
        }

        &-link {
            padding: $hub-nav-link-padding 0;
            border-bottom: $selected-underline-width solid transparent;
            position: relative;
            max-width: 100%;
            white-space: nowrap;
            color: $black;
            display: block;
            text-decoration: none !important;
            font-weight: $font-weight-bold;

            &.active, &:hover, &:focus {
                border-bottom-color: $black;
            }
        }
    }
}

