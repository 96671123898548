﻿/// <reference path="../entries/main">

%body {
    @include font-size($body-font-sizes);
    font-family: $body-font, serif;
    font-weight: $font-weight-medium;
}

%small {
    @include font-size($small-body-font-sizes);
}

%large {
    @include font-size($large-body-font-sizes);
    font-weight: $font-weight-normal;
}

%label {
    @include font-size($label-font-sizes);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

%tag {
    @include font-size($tag-font-sizes);
    font-weight: $font-weight-medium;
    text-transform: uppercase;
}

%quote {
    @include font-size($quote-font-sizes);
    font-family: $heading-font;
    font-weight: $font-weight-normal;
    font-style: italic;
}

%hyperlink {
    transition: $transition;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    border-radius: $border-radius;
}

%bold {
    font-weight: $font-weight-bold;
}

%medium {
    font-weight: $font-weight-normal;
}

%italic {
    font-style: italic;
}


@include theme-parent() {
    %body {
        color: $themed-foreground;
    }

    %light {
        color: $themed-light-text;
    }

    %hyperlink {
        @include link-helper();
    }
}