﻿/// <reference path="../../entries/main" />

@mixin link-helper($color: nth($themed-link-config, 1), $contrast: nth($themed-link-config, 2), $border: true) {
    color: $color;

    @if $border == true {
        text-decoration: underline;
    }

    @else {
        border-color: transparent;
    }

    &:hover, &:active, &:focus {
        color: $contrast;

        @if $border == true {
            text-decoration: none;
        }

        @else {
            border-color: transparent;
        }
    }
}