﻿/// <reference path="./__index">

// Containers with classes .theme-[theme] will use these configuration values in the styles

// Setup for theming mixin
$default-background: $themed-background;
$default-foreground: $themed-foreground;
$default-link-config: $themed-link-config;
$default-button-1-config: $themed-button-1-config;
$default-button-2-config: $themed-button-2-config;
$default-button-3-config: $themed-button-3-config;
$default-button-on-config: $themed-button-on-config;
$default-button-disabled-config: $themed-button-disabled-config;
$default-focus-ring: $themed-focus-ring;
$default-light-text: $themed-light-text;
$default-light-color: $themed-light-color;
$default-medium-color: $themed-medium-color;
$default-inverted-color: $themed-inverted-color;
$default-input-bg: $themed-input-bg;
$default-primary-border: $themed-primary-border;
$default-light-border: $themed-light-border;
$default-light-text: $themed-light-text;
$default-active-slide-background: $themed-active-slide-background;
$default-active-slide-foreground: $themed-active-slide-foreground;

// Get Default Theme Light Colors
$default-base: $themed-base;
$default-gray: $themed-gray;
$default-gradient-gray: $themed-gradient-gray;
$default-gradient-purple: $themed-gradient-purple;
$default-gradient-green: $themed-gradient-green;
$default-gradient-red: $themed-gradient-red;
$default-gradient-orange: $themed-gradient-orange;
$default-gradient-blue: $themed-gradient-blue;
$default-gradient-teal: $themed-gradient-teal;
$default-gradient-magenta: $themed-gradient-magenta;
$default-red-light: $themed-red-light;
$default-red-dark: $themed-red-dark;
$default-orange-light: $themed-orange-light;
$default-orange-dark: $themed-orange-dark;
$default-green-light: $themed-green-light;
$default-green-dark: $themed-green-dark;
$default-teal-light: $themed-teal-light;
$default-teal-dark: $themed-teal-dark;
$default-blue-light: $themed-blue-light;
$default-blue-dark: $themed-blue-dark;
$default-purple-light: $themed-purple-light;
$default-purple-dark: $themed-purple-light;
$default-magenta-light: $themed-magenta-light;
$default-magenta-dark: $themed-magenta-dark;

    
$themes: (
    'light': (
        'background': $default-background,
        'foreground': $default-foreground,
        'links': $default-link-config,
        'button-1': $default-button-1-config,
        'button-2': $default-button-2-config,
        'button-3': $default-button-3-config,
        'button-on': $default-button-on-config,
        'button-disabled': $default-button-disabled-config,
        'focus-ring': $default-focus-ring,
        'light-text': $default-light-text,
        'light-color': $default-light-color,
        'medium-color': $default-medium-color,
        'inverted-color': $default-inverted-color,
        'input-bg': $default-input-bg,
        'primary-border': $default-primary-border,
        'light-border': $default-light-border,
        'base': $default-base,
        'gray': $default-gray,
        'gradient-gray': $default-gradient-gray,
        'gradient-magenta': $default-gradient-magenta,
        'gradient-purple': $default-gradient-purple,
        'gradient-green': $default-gradient-green,
        'gradient-red': $default-gradient-red,
        'gradient-orange': $default-gradient-orange,
        'gradient-blue': $default-gradient-blue,
        'gradient-teal': $default-gradient-teal,
        'red-light': $default-red-light,
        'red-dark': $default-red-dark,
        'orange-light': $default-orange-light,
        'orange-dark': $default-orange-dark,
        'green-light': $default-green-light,
        'green-dark': $default-green-dark,
        'teal-light': $default-teal-light,
        'teal-dark': $default-teal-dark, 
        'blue-light': $default-blue-light,
        'blue-dark': $default-blue-dark,
        'purple-light': $default-purple-light,
        'purple-dark': $default-purple-dark,
        'magenta-light': $default-magenta-light,
        'magenta-dark': $default-magenta-dark, 
        'active-slide-background': $default-active-slide-background,
        'active-slide-foreground': $default-active-slide-foreground
     ),
    'dark' : (
        'background': $primary-dark,
        'foreground': $white,
        'links': ($white, $white-64),
        'button-1': ($white, $primary-dark),
        'button-2': ($primary-dark, $white, $white),
        'button-3': (transparent, $white, $white),
        'button-on': ($white-80, $primary-dark),
        'button-disabled': ($white-16, $white-64),
        'focus-ring': rgba($white, 0.8),
        'light-text': $white-64,
        'light-color': $white-16,
        'medium-color': $white-64,
        'inverted-color': $white,
        'input-bg': $white-8,
        'base': $dark-base,
        'gray': $dark-gray,
        'primary-border': $white,
        'light-border': $white-32,
        'gradient-gray': $dark-gradient-gray,
        'gradient-magenta': $dark-gradient-magenta,
        'gradient-purple': $dark-gradient-purple,
        'gradient-green': $dark-gradient-green,
        'gradient-red': $dark-gradient-red,
        'gradient-orange': $dark-gradient-orange, 
        'gradient-blue': $dark-gradient-blue,
        'gradient-teal': $dark-gradient-teal,
        'red-light': $dark-red-light,
        'red-dark': $dark-red-dark,
        'orange-light': $dark-orange-light,
        'orange-dark': $dark-orange-dark,
        'green-light': $dark-green-light,
        'green-dark': $dark-green-dark,
        'teal-light': $dark-teal-light,
        'teal-dark': $dark-teal-dark, 
        'blue-light': $dark-blue-light,
        'blue-dark': $dark-blue-dark,
        'purple-light': $dark-purple-light,
        'purple-dark': $dark-purple-dark,
        'magenta-light': $dark-magenta-light,
        'magenta-dark': $dark-magenta-dark,
        'active-slide-background': $white,
        'active-slide-foreground': $primary-dark
    )
);