﻿/// <reference path="../entries/main">

// Refer to media items partials for indiviudal item styling

.media-block {

    figcaption {
        margin-top: $gap-sm;
        @include font-size($label-font-sizes);

        & * + * {
            margin-top: $space;
        }

        .title {
            font-weight: $font-weight-bold;
        }

        .caption {
            color: $black-64;
        }
    }
}

// Dark Theme
.theme-dark .media-block {
    figcaption .caption {
        color: $white-64;
    }
}
