﻿/// <reference path="../entries/main">


// STRUCTURE
main > .block > .image-block {
    // only apply max width if directly inside main
    max-width: $max-line-length;
}

.image-block.inline {
    max-width: $max-line-length;
}

.image-block {
    & > img {
        width: 100%;
    }
}