﻿/// <reference path="../entries/main">
$gradient-colors: ( orange, red, gray, green, purple, magenta, blue, teal );
[class*="bg-gradient"] {
    @extend %gradient-background;
}

@each $name in $gradient-colors {
    .bg-gradient-#{$name} {
        @include theme-child() {
            $gradientToUse: null;
            @if $name == orange {
                $gradientToUse: $themed-gradient-orange;
            }
            @elseif $name == red {
                $gradientToUse: $themed-gradient-red;
            }
            @elseif $name == gray {
                $gradientToUse: $themed-gradient-gray;
            }
            @elseif $name == green {
                $gradientToUse: $themed-gradient-green;
            }
            @elseif $name == purple {
                $gradientToUse: $themed-gradient-purple;
            }
            @elseif $name == magenta {
                $gradientToUse: $themed-gradient-magenta;
            }
            @elseif $name == blue {
                $gradientToUse: $themed-gradient-blue;
            }
            @elseif $name == teal {
                $gradientToUse: $themed-gradient-teal;
            }
            //@debug "background image #{map-get($gradient-colors, $name)}";
            //@debug $name $gradientToUse;
            background-image: url('/Static/img/grain.png'), $gradientToUse;
        }
    }
}

@include theme-parent() {
    .bg-gray {
        background-color: $themed-gray;
    }

    .bg-red-light {
        background-color: $themed-red-light;
    }

    .bg-red-dark {
        background-color: $themed-red-dark;
    }

    .bg-orange-light {
        background-color: $themed-orange-light;
    }

    .bg-orange-dark {
        background-color: $themed-orange-dark;
    }

    .bg-green-light {
        background-color: $themed-green-light;
    }

    .bg-green-dark {
        background-color: $themed-green-dark;
    }

    .bg-blue-light {
        background-color: $themed-blue-light;
    }

    .bg-blue-dark {
        background-color: $themed-blue-dark;
    }

    .bg-teal-light {
        background-color: $themed-teal-light;
    }

    .bg-teal-dark {
        background-color: $themed-teal-dark;
    }

    .bg-purple-light {
        background-color: $themed-purple-light;
    }

    .bg-purple-dark {
        background-color: $themed-purple-dark;
    }

    .bg-magenta-light {
        background-color: $themed-magenta-light;
    }

    .bg-magenta-dark {
        background-color: $themed-magenta-dark;
    }
}
