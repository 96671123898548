﻿/// <reference path="./__index">

// UI Element sizes
$ui-icon-thickness: 2px; //for pseudoelement arrows, close buttons, etc
$border-radius: 2px; //buttons and form elements
$border-radius-lg: 6px; //cards, headers, alerts
$ui-icon-size: 1.25rem;
$svg-icon-xs: 0.75em;
$svg-icon-sm: 1.25em;
$svg-icon-lg: 2em;
$button-form-padding-h: 1.125rem;
$button-form-padding-v: .875rem;
$button-form-padding: $button-form-padding-v $button-form-padding-h;
$button-form-border: 0.0625rem;
$hub-nav-link-padding: 1rem;
$accordion-icon-size: .75rem;
$radiocheck-size: 1.25rem;
$radiocheck-padding: $gap-sm;
$_radiocheck-size: $radiocheck-size;
$_radiocheck-padding: $radiocheck-padding;
$_radiocheck-offset: -0.05em;
$_radiocheck-weight: 2px;
$_checkmark-width: $_radiocheck-size / 1.5;
$_checkmark-height: $_radiocheck-size / 3;
$_radio-pill-checkmark-left: .75rem;
$_radio-pill-checkmark-top: 1rem;

// Sizing is equal to height of buttons/form inputs
$square-button-size: (1rem * $body-line-height) + ($button-form-padding-v * 2) + ($button-form-border * 2);

$button-width: 8rem;

$loader-size: 5rem;
$loader-weight: 2; //multiplier scale, not direct pixel weight

// Calendar structure variables
$filter-height: 6rem !default;
$filter-height-mobile: 5rem !default;
$weekdays-height: 4.5rem !default;

// Nav variables (estimated size, only used to preset nav-clearance)
$sticky-nav-height: 5.9375rem;
$sticky-nav-height-mobile: 4.5rem;
