﻿/// <reference path="../entries/main">

// ------------- MODAL - STRUCTURE ------------- //
.#{$prefix}modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: $z-modal;
    align-items: center;
    justify-content: center;
    display: none;

    &.active {
        display: flex;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        height: calc(var(--vh, 1vh) * 100);
        width: 100vw;
        opacity: 0.7;
        z-index: -1;
    }

    &-wrapper {
        padding: $gap;
        position: relative;
        width: 100%;
        max-width: $max-modal-width;
    }

    &-item {
        + * {
            margin-top: $gap-sm;
        }

        &-split {
            display: flex;

            > * {
                flex-grow: 1;

                + * {
                    margin-left: $gap-sm;
                }
            }
        }
    }

    &-action {
        > * {
            + * {
                margin-left: $gap-sm;
            }
        }
    }

    &-close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        padding: 0;
    }
}

// ------------- MODAL - SKIN ------------- //
.#{$prefix}modal {

    &::before {
        background: $primary-dark;
    }

    &-wrapper {
        background: $white;
    }

    &-close {
        line-height: 1;
        font-weight: bold;
        font-size: 0.9rem;
        color: $primary-medium;
        background: $white;

        @extend %btn;
    }
}
