﻿/// <reference path="../entries/main">

// ------------- ACE LISTING - STRUCTURE ------------- //
.#{$prefix}listing {
    padding: $gap-md 0;
}
// ------------- ACE LISTING ITEM - STRUCTURE ------------- //
.#{$prefix}listing-item {
    margin-bottom: $gap-md;
}

// ------------- ACE LISTING ITEM HEADER - STRUCTURE ------------- //
.#{$prefix}listing-item-header {
    margin-bottom: $gap-md;
}
// ------------- ACE LISTING SUBTOTAL - STRUCTURE ------------- //
.#{$prefix}listing-subtotal {
    text-align: right;
}
// ------------- ACE LISTING SUBTOTAL - STRUCTURE ------------- //
.#{$prefix}listing-item-content {
    .ace-grid-inner {
        grid-row-gap: $gap / 2;
    }
}

// ------------- ACE LISTING ITEM FOOTER - STRUCTURE ------------- //
.#{$prefix}listing-item-footer {
    margin-top: $gap-md;
    font-weight: bold;
}


// ------------- ACE LISTING ITEM CART ITEM - STRUCTURE ------------- //
.#{$prefix}listing-item {
    .ace-cart-item {
        padding: $gap / 2 0 0 0;
        margin-top: $gap / 2;
      }
}


// ------------- ACE LISTING LIGHT - SKIN ------------- //
.#{$prefix}listing-light {
    .#{$prefix}listing-item {
        background: $white;
    }
}

// ------------- ACE LISTING DARK - SKIN ------------- //
.#{$prefix}listing-dark {
    .#{$prefix}listing-item {
        background: $primary-light;
    }
}
