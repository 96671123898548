﻿/// <reference path="../entries/main">

.dynamic-list-block {

    * {
        &.dynamic-list-block-section {
            border-top: none;
        }
    }

    &-result {
        & + & {
            padding-top: $gap-md;
            margin-top: $gap-md;

            @include theme-child() {
                border-top: 1px solid $themed-light-border;
            }
        }
    }

    &-pagination .pagination-container {
        @extend %standard-padding-v;
        justify-content: center;
    }

    &-filters-list {
        padding-top: $gap-sm;
        margin-top: $space;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
        }
    }

    .preview-card {
        border-top: none;

        &-section-image {
            @extend %cropped-16-by-9;
        }
    }
}
