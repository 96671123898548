﻿/// <reference path="../../entries/main">

.#{$prefix}header {
    @include responsive-context($gutter-map-v) {
        margin-bottom: $responsive-variable;
    }

    &-small {
        @include responsive-context($gutter-map-v) {
            margin-bottom: $responsive-variable/2;
        }
    }

    &-actions {
        @include media(small) {
            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable;
            }
        }
    }

    &-content {
        @include media-min(small) {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.#{$prefix}page {
    &,
    &-section,
    &-header {
        @extend %standard-max-width;
        @extend %standard-padding-h;
        @extend %standard-padding-v;

        &.reduced-top {
            padding-top: $gap-sm;
        }

        &.reduced-bottom {
            padding-bottom: $gap-sm;
        }

        .grecaptcha-badge {
            display: none !important;
        }
    }
}
