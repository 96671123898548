﻿/// <reference path="../../entries/main">

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 5px solid $gray-light; 
    border-top: 5px solid $primary-dark;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}