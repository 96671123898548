﻿/// <reference path="../../entries/main">

// ------------- ACE EXCHANGES OPTIONS - SKIN ------------- //
.#{$prefix}exchanges-options {
    border-color: $primary-light;
}

// ------------- ACE EXCHANGES OPTIONS LIST GROUP - SKIN ------------- //
.#{$prefix}exchanges-options-list-group {

    &-header {
        font-weight: $font-weight-bold;
    }
}

// ------------- ACE EXCHANGES OPTIONS LIST GROUP ITEM - SKIN ------------- //
.#{$prefix}exchanges-options-list-group-item {

    label {

        .type {
            font-weight: $font-weight-normal;
        }
    }
}

