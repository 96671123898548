﻿/// <reference path="../../entries/main">

.#{$prefix}cart-item {
    background: $white;
    border-top: 1px solid $black-16;

    &-row,
    &-footer,
    &-note,
    &-subheader {
        border-top: 1px solid $black-16;
        padding-top: $gap-sm;

        &-exchanged {
            color: $primary-dark;
            text-decoration: line-through;

            * {
                color: $primary-dark;
            }
        }
    }

    &-cta-links {
        display: flex;
    }

    &-exchanged {
        color: $primary-dark;

        * {
            color: $primary-dark;
        }
    }
}

// ------------- ACE CART TICKET ------------- //
.#{$prefix}cart-ticket-wrapper {
    & + & {
        margin-top: $gap-md * 2;
    }
}
.#{$prefix}cart-ticket-choice {
    display: flex;
    flex-wrap: wrap;

    &-item {
        padding-right: $gap-md * 2;
    }
}
