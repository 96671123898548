﻿/// <reference path="../../entries/calendar">

.ace-cal {
    &-filters {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;

        @include media(medium) {
            flex-direction: column;
        }

        @include media-min($calendar-breakpoint-size) {
            padding-right: $gap-sm;
        }

        &-heading {
            @include visually-hidden;
        }

        &-list {
            width: 100%;

            @include media(medium) {
                li {
                    margin-top: $gap-sm;
                }
            }


            @include media-min(medium) {
                display: flex;
                align-items: flex-end;

                li {
                    margin-bottom: 0;
                }
            }

            &-container {
                > h3 {
                    @include visually-hidden;
                }
            }
        }

        &-pill {
            font-weight: $font-weight-normal;
        }

        &-toggle {

            @include theme-child() {
                background: $black-4;
                color: $black;
            }

            svg {
                stroke: $black;
                fill: none;
                margin-right: $space;
                margin-left: 0;
            }

            &[disabled] {
                color: $white;
                background: $black;

                svg {
                    stroke: $white;
                }
            }

            & + & {
                margin-left: $space;
            }
        }
    }

    &-clear-filters {
        @include media-min(medium) {
            text-align: right;
            flex: 1 1 auto;
            margin-right: 0 !important;
        }

        @include media(medium) {
            text-align: center;
        }

        button:not([disabled]) {
            text-decoration: underline;
        }
    }

    &-nav .ace-cal-toggles {
        @include media($calendar-breakpoint-size) {
            display: none;
        }
    }

    &-month-dropdown {
        select {
            // Account for larger select style
            background-position: right 0.5em top 1.1em;
        }
    }
}
