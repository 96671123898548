﻿/// <reference path="../../entries/main">

.search-page {
    padding-top: 0;

    @extend %standard-padding-v;
    @extend %standard-padding-h;
    @extend %standard-max-width;

    &-section {
        padding: $gap 0;
        border-top: 1px solid $black;

        @include responsive-context($standard-padding-map) {
            margin-top: $responsive-variable;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gap-md;
    }


    &-noresults {
        text-align: center;
        padding: $max-standard-padding 0 $max-standard-padding;
    }

    &-bar {
        display: flex;

        input {
            margin-right: $space;
        }
    }

    &-result {
        position: relative;

        & + & {
            border-top: 1px solid $default-light-border;
            margin-top: $gap;
            padding-top: $gap;
        }
    }
}


