﻿/// <reference path="../../entries/main">
.pdp-sticky-nav {
    display: flex;

    &-desktop {
        flex-wrap: wrap;
        justify-content: flex-end;

        @include media(tablet) {
            @include hide();
        }

        & > * {
            margin-bottom: $space;
        }

        & * + * {
            margin-left: $space;
        }
    } 
    &-mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: $gap-sm $space;
        z-index: $z-bottom-sticky-nav;

        @include theme-child() {
            border-top: 1px solid $themed-light-border;
            background: $themed-background;
        }

        a[class*="btn"] {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & * + * {
            margin-left: $space;
        }

        @include media-min(tablet) {
            @include hide();
        }
    }
}
