﻿/// <reference path="../entries/main">

%block-header, .block-header {
    display: flex;
    padding: $space 0 $gap;
    margin: $gap 0;

    @include theme-child() {
        border-top: 1px solid $themed-primary-border;
        border-bottom: 1px solid $themed-light-border;
    }

    @include media(small) {
        display: block;
    }

    & > * {
        width: 50%;

        & + * {
            padding-left: $gap-sm;
        }

        @include media(small) {
            width: 100%;

            & + * {
                padding-left: 0;
                padding-top: $space;
            }
        }
    }
}
