﻿/// <reference path="../entries/main">


// STRUCTURE
.fwcta {

    &-footer {
        padding: $gap-md 0;
    }

    &-block-padded {
        @extend %block-padding-v;
    }
}
